import { yupResolver } from '@hookform/resolvers/yup';
import { observer } from 'mobx-react';
import { useForm } from 'react-hook-form';
import { useNavigate, Link, useSearchParams } from 'react-router-dom';
import { MSG, SYSTEM_PATH, TOOLTIP_FORMAT_PASSWORD } from '../../core/configs/constants';
import { useStore } from '../../core/utils/hook';
import yup from '../../core/utils/yupValidate';
import { useState } from 'react';
import classNames from 'classnames';
import { useEffect } from 'react';
import NotFoundScreen from '../404/NotFoundScreen';
import { passwordRegex } from '../../core/utils/common';

const SetPasswordScreen = observer(() => {

    // other
    const navigate = useNavigate();
    const [ searchParams ] = useSearchParams();
    const uuid = searchParams.get('uuid');

    // store
    const { authStore: { setPassword, verifyUuid }, modalStore: { show, hide } } = useStore();

    /// state
    const [ existedUuid, setExistedUuid ] = useState(true);
    const [ showPassword, setShowPassword ] = useState(false);
    const [ showPasswordConfirm, setShowPasswordConfirm ] = useState(false);

    const [showCheckFormatPassword, setShowCheckFormatPassword] = useState(false);
    const [hasUppercase, setHasUppercase] = useState(false);
    const [hasLowercase, setHasLowercase] = useState(false);
    const [hasDigit, setHasDigit] = useState(false);
    const [hasSymbol, setHasSymbol] = useState(false);
    const [hasAvaiableLength, setHasAvaiableLength] = useState(false);

    const validateSetPasswordSchema = yup.object().shape({
        password: yup
            .string()
            .required(MSG['error.required'])
            .matches(passwordRegex, MSG['error.password_format_warning']),

        passwordConfirm: yup
            .string()
            .required(MSG['error.required'])
            .oneOf([yup.ref('password'), null], MSG['error.confirm_password_not_match']),
    })
    
    const { 
        register, 
        handleSubmit, 
        formState: { errors, isSubmitting },
        getValues,
        trigger
    } = useForm({resolver: yupResolver(validateSetPasswordSchema), mode: 'onChange'});

    // lifecycle
    useEffect(() => {
        uuid && onVerifyUuid();
    }, [])

    // function
    const onVerifyUuid = async () => {
        const res = await verifyUuid({uuid: uuid ?? ''});
        setExistedUuid(res);
    }

    const toggleShowPassword = () => {
        setShowPassword(!showPassword);
    }

    const toggleShowPasswordConfirm = () => {
        setShowPasswordConfirm(!showPasswordConfirm);
    }

    const redirectToLogin = async() => {
        await hide();
        navigate(SYSTEM_PATH.LOGIN);
    }

    const onSubmit = async (data) => {
        try {
            const { password } = data;
            const res = await setPassword({ uuid, password });
            if (res) {
                show({
                    id: 'modal',
                    isOpen: true,
                    onRequestClose: () => hide(),
                    notButtonX: true,
                    children: (
                        <div className='pd-tb-30 pd-lr-20'>
                            <div className='text-center'>
                                <i className='fa-solid fa-circle-check fs-cs-90'></i>
                                <div className='mg-t-10 fs-cs-24 font-semi-bold'>パスワード変更が完了しました!</div>
                            </div>
                            <div className='mg-t-20 d-flex align-items-center justify-content-center flex-gap-20 flex-wrap'>
                                <button className='btn btn-bg-cs-1 font-regular fs-heading-small width-315' onClick={redirectToLogin}>ログイン</button>
                            </div>
                        </div>
                    )
                })
            }
        } catch (error) {
            console.log(error);
        }
    }

    if(!existedUuid || !uuid) {
        return (
            <div className='text-center mg-t-50 text-color-cs-white'>
                <NotFoundScreen/>
            </div>
        ) 
    }

    const handlePasswordChange = (e) => {
        const password = e.target.value;
    
        const regexUppercase = /[A-Z]/.test(password);
        const regexLowercase = /[a-z]/.test(password);
        const regexDigit = /\d/.test(password);
        const regexSymbol = /[@#$%^&+=!]/.test(password);
        const regexAvaiableLength = /^.{8,}$/.test(password);
        const blankInput = password === '';
    
        setHasUppercase(regexUppercase);
        setHasLowercase(regexLowercase);
        setHasDigit(regexDigit);
        setHasSymbol(regexSymbol);
        setHasAvaiableLength(regexAvaiableLength);
        const isFormatValid =
          regexUppercase && regexLowercase && regexDigit && regexSymbol && regexAvaiableLength;
        setShowCheckFormatPassword(!isFormatValid);
        if (blankInput) {
            setShowCheckFormatPassword(false);
        }
    };

    return(
        <div className='setPassword-screen wrapper-content-form'>
            <form onSubmit={handleSubmit(onSubmit)} className='mg-t-35'>
                <div className='title fs-heading-large text-white font-semi-bold d-flex align-items-center'>
                    パスワード再設定
                </div>
                <div className='mg-t-15'>
                    <div className='form-floating input-floating'>
                        <input 
                            {...register('password', {
                                onChange: (e) => {
                                    getValues('passwordConfirm') && trigger('passwordConfirm');
                                    handlePasswordChange(e);
                                }
                            })} 
                            id='password' 
                            tabIndex={1}
                            placeholder='password' 
                            type={!showPassword ? 'password' : 'text'} 
                            className='form-control input-with-icon' 
                            autoComplete='off'/>
                        <label>
                            新しいパスワード 
                            <span className='text-color-cs-red'>*</span>
                        </label>
                    </div>
                    <button type='button' className='btn-icon-input' onClick={toggleShowPassword}>
                        <i className={classNames('text-white', showPassword ? 'fas fa-eye' : 'fas fa-eye-slash')}/>
                    </button>
                    {
                        errors?.password &&
                        <div className='text-danger mg-t-5 fs-error'>{errors.password?.message}</div>
                    }
                </div>
                <div className='mg-t-15'>
                    <div className='form-floating input-floating'>
                        <input 
                            {...register('passwordConfirm', {
                                onChange: () => {
                                    getValues('password') && trigger('password');
                                }
                            })} 
                            onSelect={() => {setShowCheckFormatPassword(false)}}
                            id='passwordConfirm' 
                            tabIndex={2}
                            placeholder='passwordConfirm' 
                            type={!showPasswordConfirm ? 'password' : 'text'} 
                            className='form-control input-with-icon' 
                            autoComplete='off'/>
                        <label htmlFor='passwordConfirm'>新しいパスワードを再入力 <span className='text-color-cs-red'>*</span></label>
                    </div>
                    <button type='button' className='btn-icon-input' onClick={toggleShowPasswordConfirm}>
                        <i className={classNames('text-white', showPasswordConfirm ? 'fas fa-eye' : 'fas fa-eye-slash')}/>
                    </button>
                    {
                        errors?.passwordConfirm &&
                            <div className='text-danger mg-t-5 fs-error'>{errors.passwordConfirm?.message}</div>
                    }
                </div>
                {
                    showCheckFormatPassword && (
                        <div className='pd-5 border-radius-10 bg-cs-password-format mg-t-30'>
                            <ul className='list-unstyled mg-0'>
                                <li 
                                    className={classNames('text-color-cs-7 fs-cs-14', 
                                        hasAvaiableLength ? 'text-color-cs-green font-bold' : 'text-color-cs-red')}>・8桁以上であること</li>
                                <li 
                                    className={classNames('text-color-cs-7 fs-cs-14', 
                                        hasUppercase ? 'text-color-cs-green font-bold' : 'text-color-cs-red')}>・少なくとも1桁の大文字を含むこと</li>
                                <li 
                                    className={classNames('text-color-cs-7 fs-cs-14', 
                                        hasLowercase ? 'text-color-cs-green font-bold' : 'text-color-cs-red')}>・少なくとも1桁の小文字を含むこと</li>
                                <li 
                                    className={classNames('text-color-cs-7 fs-cs-14', 
                                        hasDigit ? 'text-color-cs-green font-bold' : 'text-color-cs-red')}>・少なくとも1桁の数字を含むこと</li>
                                <li 
                                    className={classNames('text-color-cs-7 fs-cs-14', 
                                        hasSymbol ? 'text-color-cs-green font-bold' : 'text-color-cs-red')}>・少なくとも1桁の記号を含むこと</li>
                            </ul>
                        </div>
                    )
                }
                <button 
                    type='submit' 
                    className={classNames('btn btn-bg-cs-1 w-100', showCheckFormatPassword ? 'mg-t-30' : 'mg-t-80')} 
                    disabled={isSubmitting}>パスワード変更</button>
            </form>
        </div>
    )
})

export default SetPasswordScreen;