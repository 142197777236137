import { useEffect } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import yup from '../../core/utils/yupValidate';
import { MSG } from '../../core/configs/constants';
import { useStore } from '../../core/utils/hook';
import { observer } from 'mobx-react';
import { useFieldArray, useForm } from 'react-hook-form';
import ReactNotifications from '../../components/Notifications/ReactNotifications';
import './styles.scss'

const SettingDropAddressModal = observer((props) => {

    //store
    const { authStore: { accountInfo, getInfo, },
        userStore: { updateUserInfo },
        modalStore: { hide } } = useStore()
    //state
    const validateSchema = yup.object().shape({
        dropAddress: yup.array().of(yup.string().trim().required(MSG['error.dropAddress.required'])).nullable() 
    })
    const { control, handleSubmit, register, reset,
        formState: { errors, isSubmitting } } = useForm({ resolver: yupResolver(validateSchema), mode: 'onChange', });
    const { fields, append, remove } = useFieldArray({
        control,
        name: 'dropAddress'
    });
    //lifecycle
    // useEffect(() => {
    //     const getData = async () => {
    //         await getInfo();
    //     }
    //     getData();
    // }, [])
    useEffect(() => {
        if (accountInfo?.userName) {
            const { user: { dropAddress } } = accountInfo;
            reset({ dropAddress });
        }
    }, [accountInfo])
    //modal
    //function
    const onSubmitDropAddress = async (data) => {
        const { user : { fullName, fullNameFuri, birthday, phoneNumber, address, avatar } } = accountInfo;
        const res = await updateUserInfo({
            fullName, fullNameFuri, birthday, phoneNumber,
            address, avatar, dropAddress: data?.dropAddress ? data.dropAddress : []
        });
        if (res) {
            ReactNotifications('success', MSG['inform.success.update']);
            getInfo();
            hide();
        }
    }

    return (<div className='setting-drop-address-screen border-top border-top-white'>
        <form className='h-100' onSubmit={handleSubmit(onSubmitDropAddress)}>
            <div className='drop-address-list'>
                {fields.map((field, index) => {
                    return <><div key={field.id} className='drop-address-item 
                     w-100 d-flex align-items-center justify-content-start pd-8'>
                        <label className='width-125 text-color-cs-8 font-medium drop-label'>{`Drop先アドレス ${index + 1}`}</label>
                        <div className='flex-fill d-flex flex-row align-items-center pd-0'>
                            <div className='drop-item-input d-flex flex-column'>
                                <input className='w-100 input-type-2
                                 reset-auto-fill text-color-cs-white' {...register(`dropAddress.${index}`)} />
                            </div>
                            <div className='flex-fill mg-l-10 drop-remove btn-clear-input'
                                onClick={() => remove(index)}>
                                <i className="fa-solid fa-trash-can fs-heading-normal text-color-cs-1"></i>
                            </div>
                        </div>
                    </div>
                    <div className='drop-item-error d-flex align-items-center justify-content-start pd-lr-8 w-100 item-border-bottom'>
                        <div className='width-125'></div>
                        <>
                            {
                                errors.dropAddress && errors.dropAddress[index] &&
                                        <div className='drop-error flex-fill text-danger fs-error pd-0'>{errors.dropAddress[index]?.message}</div>
                            }
                        </>
                    </div>
                    </>
                })}
            </div>
            <div className='d-flex item-border-bottom justify-content-center align-items-center height-50' role='button' onClick={() => {
                append(' ');
            }
            }>
                <i className="fa-solid text-color-cs-1 mg-r-5 fa-circle-plus fs-heading-normal" />
                <span className='fs-label font-medium text-color-cs-1'>Drop先アドレスを追加する</span></div>
            <div className='btn-drop-group mg-tb-35 d-flex align-items-center justify-content-center flex-wrap'>
                <button className='btn btn-bg-cs-6 text-color-cs-white min-width-130 btn-common border-gold'
                    onClick={() => {hide()}}>キャンセル</button>
                <button type='submit' className='btn btn-bg-cs-1 min-width-130 btn-common' disabled={isSubmitting} >変更</button>
            </div>
        </form>
    </div>)
})

export default SettingDropAddressModal;