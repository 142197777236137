import { useForm } from 'react-hook-form';
import { useStore } from '../../core/utils/hook';
import { yupResolver } from '@hookform/resolvers/yup';
import yup from '../../core/utils/yupValidate';
import { MSG } from '../../core/configs/constants';
import './style.scss';
import { useNavigate } from 'react-router-dom';


const SendInquiryScreen = () => {

    //other
    const navigate = useNavigate();
    //store
    const { inquiryStore: { sendInquiry }, modalStore: {show, hide}} = useStore();
    //state
    const validateInquirySchema = yup.object().shape({
        fullName: yup
            .string()
            .max(24, MSG['error.length_24'])
            .required(MSG['error.required']),
        email: yup
            .string()
            .email(MSG['error.email_format'])
            .required(MSG['error.required']),
        phoneNumber: yup.string().max(11, MSG['error.phone_number_length'])
            .checkInputOnlyContainDigitsOrEmpty(MSG['error.phone_number_format']),
        content: yup.string().max(1000, MSG['error.length_1000']).required(MSG['error.required'])
    })
    const { register, handleSubmit, setValue, formState: { errors, isSubmitting }, reset } = useForm({
        resolver: yupResolver(validateInquirySchema),
        mode: 'onChange'
    });
    
    //lifecycle
    //modal
    const openSuccessfulAlert = () => {
        show({
            id: 'modal',
            isOpen: true,
            onRequestClose: () => hide(),
            notButtonX: true,
            children: (
                <div className='pd-tb-30 pd-lr-20'>
                    <div className='text-center'>
                        <i className='fa-solid fa-circle-check fs-cs-45'></i>
                        <div className='mg-t-10 fs-cs-16 font-semi-bold'>お問い合わせが送信されました。返信までしばらくお待ちください。</div>
                    </div>
                    <div className='mg-t-20 d-flex align-items-center justify-content-center'>
                        <button className='btn btn-bg-cs-1 min-width-200' onClick={() => hide()}>閉じる</button>
                    </div>
                </div>
            )
        })
    }
    //function
    const onSend = async (data) => {
        let res = await sendInquiry(data);
        if (res) {
            reset();
            openSuccessfulAlert();
        }
    }
    const handlePhone = (e) => {
        const curVale = e.target.value;
        const input = curVale.replace(/[^0-9]/g, '');
        setValue('phoneNumber', input);
    }


    return <div className="inquiry-screen">
        <div className='wrapper-form min-height-750'>
            <div className='wrapper-form-header text-center pd-t-40'>
                <h1 className='fw-semi-bold'>お問い合わせ窓口</h1>
                <p className='mg-t-10 mg-b-0 fw-medium fs-heading-normal'>* RAISEに関するご質問はこちらのフォームから、ご連絡ください。</p>
                <p className='fs-heading-normal'>内容確認し、運営より順次回答させていただきます。</p>
            </div>
            <div className='wrapper-form-content mg-t-20'>
                <form onSubmit={handleSubmit(onSend)} className='d-flex flex-column justify-content-center align-items-center'>
                    <div className='form-input'>
                        <input {...register('fullName')} id='fullName' name='fullName' className='form-control ' type='text' placeholder='名前*' />
                        {
                            errors?.fullName &&
                            <div className='text-danger mg-t-5 fs-error'>{errors.fullName?.message}</div>
                        }
                    </div>
                    <div className='form-input mg-t-25'>
                        <input {...register('email')} id='email' name='email' className='form-control ' type='text' placeholder='メールアドレス*' />
                        {
                            errors?.email &&
                            <div className='text-danger mg-t-5 fs-error'>{errors.email?.message}</div>
                        }
                    </div>
                    <div className='form-input mg-t-25'>
                        <input {...register('phoneNumber')} id='phoneNumber' name='phoneNumber'
                            className='form-control ' type='text' placeholder='電話番号' maxLength="11" onChange={handlePhone}/>
                        {
                            errors?.phoneNumber &&
                            <div className='text-danger mg-t-5 fs-error'>{errors.phoneNumber?.message}</div>
                        }
                    </div>
                    <div className='form-input mg-t-25'>
                        <textarea {...register('content')}
                            id='content' name='content' className='form-control ' type='text' placeholder='問い合わせ内容を記入してください*' maxLength={1000} />
                        {
                            errors?.content &&
                            <div className='text-danger mg-t-5 fs-error'>{errors.content?.message}</div>
                        }
                    </div>
                    <div className='btn-form d-flex justify-content-center w-100 pd-lr-30 mg-tb-40'>
                        <button type="submit" className='btn btn-bg-cs-1 width-400' disabled={isSubmitting}>送信</button>
                    </div>
                </form>
            </div>
        </div>
        
    </div>
}

export default SendInquiryScreen;