import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import ContainerBody from '../components/Container/ContainerBody';
import NotFoundScreen from '../screens/404/NotFoundScreen';
import { FOOTER_NAV, HEADER_NAV, SCREEN_MODE, SYSTEM_PATH, TYPE_GENERAL_SETTING } from '../core/configs/constants';
import { Authentication, ContainerBodyOnlyBackground, ContainerWithSidebar } from '../components';
import LoginScreen from '../screens/Login/LoginScreen';
import SendInquiryScreen from '../screens/Inquiry/SendInquiryScreen'
import HolderScreen from '../screens/Holder/HolderScreen';
import {
    ListCardBuy,
    ListCard,
    RegisterScreen,
    SetPasswordScreen,
    ForgetPasswordScreen,
    GeneralSettingScreen,
    ChangeEmailScreen,
    ChangePasswordScreen,
    AccountInfoScreen,
    EditAccountScreen,
    PurchaseHistory,
    SubcriptionHistory,
    CardBuyHistory,
    CardBuyDetail,
    PurchaseHolderScreen,
    PurchaseHolderDetailScreen,
    ExtendHolderScreen
} from '../screens';
import ConfirmEmailScreen from '../screens/ConfirmEmail/ConfirmEmailScreen';
import PurchaseCardsScreen from '../screens/Holder/PurchaseCardsScreen';
import { useEffect } from 'react';
import $ from 'jquery';
import 'animate.css';

const Root = () => {
    const location = useLocation();

    useEffect(() => {
        $('html, body').animate({ scrollTop: 0 }, 250, 'swing', () => { });
    }, [location.pathname]);

    return (
        <Routes>
            {/* with container only background - public route */}
            <Route element={<ContainerBodyOnlyBackground />}>
                <Route path={SYSTEM_PATH.LOGIN} element={<LoginScreen mode={SCREEN_MODE.LOGIN} />} />
                <Route path={SYSTEM_PATH.SET_PASSWORD} element={<SetPasswordScreen />} />
                <Route path={SYSTEM_PATH.FORGET_PASSWORD} element={<ForgetPasswordScreen />} />
                <Route path={SYSTEM_PATH.REGISTER} element={<RegisterScreen />} />
                <Route path={SYSTEM_PATH.CONFIRM_EMAIL} element={<ConfirmEmailScreen />} />
            </Route>

            {/* authentication with container body  */}
            <Route element={<Authentication />}>
                <Route element={<ContainerBody />}>
                    <Route path={SYSTEM_PATH.HOME} element={<Navigate to={SYSTEM_PATH.LOGIN} />} />
                    <Route path={SYSTEM_PATH.ADD_ACCOUNT} element={<LoginScreen mode={SCREEN_MODE.ADD_ACCOUNT} />} />
                    <Route path={SYSTEM_PATH.LIST_CARD} element={<ListCard />} />
                    <Route path={SYSTEM_PATH.HOLDER} element={<HolderScreen />} />
                    <Route path={SYSTEM_PATH.PURCHASE_CARD} element={<PurchaseCardsScreen />} />
                    <Route path={SYSTEM_PATH.SEND_INQUIRY} element={<SendInquiryScreen />} />
                    <Route path={SYSTEM_PATH.PURCHASE_HOLDER} element={<PurchaseHolderScreen />} />
                    <Route path={SYSTEM_PATH.EXTEND_HOLDER} element={<ExtendHolderScreen />} />

                    <Route element={<ContainerWithSidebar />}>
                        <Route path={SYSTEM_PATH.LIST_CARD_BUY} element={<ListCardBuy />} />
                        <Route path={SYSTEM_PATH.PURCHASE_HISTORY} element={<PurchaseHistory />} />
                        <Route path={SYSTEM_PATH.CHANGE_PASSWORD} element={<ChangePasswordScreen />} />
                        <Route path={SYSTEM_PATH.CHANGE_EMAIL} element={<ChangeEmailScreen />} />
                        <Route path={SYSTEM_PATH.ACCOUNT_INFO} element={<AccountInfoScreen />} />
                        <Route path={SYSTEM_PATH.EDIT_ACCOUNT} element={<EditAccountScreen />} />
                        <Route path={SYSTEM_PATH.SUBCRIPTION_HISTORY} element={<SubcriptionHistory />} />
                        <Route path={SYSTEM_PATH.CARD_BUY_HISOTRY} element={<CardBuyHistory />} />
                        <Route path={SYSTEM_PATH.CARD_BUY_DETAIL} element={<CardBuyDetail />} />
                        <Route path={SYSTEM_PATH.PURCHASE_HOLDER_DETAIL} element={<PurchaseHolderDetailScreen />} />
                    </Route>

                    <Route
                        path={FOOTER_NAV.TERMS.path}
                        element={<GeneralSettingScreen
                            tittle={FOOTER_NAV.TERMS.label}
                            type={TYPE_GENERAL_SETTING.TERMS} />} />
                    <Route
                        path={FOOTER_NAV.ACT_OF_SPECIFIED_COMMERCIAL_TRANSACTION.path}
                        element={<GeneralSettingScreen
                            tittle={FOOTER_NAV.ACT_OF_SPECIFIED_COMMERCIAL_TRANSACTION.label}
                            type={TYPE_GENERAL_SETTING.ACT_OF_SPECIFIED_COMMERCIAL_TRANSACTION} />} />
                    <Route
                        path={FOOTER_NAV.PRIVACY_POLICY.path}
                        element={<GeneralSettingScreen
                            tittle={FOOTER_NAV.PRIVACY_POLICY.label}
                            type={TYPE_GENERAL_SETTING.PRIVACY_POLICY} />} />
                    <Route
                        path={HEADER_NAV.ABOUT_US.path}
                        element={<GeneralSettingScreen
                            tittle={HEADER_NAV.ABOUT_US.label}
                            type={TYPE_GENERAL_SETTING.ABOUT_US} />} />
                </Route>
            </Route>

            {/* not found */}
            <Route path="*" element={<NotFoundScreen />} />
        </Routes>
    );
};

export default Root;
