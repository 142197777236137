/* eslint-disable indent */
import { observer } from 'mobx-react';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { MSG } from '../../../core/configs/constants';
import { useStore } from '../../../core/utils/hook';
import { yupResolver } from '@hookform/resolvers/yup';
import yup from '../../../core/utils/yupValidate'
import '../style.scss'
import ModalSwitchAccount from './ModalSwitchAccount';

const ModalAddAccount = observer((props) => {

    // props
    // store
    const {
        authStore: { addAccount, getAccountList },
        modalStore: { hide, show }
    } = useStore();

    // state
    const validateSchema = yup.object().shape({
        userName: yup.string().required(MSG['error.required'])
    })

    const { register, handleSubmit, getValues, setValue,
        formState: { errors } } = useForm({ resolver: yupResolver(validateSchema), mode: 'onChange' });


    // lifecycle

    //function

    //modal
    const openSwitchAccount = (id) => {
        show({
            id: 'modal-success-alert',
            isOpen: true,
            onRequestClose: () => hide(),
            onCancel: () => hide(),
            children: <ModalSwitchAccount id={id} />
        })
    }

    const onSubmitAddAccount = async (data) => {
        const res = await addAccount(data);
        if (res && res.id) {
            getAccountList();
            openSwitchAccount(res.id);
        }
    }

    return (
        <div className='modal-account'>
            <div className='pd-lr-20 pd-tb-20'>
                <form className='w-100' onSubmit={handleSubmit(onSubmitAddAccount)}>
                    <label className='field-required text-cs font-bold'>新しいアカウントのユーザーネームを入力してください。</label>
                    <div className='mg-t-5 w-100'>
                        <input autoFocus   {...register('userName')} className='w-100 input-type-2 text-color-cs-white' max={12}>
                        </input>
                        {
                            errors.userName &&
                            <div className='text-danger fs-error mg-t-5'>{errors.userName?.message}</div>
                        }
                    </div>
                    <div className='footer-button mg-t-25 flex-gap-20 d-flex align-items-center justify-content-center flex-wrap'>
                        <button type='button' className='btn btn-bg-cs-6-bd-cs-1 min-width-150' onClick={() => hide()}>キャンセル</button>
                        <button type='submit' className='btn btn-bg-cs-1 min-width-150'>追加</button>
                    </div>
                </form>
            </div>
        </div>
    )
})

export default ModalAddAccount;