import ApiService from './ApiService';

class GeneralSettingApi extends ApiService {
    constructor() {
        super('general');
    }
    getGeneralSetting(type) {
        return this.get(type);
    }
}

export default GeneralSettingApi;