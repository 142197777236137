import classnames from 'classnames';
import { observer } from 'mobx-react';
import { Link, Outlet, useLocation, useNavigate } from 'react-router-dom';
import Header from './Header';
import Footer from './Footer';

import './style.scss';
import { FOOTER_NAV, HEADER_NAV, SYSTEM_PATH } from '../../core/configs/constants';

const ContainerBody = observer(() => {

    // other
    const navigate = useNavigate();
    const location = useLocation();

    const CONTENT_BODY_WITH_EDITOR = [
        HEADER_NAV.ABOUT_US.path, 
        FOOTER_NAV.TERMS.path, 
        FOOTER_NAV.PRIVACY_POLICY.path, 
        FOOTER_NAV.ACT_OF_SPECIFIED_COMMERCIAL_TRANSACTION.path
    ];

    return(
        <div className='wrapper wrapper-body'>
            <div className='wrapper-container'>
                <div className='wrapper-header'>
                    <Header/>
                </div>
                <div className={classnames('wrapper-content', !CONTENT_BODY_WITH_EDITOR.includes(location.pathname) && 'wrapper-content-white')}>
                    <Outlet/>
                </div>
                <div className='wrapper-footer'>
                    <Footer/>
                </div>
            </div>
        </div>
    )
})

export default ContainerBody;