/* eslint-disable max-len */
import { yupResolver } from '@hookform/resolvers/yup';
import { observer } from 'mobx-react';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { Link, Navigate, useNavigate } from 'react-router-dom';
import { AIRdrop_STATUS, MSG, SYSTEM_PATH } from '../../core/configs/constants';
import { useStore } from '../../core/utils/hook';
import yup from '../../core/utils/yupValidate';
import classNames from 'classnames';
import './style.scss'
import { useEffect } from 'react';
import { toJS } from 'mobx';
import { FormatNumber, FullPagination } from '../../components';
import moment from 'moment';
import { calculateCardInfo } from '../../core/utils/common';
import { io } from 'socket.io-client';
import { URL_SOCKET_CARD, URL_SOCKET_PURCHASE } from '../../core/utils/socket';


// import ListImageCard from '/list_card.svg'




const ListCardBuy = observer(() => {

    // other
    const navigate = useNavigate();

    // store
    const { 
        modalStore: { show, hide, openAlert }, 
        purchaseStore: { getCardPurchase, cardPurchase, paging, setAttrObservable },
        authStore: { accountInfo } } = useStore();

    // function
    const onShow = () => {
        show({
            id: 'modal',
            isOpen: true,
            header: '確認',
            onCancel: () => hide(),
            children: (
                <div className='pd-tb-10 pd-lr-20'>
                    <span>Holderに追加しますか？</span>
                    <div className='mg-t-20 d-flex align-items-center justify-content-center flex-gap-20 flex-wrap'>
                        <button className='btn btn-bg-cs-6 width-130' onClick={() => hide()}>キャンセル</button>
                        <button className='btn btn-bg-cs-1 width-130'>解除</button>
                    </div>
                </div>
            )
        })
    }

    /// state
    const [cacheData, setCacheData] = useState([]);
    const purchaseStatus = (transaction) => {
        return Object.keys(AIRdrop_STATUS).filter(e => AIRdrop_STATUS[e].value === transaction)?.[0] ?? null;
    }

    //lifecycle
    useEffect(() => {
        const getData = async () => {
            onFetchCardList({ size: 10, page: 1 });
        }
        getData();

        return () => {
            setAttrObservable('cardPurchase', {})
        }
    }, [])

    useEffect(() => {
        const socketPurchase = io(URL_SOCKET_PURCHASE);

        socketPurchase.on('connect', () => {
            socketPurchase.emit('user-connected', {accountId: accountInfo?.id})
        })

        socketPurchase.on('update-airdrop-status', () => {
            onFetchCardList( paging, true);
        });

        return () => {
            socketPurchase.off('update-airdrop-status');
            socketPurchase.disconnect();
        };
    }, []);


    // function
    const onFetchCardList = async (payload, disableLoading) => {
        await getCardPurchase({
            ...payload
        }, disableLoading)
    }
    const onPageChange = (page) => {
        onFetchCardList({ page });
    }



    return (
        <div className='list-card-buy-screen list-purchase-history-screen pd-20 border-radius-10 bg-cs-container min-height-820'>
            <div className='mg-t-16 list-item'>
                <div className='mg-t-20 mg-b-20 fs-heading-large heading font-semi-bold'>購入済みカード一覧</div>
                {cardPurchase?.length > 0 ? cardPurchase?.map((item) => {
                    const variationRate = calculateCardInfo(item?.currentRate, item?.currencyRate, item)?.variationRate
                    return (
                        // eslint-disable-next-line max-len
                        <div key={item?.id} className='d-flex align-items-center justify-content-center col-12 flex-wrap mg-b-24'>
                            <div className='list-card-buy-item list-card-item-purchase d-flex flex-gap-20 align-items-center justify-content-center '>
                                <div className='width-180 card-detail-image '>
                                    <img className='w-100 ' src={item?.image}></img>
                                </div>
                                <div className='d-flex max-width-custom' style={{ 'maxWidth': 'calc(100% - 200px)' }}>
                                    <div className='d-flex align-items-center justify-content-start  '>
                                        <div className='d-flex align-items-center justify-content-start flex-wrap list-card-item-purchase-option'>
                                            <div className='d-flex align-items-center col-4 min-width-175 mg-b-30 min-width-custom card-item '>
                                                <div className='width-50'>
                                                    <div className='card-detail-label-item-icon p-1 
                                                d-flex align-items-center justify-content-center rounded  width-35 height-35 card-item-icon  '>
                                                        <img src='/images/icon_card-code.svg'></img>
                                                    </div>
                                                </div>
                                                <div>
                                                    <span className='d-block text-color-cs-13' >管理番号</span>
                                                    <span className='d-block font-semi-bold' >{item?.code}</span>
                                                </div>
                                            </div>
                                            <div className='d-flex align-items-center col-4 min-width-175 mg-b-30 min-width-custom card-item  '>
                                                <div className='width-50'>
                                                    <div className='card-detail-label-item-icon p-1 d-flex align-items-center 
                                                    justify-content-center rounded  width-35 height-35 card-item-icon '>
                                                        <img src='/images/icon_rate_buy.svg'></img>
                                                    </div>
                                                </div>

                                                <div>
                                                    <span className='d-block text-color-cs-13' >基準レート</span>
                                                    <span className='d-block font-semi-bold' >{item?.baseRate} USDT</span>
                                                </div>
                                            </div>
                                            <div className='d-flex align-items-center col-4 min-width-175 mg-b-30 min-width-custom card-item '>
                                                <div className='width-50'>
                                                    <div className='card-detail-label-item-icon p-1
                                                     d-flex align-items-center
                                                      justify-content-center  rounded  width-35 height-35 card-item-icon  '>
                                                        <img src='/images/icon_purchase_price.svg'></img>
                                                    </div>
                                                </div>

                                                <div>
                                                    <span className='d-block text-color-cs-13' >カード価格</span>
                                                    {item?.cardPrice || item?.cardPrice === 0 ?
                                                        <div className='d-block font-semi-bold'>
                                                            <FormatNumber statusDisplay='text' value={item?.cardPrice} suffix=' 円' />
                                                        </div> : ''}
                                                    {/* <span className='d-block font-semi-bold' >{item.date}</span> */}
                                                </div>
                                            </div>
                                            <div className='d-flex align-items-center col-4 min-width-175 mg-b-30 min-width-custom card-item  '>
                                                <div className='width-50'>
                                                    <div className='card-detail-label-item-icon p-1
                                                     d-flex align-items-center
                                                      justify-content-center  rounded  width-35 height-35 card-item-icon '>
                                                        <img src='/images/icon_calendar.svg'></img>
                                                    </div>
                                                </div>

                                                <div>
                                                    <span className='d-block text-color-cs-13' >発行日時</span>
                                                    <span className='d-block font-semi-bold' >{item?.publicAt ?
                                                        moment(item?.publicAt).format('YYYY/MM/DD') : ''}</span>
                                                </div>
                                            </div>
                                            <div className='d-flex align-items-center col-4 min-width-175 mg-b-30 min-width-custom card-item  '>
                                                <div className='width-50'>
                                                    <div className='card-detail-label-item-icon p-1
                                                     d-flex align-items-center
                                                     justify-content-center rounded  width-35 height-35 card-item-icon  bg-cs-gray'>
                                                        <img src='/images/icon_curent_rate.svg'></img>
                                                    </div>
                                                </div>
                                                <div>
                                                    <span className='d-block text-color-cs-13' >購入時レート</span>
                                                    <span className='d-block ' > <FormatNumber statusDisplay='text' value={item?.currentRate} decimalScale={6} suffix=' USDT' /></span>
                                                    {/* <span className='d-block ' >{parseFloat(item?.currentRate.toFixed(6))}</span> */}
                                                </div>
                                            </div>
                                            <div className='d-flex align-items-center col-4 min-width-175 mg-b-30 min-width-custom card-item  '>
                                                <div className='width-50'>
                                                    <div className='card-detail-label-item-icon p-1
                                                     d-flex align-items-center
                                                      justify-content-center  rounded  width-35 height-35 card-item-icon '>
                                                        <img src='/images/icon_calendar.svg'></img>
                                                    </div>
                                                </div>

                                                <div>
                                                    <span className='d-block text-color-cs-13' >カードの購入日時
                                                    </span>
                                                    <span className='d-block font-semi-bold' >
                                                        {item?.holdDate ? moment(item?.transaction?.transactionDate).format('YYYY/MM/DD HH:mm:ss') : ''}</span>
                                                </div>
                                            </div>
                                            <div className='d-flex align-items-center col-4 min-width-175 mg-b-30 min-width-custom card-item '>
                                                <div className='width-50'>
                                                    <div className='card-detail-label-item-icon p-1
                                                     d-flex align-items-center 
                                                     justify-content-center rounded bg-cs-gray width-35 height-35 card-item-icon '>
                                                        <img src='/images/icon_currency.svg'></img>
                                                    </div>
                                                </div>
                                                <div>
                                                    <span className='d-block text-color-cs-13' >Airdrop</span>
                                                    {item?.airdrop || item?.airdrop === 0 ?
                                                        <div className='d-block font-semi-bold'>
                                                            <FormatNumber statusDisplay='text' value={item?.airdrop} suffix=' SML' />
                                                        </div> : ''}
                                                </div>
                                            </div>

                                            <div className='d-flex align-items-center col-4 min-width-175 mg-b-30 min-width-custom card-item '>
                                                <div className='width-50'>
                                                    <div className='card-detail-label-item-icon p-1
                                                     d-flex align-items-center 
                                                     justify-content-center rounded bg-cs-gray width-35 height-35 card-item-icon '>
                                                        <img src='/images/icon_heartbeat_line.svg'></img>
                                                    </div>
                                                </div>
                                                <div>
                                                    <span className='d-block text-color-cs-13' >騰落率</span>
                                                    {variationRate > 0 ? <span className='d-block font-semi-bold' >
                                                        <FormatNumber className='d-block font-semi-bold text-setting' prefix='+' statusDisplay='text' value={variationRate} decimalScale={2} suffix=' %' />
                                                    </span> : variationRate < 0 ?
                                                        <span className='d-block text-danger' ><FormatNumber className='d-block text-danger' allowNegative statusDisplay='text' value={variationRate} decimalScale={2} suffix=' %' /></span> :
                                                        <span className='d-block ' >{variationRate} %</span>}

                                                </div>
                                            </div>

                                            <div className='d-flex align-items-center col-4 min-width-175 mg-b-30 min-width-custom card-item '>
                                                <div className='width-50'>
                                                    <div className='card-detail-label-item-icon p-1
                                                     d-flex align-items-center 
                                                     justify-content-center rounded bg-cs-gray width-35 height-35 card-item-icon '>
                                                        <img src='/images/icon_setting.svg'></img>
                                                    </div>
                                                </div>
                                                <div>
                                                    <span className='d-block text-color-cs-13' >ステータス</span>
                                                    <div className='d-block font-semi-bold'
                                                        style={{ 'color': AIRdrop_STATUS[purchaseStatus(item?.status)]?.color }} >
                                                        {purchaseStatus(item?.status) && AIRdrop_STATUS[purchaseStatus(item?.status)]?.label}
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* <div className='d-flex align-items-center justify-content-start content-center '>
                                        <div className='d-flex align-items-center justify-content-start content-center
                                         flex-wrap list-card-item-purchase-option'>
                                            <div className='d-flex align-items-center col-4 min-width-175 mg-b-30 min-width-custom card-item '>
                                                <div className='width-50'>
                                                    <div className='card-detail-label-item-icon p-1 
                                                d-flex align-items-center justify-content-center rounded  width-35 height-35 card-item-icon  bg-cs-gray '>
                                                        <img src='/images/icon_setting.svg'></img>
                                                    </div>
                                                </div>
                                                <div>
                                                    <span className='d-block text-color-cs-13' >ステータス</span>
                                                    <div className='d-block font-semi-bold'
                                                        style={{ 'color': AIRdrop_STATUS[purchaseStatus(item?.status)]?.color }} >
                                                        {purchaseStatus(item?.status) && AIRdrop_STATUS[purchaseStatus(item?.status)]?.label}
                                                    </div>
                                               
                                                </div>
                                            </div>
                                        </div>
                                    </div> */}
                                </div>
                            </div>
                        </div>

                    )
                }
                ) : <div className='d-flex align-items-center justify-content-center mg-t-10 '>
                    <span className='fs-heading-normal font-bold text-color-cs-1'>{MSG['inform.no_data']}</span>
                </div>}
            </div>
            <div className='d-flex align-items-center justify-content-center '>
                <FullPagination siblingCount={0}
                    totalRecords={paging.totalRecord}
                    currentPage={paging.page}
                    pageSize={paging.size}
                    onPageChange={onPageChange}
                    previousLabel="«"
                    nextLabel="»" />
            </div>


        </div>

    )
})

export default ListCardBuy;