import ApiService from './ApiService';

class Transaction extends ApiService {
    constructor() {
        super('transaction');
    }

    getAllTransaction(payload) {
        return this.get('', payload);
    }
    getDetailTransaction({ id }) {
        return this.get(id);
    }
    cancelTransaction({ id }) {
        return this.put(`cancel/${id}`);
    }
}

export default Transaction;
