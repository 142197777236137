import { flow, makeObservable, observable } from 'mobx';
import { UserApi } from '../apis';
import BaseStore from './BaseStore';

class UserStore extends BaseStore {

    userInfo = {};


    constructor(rootStore) {
        super();
        makeObservable(this, {
            userInfo: observable,
            getUserInfo: flow.bound,
            updateUserInfo: flow.bound,
        })
        this.rootStore = rootStore;
        this.api = new UserApi();
    }

    *getUserInfo() {
        try {
            const res = yield this.rootStore.apiStore.call(this.api, this.api.getUserProfile);
            if (res?.ok) {
                this.userInfo = res?.data ?? {};
            }
        } catch (error) {
            console.log(error);
        }
    }


    *updateUserInfo(payload) {
        try {
            const res = yield this.rootStore.apiStore.call(this.api, this.api.updateUserProfile, payload);
            if(res?.ok) {
                this.userInfo = { ...this.user, ...(res?.data ?? {}) };
                return true;
            }
            return false;
        } catch (error) {
            console.log(error);
        }
    }

    clean() {
        super.clean();
        this.userInfo = {};
    }
}

export default UserStore;