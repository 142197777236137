export const getStringAfterLastSlash = (str = '', char = '.', removeQuery = false) => {
    if (!str) return '';
    const n = str.lastIndexOf(char);
    let converted = str.substring(n + 1);
    if (removeQuery && converted.includes('?')) {
        converted = converted.split('?')[0] || ''
    }
    return converted;
};

export const customToolBarEditor = ['bold', 'italic', 'strikethrough', 'underline', '|',
    'fontfamily', 'fontsize', 'fontColor', 'fontBackgroundColor', '|',
    'alignment', 'outdent', 'indent', '|', 'bulletedList', 'numberedList', '|',
    'link', '|', 'undo', 'redo'];

export const customFullToolBarEditor = [
    'heading',
    '|',
    'bold', 'italic', 'underline', 'superscript', 'subscript',
    '|',
    'fontFamily', 'fontSize', 'fontBackgroundColor', 'fontColor', 'highlight',
    '|',
    'alignment', 'indent', 'outdent',
    '|',
    'numberedList', 'bulletedList', 'todoList', 'insertTable',
    '|',
    'blockQuote', 'code', 'codeBlock', 'specialCharacters', 'horizontalLine',
    '|',
    'link', 'imageUpload', 'imageInsert', 'mediaEmbed',
    '|',
    'findAndReplace',
    '|',
    'undo',
    'redo'
]

export const isNumeric = (value) => {
    return /^\d+$/.test(value);
}

export const countCharacterOfNumber = (num) => {
    if (!num || !isNumeric(num)) return 0;

    return String(num).length;
}

// regex text
export const furiganaRegex = /^(([ァ-ン]|ー)|[0-9])*$/;
export const passwordRegex = /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@#$%^&+=!]).{8,}$/;

//caculate 
export const calculateCardInfo = (currentRate, currencyRate, card) => {
    let result = {
        variationRate: 0,
        currentRate,
        currencyRate,
        currentPrice: 0
    }

    if (!card) return result;

    if (currentRate && card?.baseRate) {
        result.variationRate = ((currentRate / card?.baseRate) - 1) * 100;
    }

    if (currentRate && card?.airdrop) {
        result.currentPrice = currentRate * currencyRate * (card?.airdrop || 0);
    }

    return result;
}

export const roundDecimalNumber = (num, round = 1) => {
    if(!num) return 0;
    if(!round || round < 1) return num;
    const roundedNumber = Math.pow(10, round);
    return Number(num).toFixed(round) * roundedNumber / roundedNumber;
}