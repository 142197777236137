/* eslint-disable max-len */
import { yupResolver } from '@hookform/resolvers/yup';
import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Link, Navigate, useNavigate } from 'react-router-dom';
import { MSG, SYSTEM_PATH, ACCOUNT_STATUS, PURCHASE_STATUS, PAYMENT_TYPE, MEMBERSHIP_TYPE_TITTLE } from '../../core/configs/constants';
import { useStore } from '../../core/utils/hook';
import yup from '../../core/utils/yupValidate';
import classNames from 'classnames';
import './style.scss'
import { FormatNumber, FullPagination, ReactNotifications } from '../../components';
import { toJS } from 'mobx';
import moment from 'moment';
import { io } from 'socket.io-client';
import { URL_SOCKET_PURCHASE } from '../../core/utils/socket';
// import ListImageCard from '/list_card.svg'




const SubcriptionHistory = observer(() => {

    // other
    const navigate = useNavigate();

    // store
    const { modalStore: { show, hide, openAlert }, authStore: { cancelPremium, accountInfo, getInfo },
        transactionStore: { listTransaction, getAllTransaction, paging, setAttrObservable } } = useStore();



    //state
    const { register, getValues, watch, handleSubmit } = useForm({
        mode: 'onChange', defaultValues: {
            // price: '0',
            // sort: '0'
        }
    });

    const purchaseStatus = (transaction) => {
        return Object.keys(PURCHASE_STATUS).filter(e => PURCHASE_STATUS[e].value === transaction)?.[0] ?? null;
    }

    //lifecycle
    useEffect(() => {
        onFetchSubcriptionList({ page: 1, size: 10, type: PAYMENT_TYPE.MEMBERSHIP });
        return () => {
            setAttrObservable('listTransaction', []);
        }
    }, [])

    useEffect(() => {
        const socketPurchase = io(URL_SOCKET_PURCHASE);

        socketPurchase.on('connect', () => {
            socketPurchase.emit('user-connected', {accountId: accountInfo?.id})
        })

        socketPurchase.on('accept-purchase-status', () => {
            onSearch(true);
        });

        return () => {
            socketPurchase.off('accept-purchase-status');
            socketPurchase.disconnect();
        };
    }, []);

    // function
    const onShow = () => {
        show({
            id: 'modal',
            isOpen: true,
            header: '確認する',
            onCancel: () => hide(),
            children: (
                <div className='pd-tb-15 pd-lr-20'>
                    <div className='pd-b-10'>よろしいですか。</div>
                    <div>有料会員を解約すると、Holder一覧が使用できなくなり、保有されているカードもHolder一覧からすべて削除されます。</div>
                    <div className='mg-t-20 d-flex align-items-center justify-content-center flex-gap-20 flex-wrap'>
                        <button className='btn btn-bg-cs-6 width-130 btn-bg-cs-6-bd-cs-1' onClick={() => hide()}>いいえ</button>
                        <button className='btn btn-bg-cs-1 width-130' onClick={onCancelPremium}>はい</button>
                    </div>
                </div>
            )
        })
    }

    const onCancelPremium = async () => {
        hide();
        const res = await cancelPremium()
        if (res) {
            ReactNotifications('success', MSG['inform.success.create']);
            getInfo()
            onFetchSubcriptionList({ page: 1, size: 10, type: PAYMENT_TYPE.MEMBERSHIP });

        }
    }


    const onSearch = (disableLoading) => {
        onFetchSubcriptionList({ page: 1, size: 10, type: PAYMENT_TYPE.MEMBERSHIP }, disableLoading);
    }


    const onFetchSubcriptionList = async (payload, disableLoading) => {
        const { transactionCode, transactionStatus } = getValues();
        await getAllTransaction({
            transactionCode: transactionCode || null,
            transactionStatus: transactionStatus || null,
            type: PAYMENT_TYPE.MEMBERSHIP,
            ...payload
        }, () => {}, disableLoading);
    }

    // function

    const onPageChange = (page) => {
        onFetchSubcriptionList({ page });
    }



    // function


    return (
        <div className='subcription-history-screen
         list-card-screen  list-purchase-history-screen pd-20 border-radius-10 bg-cs-container list-purchase-history-responsive min-height-820 payment-screen'>
            <div className='d-flex align-items-center justify-content-between flex-gap-10 flex-wrap mg-t-20 subcription-history-screen-header '>
                <div className=' fs-heading-large font-semi-bold '>月額支払い</div>
                {toJS(accountInfo) && toJS(accountInfo)?.status == ACCOUNT_STATUS.SUBSCRIBED.key ? <div role='button' onClick={onShow}
                    className='width-210 height-30 d-flex align-items-center justify-content-center flex-gap-10 bg-cs-red border-radius-5'>
                    <img src='/images/icon_notify_error.svg'></img>
                    <div>有料会員を解約する</div>
                </div> : <></>}

            </div>
            <div className='w-full mg-t-40 '>
                <form onSubmit={handleSubmit(onSearch)} >
                    <div className='d-flex align-items-center justify-content-between flex-gap-80 flex-wrap list-card-list-select'>

                        <div className='d-flex align-items-center flex-gap-10 list-card-list-select-item  col-6-select  flex-wrap '>
                            <label className='col-2' >取引No</label >
                            <input {...register('transactionCode')} className='col-10-custom select-custom input-custom'>
                            </input>
                        </div>
                        <div className='d-flex align-items-center flex-gap-10 list-card-list-select-item  col-6-select  flex-wrap '>
                            <label className='col-2'>支払い状態</label >
                            <select {...register('transactionStatus')} className='col-10-custom select-custom height-45'>
                                <option value={''}>すべて</option>
                                {
                                    // eslint-disable-next-line max-len
                                    Object.keys(PURCHASE_STATUS).map(e => <option key={PURCHASE_STATUS[e].value} value={PURCHASE_STATUS[e].value} >{PURCHASE_STATUS[e].label}</option>)
                                }
                            </select>
                        </div>
                    </div>
                    <button type='submit' className='btn btn-bg-cs-1 min-width-150 d-block mx-auto mg-tb-40' >検索</button>
                </form>
                <div className='row mg-t-16 list-item'>
                    {listTransaction?.length > 0 ? listTransaction.map((item) => (
                        // eslint-disable-next-line max-len
                        <div key={item.id} className='d-flex align-items-center justify-content-center col-lg-6 col-sm-12 flex-wrap mg-b-24 history-purchase-card align-self-start'>
                            <div className='list-card-item row py-4 min-height-300'>
                                <div className='col-4 card-detail-image d-flex  align-items-center '>
                                    <img className='w-100 max-width-190'
                                        src='/images/icon_subcription_history.svg'></img>
                                </div>
                                {item?.membershipType !== null ? <div className='col-8 history-purchase-card-label '>
                                    <div className='mg-b-10 pd-t-15 fs-heading-normal font-semi-bold'>{item?.code}</div>
                                    <div className='mg-b-10 fs-label font-semi-bold text-color-cs-4'>月額料支払い

                                    </div>
                                    <div className='d-inline-grid align-items-center justify-content-center'>

                                        <div className='d-flex align-items-center history-purchase-card-label-item mg-b-10'>
                                            <div className='width-140 subcription-label-width text-color-cs-9 text-left'>会員料金プラン</div>
                                            <div className='w-full'>
                                                {item?.membershipType === 1 ? MEMBERSHIP_TYPE_TITTLE.MONTHLY : item?.membershipType === 2 ? MEMBERSHIP_TYPE_TITTLE.ANNUAL : ''}
                                            </div>
                                        </div>
                                        <div className='d-flex align-items-center history-purchase-card-label-item mg-b-10'>
                                            <div className='width-140 subcription-label-width text-color-cs-9 text-left'>支払総額:</div>
                                            {item?.totalPayment > 0 ? <div className='text-color-cs-14 w-full'>
                                                <FormatNumber className='text-color-cs-14' statusDisplay='text' value={item?.totalPayment} suffix=' 円' /> </div> : ''}
                                        </div>
                                        <div className='d-flex align-items-center  history-purchase-card-label-item mg-b-10'>
                                            <div className='width-140 subcription-label-width text-color-cs-9 text-left' >作成日:</div>
                                            <div className='w-full'>{item?.createdAt ? moment(item?.createdAt).format('YYYY/MM/DD HH:mm:ss') : ''}</div>
                                        </div>
                                        <div className='d-flex align-items-center  history-purchase-card-label-item '>
                                            <div className='width-140 subcription-label-width text-color-cs-9 text-left' >支払日:</div>
                                            <div className='w-full'>{item?.transactionDate ? moment(item?.transactionDate).format('YYYY/MM/DD HH:mm:ss') : ''}</div>
                                        </div>
                                    </div>
                                </div> : <div className='col-8 history-purchase-card-label '>
                                    <div className='mg-b-10 pd-t-15 fs-heading-normal font-semi-bold'>有料会員解約</div>
                                    <div className='d-inline-grid align-items-center justify-content-center'>
                                        <div className='d-flex align-items-center  history-purchase-card-label-item mg-tb-10'>
                                            <div className='width-80 subcription-label-width text-color-cs-9 text-left' >解約日:</div>
                                            <div className='w-full'>{item?.createdAt ? moment(item?.createdAt).format('YYYY/MM/DD HH:mm:ss') : ''}</div>
                                        </div>
                                    </div>
                                </div>}

                            </div>
                            {item?.membershipType === null ? <></> : <div className='notification d-flex justify-content-center align-items-center width-120 height-20
                              border-radius-10' style={{ 'backgroundColor': PURCHASE_STATUS[purchaseStatus(item?.status)]?.color }} >
                                <div> {purchaseStatus(item?.status) && PURCHASE_STATUS[purchaseStatus(item?.status)]?.label}</div>
                            </div>}




                        </div>

                    )) : <div className='d-flex align-items-center justify-content-center mg-t-10 '>
                        <span className='fs-heading-normal font-bold text-color-cs-1'>{MSG['inform.no_data']}</span>
                    </div>}

                </div>

            </div>

            <div className='d-flex align-items-center justify-content-center '>
                <FullPagination siblingCount={0}
                    totalRecords={paging.totalRecord}
                    currentPage={paging.page}
                    pageSize={paging.size}
                    onPageChange={onPageChange}
                    previousLabel="«"
                    nextLabel="»" />
            </div>
        </div>
    )
})

export default SubcriptionHistory;