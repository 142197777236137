import ApiService from './ApiService';

class InquiryApi extends ApiService {
    constructor() {
        super('inquiry');
    }

    getAllInquiry(payload) {
        return this.get('', payload);
    }
    sendInquiry(payload) {
        return this.post('', payload);
    }

    getInquiry({ id, payload }) {
        return this.get(id, payload);
    }

    deleteInquiry({ id, payload }) {
        return this.delete(id, payload);
    }
}

export default InquiryApi;