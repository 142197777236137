import ApiService from './ApiService';

class PurchaseApi extends ApiService {
    constructor() {
        super();
    }

    getMembershipFee() {
        return this.get('membership-fee');
    }
    
    registerMembership(payload) {
        return this.post('register-membership', payload);
    }

    purchaseAddHolderFee(payload) {
        return this.post('buy-holder', payload);
    }

    cancelHolder({ id, payload }) {
        return this.put(`cancel-holder/${id}`, payload);
    }

    getCardPurchase(payload) {
        return this.get('card-purchased', payload);
    }

    getExpireHolder() {
        return this.get('expire-holder');
    }
    
    purchaseExpireHolder(payload) {
        return this.post('expire-holder/extend', payload);
    }

    getListHoldersCanBuy() {
        return this.get('can-buy-holder')
    }

    getListHoldersCanExtend() {
        return this.get('can-extend-holder')
    }

    extendHolders(payload) {
        return this.post('extend-holders', payload)
    }

    buyHolders(payload) {
        return this.post('buy-holders', payload)
    }
}

export default PurchaseApi;
