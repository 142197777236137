import { action, flow, makeObservable, observable } from 'mobx';
import { GeneralSettingApi } from '../apis';
import { TYPE_GENERAL_SETTING } from '../core/configs/constants';

class GeneralSettingStore {

    holderFee = 0;
    
    constructor(rootStore) {
        makeObservable(this, {
            holderFee: observable,
            getGeneralSetting: flow.bound,
            clean: action.bound,
        })
        this.rootStore = rootStore;
        this.api = new GeneralSettingApi();
    }

    *getGeneralSetting( type ) {
        try {
            const res = yield this.rootStore.apiStore.call(this.api, this.api.getGeneralSetting, type);
            if (res) {
                if (type === TYPE_GENERAL_SETTING.OTHER_SETTING) {
                    this.holderFee = res.data?.holderFee || 0;
                    return;
                }
                return res;
            }
        } catch (error) {
            console.log(error);
        }
    }

    clean() {
        this.holderFee = 0;
    }
}

export default GeneralSettingStore;