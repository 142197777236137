import { Suspense } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Root from './routes/Root';
import { ReactNotifications } from 'react-notifications-component';
import { Loading, ReactModal } from './components';
import Modal from 'react-modal';

import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import 'animate.css';
import 'react-date-range/dist/styles.css'; 
import 'react-date-range/dist/theme/default.css'; 
import 'react-datepicker/dist/react-datepicker.css';
import 'react-tabs/style/react-tabs.css';
import './App.scss';
import './responsive.scss';
// import '../public/meiryo.ttc';


// Make sure to bind modal to your appElement (http://reactcommunity.org/react-modal/accessibility/)
Modal.setAppElement('#root');

const App = () => {
    return (
        <div className='app'>
            <Suspense fallback=''>
                <ReactNotifications isMobile={true} breakpoint={500}/>
                <BrowserRouter>
                    <Routes>
                        <Route>
                            <Route path='/*' element={ <Root/> }/>
                        </Route>
                    </Routes>
                    <ReactModal/>
                </BrowserRouter>
                <Loading/>
            </Suspense>
        </div>
    );
}

export default App;
