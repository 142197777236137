/* eslint-disable indent */
import ApiStore from './ApiStore';
import AuthStore from './AuthStore';
import ModalStore from './ModalStore';
import GeneralSettingStore from './GeneralSettingStore';
import UserStore from './UserStore';
import InquiryStore from './InquiryStore';
import CardStore from './CardStore';
import PurchaseStore from './PurchaseStore';
import TransactionStore from './TransactionStore';
class RootStore {

        constructor() {
                this.modalStore = new ModalStore(this);
                this.apiStore = new ApiStore(this);
                this.authStore = new AuthStore(this);
                this.generalSettingStore = new GeneralSettingStore(this);
                this.userStore = new UserStore(this);
                this.inquiryStore = new InquiryStore(this);
                this.cardStore = new CardStore(this);
                this.purchaseStore = new PurchaseStore(this);
                this.transactionStore = new TransactionStore(this);
        }
}

export default RootStore;