import { observer } from 'mobx-react';
import { ACCOUNT_STATUS, HEADER_NAV, SYSTEM_PATH } from '../../core/configs/constants';
import { Link, useNavigate } from 'react-router-dom';
import { useStore } from '../../core/utils/hook';
import { useEffect } from 'react';
import { toJS } from 'mobx';
import ModalAddAccount from './component/ModalAddAccount';
import { useRef } from 'react';

const Header = observer(props => {

    // other
    const navigate = useNavigate();

    // store
    const { authStore: { getInfo, accountInfo, accountList, getAccountList, switchAccountById, logout, logoutAll }
        , modalStore: { hide, openWarningModal, show } } = useStore();

    // lifecycle 
    useEffect(() => {
        getInfo();
    }, [])

    useEffect(() => {
        if (accountInfo?.user?.id) {
            getAccountList();
        }
    }, [accountInfo])

    // function
    const onSwitchAccount = (id) => {
        switchAccountById(id);
        window.location.href = SYSTEM_PATH.HOLDER;
    }

    const onLogout = async () => {
        const res = await logoutAll();
        if (res) {
            navigate(0);
        }
    }


    const onShowModalAddAccount = () => {
        show({
            id: 'modal-alert',
            isOpen: true,
            header: 'アカウント追加',
            onCancel: () => {
                hide();
            },
            children: (
                <ModalAddAccount />
            ),
            type: 'small'
        });

    }

    return (
        <div className='header-container'>
            <div className='header-nav-dropdown dropdown'>
                <i className='fa-solid fa-bars header-menu dropdown'
                    type='button' data-bs-toggle='dropdown' aria-expanded='false' />
                <ul className='dropdown-menu dropdown-cs-menu dropdown-cs-menu-left'>
                    {
                        Object.keys(HEADER_NAV).map((key, idx) => (
                            <li key={idx}>
                                <Link to={HEADER_NAV[key].path}
                                    className='dropdown-item font-semi-bold'>
                                    {HEADER_NAV[key].label}
                                </Link>
                            </li>
                        ))
                    }
                </ul>
            </div>
            <img className='header-logo' src='/images/logo.png' alt='logo' />
            <div className='header-nav'>
                {
                    Object.keys(HEADER_NAV).map((key, idx) => (
                        <Link key={idx} to={HEADER_NAV[key].path}
                            className='text-white fs-heading-normal font-bold'>
                            {HEADER_NAV[key].label}
                        </Link>
                    ))
                }
            </div>
            
            <div className='dropdown header-avatar'>
                <div className='avatar dropdown' type='button' data-bs-toggle='dropdown' aria-expanded='false'>
                    <i className='fa-solid fa-caret-down' />
                    <img src={accountInfo?.user?.avatar || '/images/default_avatar.jpg'} alt='avatar' />
                </div>
                <ul className='dropdown-menu dropdown-cs-menu dropdown-cs-menu-right'>
                    <li>
                        <div className='d-flex align-items-center flex-gap-10'>
                            <img src={accountInfo?.user?.avatar || '/images/default_avatar.jpg'} alt='avatar' />
                            <div className='info-account'>
                                <div className='font-semi-bold fs-cs-14'>{accountInfo?.userName}</div>
                                <div className='font-medium fs-cs-12 text-color-cs-5'>{accountInfo?.user?.email}</div>
                            </div>
                        </div>
                    </li>
                    <li onClick={() => navigate(SYSTEM_PATH.ACCOUNT_INFO)} className='pd-tb-12' role='button'>
                        <div className='text-color-cs-1'>
                            <i className='fa-solid fa-user text-color-cs-1' />
                            <span className='font-regular mg-l-5 text-color-cs-1'>マイページ</span>
                        </div>
                    </li>
                    {
                        accountList?.length > 1 &&
                        <>
                            <li className='pd-tb-12'>
                                <div className='text-color-cs-1'>アカウント</div>
                            </li>
                            <li className='lst-user'>
                                <ul className='bg-cs-gray-header lst-user-list'>
                                    {
                                        accountList.filter(e => e.account?.id !== accountInfo?.id).map(e => (
                                            <li key={e.account?.id} className='pd-tb-10' onClick={() => onSwitchAccount(e.account?.id)} role='button'>
                                                <div className='d-flex align-items-center justify-content-between'>
                                                    <div className='info-account d-flex align-items-center flex-gap-15'>
                                                        <i className='fa-regular fa-user border border-white rounded-circle pd-tb-5 pd-lr-6'></i>
                                                        <div className='font-semi-bold fs-cs-14'>{e?.account?.userName}</div>
                                                    </div>
                                                    <i className='fa-solid fa-arrow-right fs-cs-18'></i>
                                                </div>
                                            </li>
                                        ))
                                    }
                                </ul>
                            </li>
                        </>
                    }
                    {
                        accountList?.length > 0 && accountList.filter(acc => acc.account?.status === ACCOUNT_STATUS.SUBSCRIBED.key).length > 0 &&
                        <li onClick={onShowModalAddAccount} className='pd-lr-15 font-regular pd-tb-12' role='button'>
                            <div className='d-flex align-items-center flex-gap-5'>
                                <i className='fa-regular fa-plus fs-cs-11 border border-white rounded-circle pd-tb-4 pd-lr-5' />
                                <span className='fs-cs-14'>別のアカウントを追加</span>
                            </div>
                        </li>
                    }


                    <li onClick={() => onLogout()} className='pd-tb-12' role='button'>
                        <div>
                            <i className='fa-solid fa-power-off text-color-cs-1' />
                            <span className='font-regular text-color-cs-1 mg-l-5'>ログアウト</span>
                        </div>
                    </li>

                </ul>
            </div>
        </div>
    )
})

export default Header;