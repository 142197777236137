import { useEffect, useMemo } from 'react';
import { useStore } from '../../core/utils/hook';
import { observer } from 'mobx-react';
import { useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import yup from '../../core/utils/yupValidate';
import { FormatNumber } from '../../components';
import { MSG, SYSTEM_PATH, TEXT_MONTHLY, TYPE_GENERAL_SETTING } from '../../core/configs/constants';
import ModalBuyHoldersSuccess from './Modal/ModalBuyHoldersSuccess';
import ExtendHolderList from './ExtendHolderList';

const ExtendHolderScreen = observer(() => {
    // other
    const { state: { accountId, holderId } } = useLocation();
    const navigate = useNavigate();

    // store
    const { 
        purchaseStore: { listHolderCanExtend, getListHoldersCanExtend, setAttrObservable, extendHolders },
        generalSettingStore: { holderFee, getGeneralSetting },
        modalStore: { show, hide }
    } = useStore();

    // state
    const validateSchema = yup.object().shape({
        extendHolder: yup.array().of(yup.object().shape({
            accountId: yup.number(),
            holders: yup.array().of(yup.object().shape({
                holderId: yup.number(),
                expiryStartDate: yup.date(),
                monthHolder: yup.number(),
                isChecked: yup.boolean()
            }))
        }))
    })

    const formMethods = useForm({ resolver: yupResolver(validateSchema), mode: 'onChange' });
    const { 
        watch, 
        handleSubmit
    } = formMethods;

    const watchExtendHolder = watch('extendHolder');

    const total = useMemo(() => {
        if(!watchExtendHolder) return {
            totalHolder: 0,
            totalPayment: 0
        };
        return {
            totalHolder: watchExtendHolder.reduce((total, current) => total + current.holders.filter(e => e.isChecked).length, 0),
            totalPayment: watchExtendHolder.reduce((total, current) => total + 
                Number(current.holders.filter(e => e.isChecked).reduce((sum, e) => sum + Number(e.monthHolder), 0)), 0)
        }
    }, [JSON.stringify(watchExtendHolder)])

    // life cycle
    useEffect(() => {
        fetchData();
        return () => {
            setAttrObservable('listHolderCanExtend', []);
        }
    }, [])

    // function
    const fetchData = () => {
        getListHoldersCanExtend();
        onGetHolderFee();
    }

    const onGetHolderFee = () => {
        getGeneralSetting(TYPE_GENERAL_SETTING.OTHER_SETTING)
    }

    const onSubmit = async (data) => {
        const payload = {
            extendHolders: data.extendHolder.map(holder => (
                {
                    accountId: holder.accountId,
                    holders: holder.holders.filter(item => item.isChecked).map(item => (
                        {
                            holderId: item.holderId,
                            months: item.monthHolder
                        }
                    ))
                }
            )).filter(item => item.holders.length > 0)
        }

        const res = await extendHolders(payload);
        if(res) {
            onShowModalSuccess();
        }
    }

    const handleGoBackHolder = () => {
        navigate(SYSTEM_PATH.HOLDER);
    }

    const onShowModalSuccess = () => {
        show({
            id: 'modal-alert',
            isOpen: true,
            notButtonX: true,
            children: <ModalBuyHoldersSuccess/>
        })
    }

    return (
        <form onSubmit={handleSubmit(onSubmit)} className='extend-holder-screen d-flex gap-3'>
            <div className='pd-20 border-radius-10 bg-cs-container w-75'>
                <div className='font-semi-bold fs-heading-large pd-b-15 mg-b-15 purchase-holder-line'>Holderのサブスクを延長</div>
                <div className='border-radius-10 bg-cs-light-yellow opacity-10 pd-10 fs-label'>
                    <div className='font-semi-bold'>
                        Holder追加料
                        <span className='mg-l-25'>
                            <FormatNumber
                                statusDisplay='text' 
                                value={holderFee} 
                                suffix=' 円/月' />
                        </span>
                    </div>
                    <div className='font-medium'>サブスクリプションを延長したいHolderとご希望の利用期間を選択してください。</div>
                </div>
                {
                    listHolderCanExtend?.accounts?.length > 0 ? (
                        <div>
                            <ExtendHolderList 
                                accountId={accountId}
                                holderId={holderId}
                                formMethods={formMethods}
                                data={listHolderCanExtend.accounts}
                                holderFee={holderFee}
                            />
                        </div>
                    ) : (
                        <div className='text-center mg-t-20'>{MSG['inform.no_data']}</div>
                    )
                }
            </div>
            <div className='purchase-holder-submit pd-20 border-radius-10 bg-cs-container align-self-start w-25'>
                <div className='title-purchase-button d-flex justify-content-between align-items-center 
                pd-b-10 font-regular mg-b-10 mg-t-25 purchase-holder-line'>
                    <span>Holder総枠数 :</span>
                    <span>{total.totalHolder}</span>
                </div>
                <div className='d-flex justify-content-between align-items-center pd-b-10 font-semi-bold'>
                    <span>支払総額 :</span>
                    <FormatNumber
                        className='text-color-cs-medium-yellow fs-description-large' 
                        statusDisplay='text' 
                        value={total.totalPayment*holderFee} 
                        suffix=' 円' />
                </div>
                <button className='btn btn-bg-cs-1 btn-common w-100 mg-t-20' type='submit' disabled={total.totalHolder == 0}>Holder追加</button>
                <button 
                    onClick={handleGoBackHolder}
                    className='btn btn-cs-1 text-color-cs-white w-100 btn-common mg-t-20' 
                    type='button'>キャンセル</button>
            </div>
        </form>
    )
})

export default ExtendHolderScreen;