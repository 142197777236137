import { NumericFormat } from 'react-number-format';

const FormatNumber = ((props) => {

    const {
        value,
        setvalueOfNumericFormat,
        className,
        statusDisplay = 'text',
        type = 'text',
        thousandSeparator = true,
        allowNegative = false,
        minValue,
        maxValue,
        decimalScale,
        defaultValue,
        prefix = '',
        suffix = '',
        getInputRef,
        renderText,
        decimalSeparator = '.',
        fixedDecimalScale = false


    } = props;

    const onValueChange = (value) => {
        setvalueOfNumericFormat && setvalueOfNumericFormat(value);
    }
    /**
     * 
     * @param {*} values 
     * @returns Kiểm tra giới hạn của giá trị value khi nhập 
     */
    const handleAllow = (values) => {
        const { floatValue } = values;
        if (!floatValue) {
            return true;
        }
        if (minValue && maxValue)
            return floatValue >= minValue && floatValue <= maxValue;
        if (minValue)
            return floatValue >= minValue
        if (maxValue)
            return floatValue <= maxValue
        return true
    }

    const checkStatusDisplay = (value) => {
        if (statusDisplay == 'text') {
            if ((value === null || value === undefined || value === '')) {
                return ''
            }
            const format = /^-?\d*\.?\d+$/;
            if (format.test(value)) {
                return value
            }

            return NaN


        }
        return value
    }

    return (
        <NumericFormat
            isAllowed={handleAllow}
            decimalScale={decimalScale}
            className={className}
            displayType={statusDisplay}
            thousandSeparator={thousandSeparator}
            allowNegative={allowNegative}
            value={checkStatusDisplay(value)}
            onValueChange={
                // Xử lý giá trị khi thay đổi
                onValueChange
            }
            defaultValue={defaultValue}
            prefix={prefix}
            suffix={suffix}
            getInputRef={getInputRef}
            renderText={renderText}
            type={type}
            decimalSeparator={decimalSeparator}
            fixedDecimalScale={fixedDecimalScale}
        />
    )
})

export default FormatNumber