import './style.scss';

export const Banner = () => {

    return <div className="container-banner row">
        <div className='col-12 col-lg-6 mg-t-30 d-flex justify-content-center flex-column'>
            <p className='banner-header font-bold fs-heading-max-large'>RAISE-SML</p>
            <p className='banner-header font-bold fs-heading-large-2 text-uppercase'>Smell token Tracking Card</p>
            <p className='banner-header font-bold fs-heading-large-2 text-uppercase'>& Collection</p>
            {/* <p className='fs-default font-extra-light'>Smell token Tracking Card & Collection</p> */}
            <p className='fs-label font-regular'>Airdrop AM9:00-PM23:00【JAPAN Time】</p>
        </div>
        <div className='col-12 col-lg-6 d-flex justify-content-center justify-content-lg-end banner-image'>
            <img src='/images/listGroupCard.png' alt="gold card" />
            <div className='elip-bright'></div>
        </div>
    </div>
}