/* eslint-disable max-len */
import { useForm } from 'react-hook-form';
import { useStore } from '../../../core/utils/hook';
import { NOTIFY_FLAG, NOTIFY_LEVEL, MSG } from '../../../core/configs/constants';
import { useEffect } from 'react';
import { observer } from 'mobx-react';
import { ReactNotifications } from '../../../components';
import yup from '../../../core/utils/yupValidate';
import { yupResolver } from '@hookform/resolvers/yup';
import '../style.scss';


const SetNotification = observer((props) => {
    //other
    const { id, notifyFlag = 0, notifyValue = 10, onGetAllCardHolder} = props;
    //store
    const {cardStore: { setNotification }, modalStore: { hide } } = useStore()
    //state
    const validateSchema = yup.object().shape({
        notifyFlag: yup.boolean().required(MSG['error.required']),
        notifyValue: yup.mixed().when('notifyFlag', {
            is: true,
            then: yup.number().required(MSG['error.required']),
            otherwise: yup.mixed().nullable()
        })
    })
    const { register, handleSubmit, reset, getValues, watch,
        formState: { errors, isSubmitting } } = useForm({ resolver: yupResolver(validateSchema), mode: 'onChange' });
    const watchNotify = watch('notifyValue');
    //life cycle
    useEffect(() => {
        reset({notifyFlag, notifyValue: notifyValue ? notifyValue : NOTIFY_LEVEL[NOTIFY_LEVEL.findIndex((notify) => notify.label === '10%')]?.value })
    },[])
    //function
    const onSubmitUpdateNotification = async (data) => {
        const { notifyFlag, notifyValue } = data;
        const payload = {notifyValue, notifyFlag: notifyFlag ? NOTIFY_FLAG.ON : NOTIFY_FLAG.OFF}
        const res = await setNotification({ id, payload });
        if (res) {
            ReactNotifications('success', MSG['inform.success.update']);
            onGetAllCardHolder();
            hide();
        }
    }

    return <div className="notification-popup pd-lr-16 pd-tb-20">
        <form onSubmit={handleSubmit(onSubmitUpdateNotification)}>
            <div className="form-switch pd-0">
                <label className="form-check-label width-170 fs-label font-medium text-color-cs-11" htmlFor="notification">アラート通知</label>
                <input {...register('notifyFlag')}
                    className="form-check-input switch-input shadow-none mg-l-0" type="checkbox" role="switch" id="notification" />
            </div>
            <div className="mg-t-20 d-flex">
                <label className="width-170 fs-label font-medium text-color-cs-11" >上昇アラートの刻み</label>
                <div className='flex-fill d-flex flex-column'>
                    <select {...register('notifyValue')} className="bg-black  pd-5 height-40 text-color-cs-2">
                        {NOTIFY_LEVEL.map((notify) => <option key={notify.label} value={notify.value}>{notify.label}</option>)}
                    </select>
                    {
                        errors?.notifyValue && <div className='text-danger fs-error mg-t-5 pd-0'>{errors.notifyValue?.message}</div>
                    }
                </div>
            </div>
            <div className='mg-t-15'>
                <p className='fs-label font-medium'>Holderにキープしているカードの暗号通貨価値の上昇アラートを{getValues('notifyValue')}%刻みで 設定が可能です。</p>
                <p className='fs-label font-medium'>騰落率がカード発行時より設定値を超えた場合に通知 します。</p>
            </div>
            <div className='mg-t-35 d-flex align-items-center justify-content-center flex-wrap modal-footer-group'>
                <button type='button' className='btn btn-bg-cs-6-bd-cs-1 min-width-130 line-height-1' onClick={() => hide()}>キャンセル</button>
                <button type='submit' className='btn btn-bg-cs-1 min-width-130 line-height-1' disabled={isSubmitting}>保存</button>
            </div>
        </form>
    </div>
})
export default SetNotification;