import { useEffect, useMemo } from 'react';
import { useStore } from '../../core/utils/hook';
import HolderPurchaseList from './HolderPurchaseList';
import { observer } from 'mobx-react';
import { useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import yup from '../../core/utils/yupValidate';
import { FormatNumber } from '../../components';
import { MSG, SYSTEM_PATH, TYPE_GENERAL_SETTING } from '../../core/configs/constants';
import ModalBuyHoldersSuccess from './Modal/ModalBuyHoldersSuccess';

const PurchaseHolderScreen = observer(() => {
    // other
    const { state: { accountId } } = useLocation();
    const navigate = useNavigate();

    // store
    const { 
        purchaseStore: { listHoldersCanBuy, getListHoldersCanBuy, buyHolders, setAttrObservable },
        generalSettingStore: { holderFee, getGeneralSetting },
        modalStore: { show, hide }
    } = useStore();

    // state
    const validateSchema = yup.object().shape({
        purchaseHolder: yup.array().of(yup.object().shape({
            accountId: yup.number(),
            numberOfHolder: yup.number(),
            numberOfHolderCanBuy: yup.number(),
            monthHolders: yup.array().of(yup.number())
        }))
    })

    const formMethods = useForm({ resolver: yupResolver(validateSchema), mode: 'onChange' });
    const { 
        watch, 
        handleSubmit
    } = formMethods;

    const watchPurchaseHolder = watch('purchaseHolder');

    const total = useMemo(() => {
        if(!watchPurchaseHolder) return {
            totalHolder: 0,
            totalPayment: 0
        };
        return {
            totalHolder: watchPurchaseHolder.reduce((total, current) => total + Number(current.numberOfHolder), 0),
            totalPayment: watchPurchaseHolder.reduce((total, current) => total + 
                Number(current.monthHolders.reduce((sum, e) => sum + Number(e), 0)), 0)
        }
    }, [JSON.stringify(watchPurchaseHolder)])

    // life cycle
    useEffect(() => {
        fetchData();
        return () => {
            setAttrObservable('listHoldersCanBuy', []);
        }
    }, [])

    // function
    const fetchData = () => {
        getListHoldersCanBuy();
        onGetHolderFee();
    }

    const onSubmit = async (data) => {
        const payload = data.purchaseHolder.map(item => {
            return {
                accountId: item.accountId,
                monthHolders: item.monthHolders || []
            }
        }).filter((item) => item.monthHolders.length > 0)

        const res = await buyHolders({
            purchaseHolders: payload
        });
        if(res) {
            onShowModalSuccess();
        }
    }

    const onShowModalSuccess = () => {
        show({
            id: 'modal-alert',
            isOpen: true,
            notButtonX: true,
            children: <ModalBuyHoldersSuccess/>
        })
    }

    const handleGoBackHolder = () => {
        navigate(SYSTEM_PATH.HOLDER);
    }

    const onGetHolderFee = () => {
        getGeneralSetting(TYPE_GENERAL_SETTING.OTHER_SETTING)
    }

    return (
        <form onSubmit={handleSubmit(onSubmit)} className='purchase-holder-screen d-flex gap-3'>
            <div className='pd-20 border-radius-10 bg-cs-container w-75'>
                <div className='font-semi-bold fs-heading-large pd-b-15 mg-b-15 purchase-holder-line'>決済</div>
                <div className='border-radius-10 bg-cs-light-yellow opacity-10 pd-10'>
                    <div className='font-semi-bold'>
                        Holder追加料
                        <span className='mg-l-25'>
                            <FormatNumber
                                statusDisplay='text' 
                                value={holderFee} 
                                suffix=' 円/月' />
                        </span>
                    </div>
                    <div className='font-medium'>アカウントを選択して、追加したいHolder数を入力してください。</div>
                </div>
                {
                    listHoldersCanBuy?.accounts?.length > 0 ? (
                        <div>
                            <HolderPurchaseList 
                                accountId={accountId}
                                formMethods={formMethods}
                                data={listHoldersCanBuy.accounts}
                                holderFee={holderFee}
                            />
                        </div>
                    ) : (
                        <div className='text-center mg-t-20'>{MSG['inform.no_data']}</div>
                    )
                }
            </div>
            <div className='purchase-holder-submit pd-20 border-radius-10 bg-cs-container align-self-start w-25'>
                <div className='title-purchase-button d-flex justify-content-between align-items-center 
                pd-b-10 font-regular mg-b-10 mg-t-25 purchase-holder-line'>
                    <span>Holder総枠数 :</span>
                    <span>{total.totalHolder}</span>
                </div>
                <div className='d-flex justify-content-between align-items-center pd-b-10 font-semi-bold'>
                    <span>支払総額 :</span>
                    <FormatNumber
                        className='text-color-cs-medium-yellow fs-description-large' 
                        statusDisplay='text' 
                        value={total.totalPayment*holderFee} 
                        suffix=' 円' />
                </div>
                <button className='btn btn-bg-cs-1 btn-common w-100 mg-t-20' type='submit'>Holder追加</button>
                <button 
                    onClick={handleGoBackHolder}
                    className='btn btn-cs-1 text-color-cs-white w-100 btn-common mg-t-20' 
                    type='button'>キャンセル</button>
            </div>
        </form>
    )
})

export default PurchaseHolderScreen;