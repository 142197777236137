/* eslint-disable max-len */
import { observer } from 'mobx-react';
import { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useStore } from '../../../core/utils/hook';
import SendMailMessage from './SendMailMessage';
import { MEMBERSHIP_TYPE } from '../../../core/configs/constants';
import '../style.scss';
import PurchaseMember from './PurchaseMember';

const HolderAllow = observer((props) => {
    
    //other

    //store
    const { purchaseStore: { registerMembership },modalStore: {show, hide}} = useStore();
    
    //state
    const [navigateToPaidMember, setNavigate] = useState(false);
    const [isShow, setShow] = useState(true);

    //lifecycle
    
    //modal
    const openMailMessageModal = () => {
        show({
            id: 'modal-message',
            isOpen: true,
            notButtonX: true,
            children: (<SendMailMessage cb={() =>  {
                setShow(isShow);
                setNavigate(!navigateToPaidMember);
                hide();
            }} />)
        })
    }
    //function
    const onChangeNavigate = (value) => setNavigate(value);

    const onSubmitRegisterMembership = async (data) => {
        let res = await registerMembership({ type: data.paymentOption })
        if (res) {
            setShow(!isShow);
            openMailMessageModal();
        } 
    }


    return isShow ? <>{ !navigateToPaidMember ? (<div className='modal-default modal-paid-member pd-t-20 pd-lr-16 '>
        <p className='fs-heading-normal text-center font-semi-bold'>有料会員でないため、この機能は使用できません。</p>
        <div className='d-flex justify-content-center mg-t-30 mg-b-15'>
            <button className='btn btn-bg-cs-1 min-width-150' onClick={() => setNavigate(!navigateToPaidMember)}><span className='font-bold'>有料会員はコチラから</span></button>
        </div>
    </div>)
        : (<PurchaseMember navigateToPaidMember={navigateToPaidMember} onChangeNavigate={onChangeNavigate} onSubmitRegisterMembership={onSubmitRegisterMembership} openMailMessageModal={openMailMessageModal} />)}</> : <></>
})

export default HolderAllow;