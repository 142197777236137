import { useNavigate } from 'react-router-dom';
import { AVATAR_DEFAULT_URL, MSG, SYSTEM_PATH } from '../../core/configs/constants';
import { useStore } from '../../core/utils/hook';
import { useForm } from 'react-hook-form';
import { DateTimeCalendarCustom } from '../../components';
import moment from 'moment';
import { yupResolver } from '@hookform/resolvers/yup';
import yup from '../../core/utils/yupValidate';
import { furiganaRegex } from '../../core/utils/common';
import { useEffect } from 'react';
import { observer } from 'mobx-react';
import ReactNotifications from '../../components/Notifications/ReactNotifications';
import { loadFilePreview, loadURLPreview } from '../../core/utils/browser';
import './styles.scss';

const EditAccountScreen = observer(() => {

    //others
    const navigate = useNavigate();

    //store
    const { authStore: { accountInfo, getInfo }, userStore: { updateUserInfo } } = useStore();

    //state
    const validateEditAccountSchema = yup.object().shape({
        fullName: yup
            .string()
            .required(MSG['error.required']),
        fullNameFuri: yup
            .string()
            .matches(furiganaRegex, MSG['error.furigana_format'] )
            .nullable(),
        birthday: yup
            .lazy((value) => {
                if (value === null) {
                    return yup.mixed();
                }
                return yup.string().nullable()
            }),
        phoneNumber: yup
            .string()
            .nullable()
            .checkInputOnlyContainDigitsOrEmpty(MSG['error.phone_number_format']),
        address: yup
            .string()
            .nullable(),
        avatar: yup
            .mixed()
            .test('is-image', MSG['error.avatar_format'], 
                function(value) {
                    if (!value) {
                        return true;
                    }
                    let extFile = '';
                    if (value instanceof File) {
                        value = value.name;
                    }

                    if (value instanceof FileList) {
                        value = value[0].name;
                    }
                    
                    if(value instanceof String || typeof value === 'string') {
                        extFile = value.split('.').pop().toLowerCase();
                    }

                    const allowsExtensions = ['jpg','png','jpeg'];

                    return allowsExtensions.includes(extFile);
                })
            .transform((v) => {
                if (v instanceof FileList) {
                    return v.length < 1 ? undefined : v[0];
                } else {
                    return v;
                }
            }),
    })
    const {
        register, 
        handleSubmit, 
        getValues, 
        setValue,
        reset,
        watch,
        formState: { errors, isSubmitting },
    } = useForm({resolver: yupResolver(validateEditAccountSchema), mode: 'onChange'});

    const watchImage = watch('avatar');

    //life cycle
    useEffect( () => {
        getInfo();
    }, [])

    useEffect( () => {
        if (accountInfo?.userName) {
            const { user: { fullName, fullNameFuri, birthday, email, phoneNumber, address, avatar } } = accountInfo;
            reset( { fullName, fullNameFuri, birthday, email, phoneNumber, address, avatar } );
        }
    }, [accountInfo])

    useEffect(() => {
        if (watchImage instanceof FileList) {
            if (watchImage.length > 0) {
                loadFilePreview(watchImage[0], 'preview-image');
            } else {
                loadURLPreview(AVATAR_DEFAULT_URL, 'preview-image');
            }
        } else if (typeof watchImage === 'string' || watchImage instanceof String) {
            loadURLPreview(watchImage || AVATAR_DEFAULT_URL, 'preview-image');
        } else {
            loadURLPreview(AVATAR_DEFAULT_URL, 'preview-image');
        }
    }, [watchImage])
    
    //function
    const onRemoveImage = () => {
        setValue('avatar', undefined, { shouldValidate: true });
    }

    const onSubmit = async ( { fullName, fullNameFuri, birthday, phoneNumber, address, avatar } ) => {
        const { user: { dropAddress }} = accountInfo;
        const res = await updateUserInfo({ fullName, fullNameFuri, birthday, phoneNumber, address, avatar, dropAddress });
        if (res) {
            navigate('/account');
            ReactNotifications('success', MSG['inform.success.update']);
        }
    };
    
    return (
        <div className='edit-account-screen pd-20 border-radius-10 bg-cs-container min-height-820'>
            <div className='fs-heading-large font-semi-bold'>アカウント情報</div>
            <form onSubmit={handleSubmit(onSubmit)} className='row'>
                <div className='col-3 avatar text-center mg-t-35'>                    
                    <div className='position-relative'>
                        <img id='preview-image' className='img-avatar width-155 height-155' />
                        {
                            ((watchImage instanceof FileList && watchImage.length > 0)
                                || ((typeof watchImage === 'string' || watchImage instanceof String) && watchImage !== '')) &&
                            <i onClick={onRemoveImage} role='button'
                                className='fa fa-light fa-circle-xmark text-danger position-absolute top-0 end-5 font-size-20' />
                        }
                    </div>
                    <div className='mg-t-20'>
                        <input 
                            id='avatar' 
                            type={'file'} 
                            {...register('avatar')} 
                            accept='image/png, image/jpeg' 
                            className='w-100 reset-auto-fill' 
                            hidden />
                        <label htmlFor='avatar'>
                            <u className='text-color-cs-4 font-bold fs-label' title={MSG['error.avatar_tooltip']}>プロフィール写真を編集</u>
                        </label>
                        {
                            errors?.avatar &&
                                <div className='text-danger mg-t-5 fs-error'>{errors.avatar?.message}</div>
                        }
                    </div>
                </div>
                <div className='col-8'>
                    <div className='d-flex flex-column'>
                        <label className='mg-t-20 text-color-cs-8 font-medium'>名前-本名 <span className='text-color-cs-red'>*</span> (24文字以内)</label>
                        <input 
                            {...register('fullName')} 
                            id='fullName' 
                            type={'text'} 
                            className='input-type-2 mg-t-10 reset-auto-fill'
                            maxLength={24}/>
                        {
                            errors?.fullName &&
                                <div className='text-danger mg-t-5 fs-error'>{errors.fullName?.message}</div>
                        }
                    </div>
                    <div className='d-flex flex-column'>
                        <label className='mg-t-20 text-color-cs-8 font-medium'>名前-フリガナ (24文字以内)</label>
                        <input 
                            {...register('fullNameFuri')} 
                            id='fullNameFuri' 
                            type={'text'} 
                            className='input-type-2 mg-t-10 reset-auto-fill'
                            maxLength={24}/>
                        {
                            errors?.fullNameFuri &&
                                <div className='text-danger mg-t-5 fs-error'>{errors.fullNameFuri?.message}</div>
                        }
                    </div>
                    <div className='d-flex flex-column'>
                        <label className='mg-t-20 text-color-cs-8 font-medium'>生年月日</label>
                        <div className='mg-t-10 pd-0'>
                            <DateTimeCalendarCustom
                                classNameInput='input-type-2 height-40'
                                className='calendar'
                                startDate={getValues('birthday') || null}
                                maxDate={new Date()}
                                onChange={date => {
                                    setValue('birthday', date ? moment(date).format('YYYY/MM/DD') : null, { shouldValidate: true });
                                }}
                            />
                        </div>
                    </div>
                    <div className='d-flex flex-column'>
                        <label className='mg-t-20 text-color-cs-8 font-medium'>メールアドレス<span className='text-color-cs-red'>*</span></label>
                        <input 
                            {...register('email')}
                            id='email' 
                            type={'email'} 
                            className='input-type-2 mg-t-10 reset-auto-fill disabled-style text-color-cs-10' 
                            disabled />
                    </div>
                    <div className='d-flex flex-column'>
                        <label className='mg-t-20 text-color-cs-8 font-medium'>電話番号 (11文字以内)</label>
                        <input 
                            {...register('phoneNumber')} 
                            id='phoneNumber' 
                            type={'text'} 
                            className='input-type-2 mg-t-10 reset-auto-fill'
                            maxLength={11}/>
                        {
                            errors?.phoneNumber &&
                                <div className='text-danger mg-t-5 fs-error'>{errors.phoneNumber?.message}</div>
                        }
                    </div>
                    <div className='d-flex flex-column'>
                        <label className='mg-t-20 text-color-cs-8 font-medium'>住所 (54文字以内)</label>
                        <input 
                            {...register('address')} 
                            id='address' 
                            type={'text'} 
                            className='input-type-2 mg-t-10 reset-auto-fill'
                            maxLength={54}/>
                    </div>
                    <div className='row mg-t-70'>
                        <div className='d-flex justify-content-center flex-gap-100'>
                            <button className='btn btn-cs-1 text-color-cs-white width-150 btn-common'
                                onClick={() => navigate(SYSTEM_PATH.ACCOUNT_INFO)}>キャンセル</button>
                            <button className='btn btn-bg-cs-1 width-150 btn-common' disabled={isSubmitting}>変更</button>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    );
});  

export default EditAccountScreen;