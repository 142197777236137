import { useEffect, useState } from 'react';
import { useStore } from '../../core/utils/hook';
import { EditorCustom } from '../../components';

import './styles.scss';

const GeneralSettingScreen = ( { tittle, type } ) => {

    //store
    const { generalSettingStore : {getGeneralSetting}} = useStore();

    // state
    const [data, setData] = useState('');

    //lifecycle
    useEffect(() => {
        fetchData();
    }, [type]);

    // function
    const fetchData = async() => {
        try {
            const res = await getGeneralSetting(type);
            setData(res?.data?.value);
        } catch (error) {
            console.log(error);
        }
    }

    return (
        <div className='general-setting-screen'>
            <h1 className='text-color-cs-1 text-center'>{tittle}</h1>
            <div className='fs-heading-normal mg-t-32 bg-cs-white border-radius-20 pd-lr-20 pd-tb-30 content'>
                <EditorCustom data={data} isReadOnly={true}/>
            </div>
        </div>
    );
};

export default GeneralSettingScreen;