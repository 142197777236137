
import { Outlet } from 'react-router-dom';

import './style.scss';


const ContainerBodyOnlyBackground = () => {

    return(
        <div className='wrapper wrapper-only-background'>
            <div className='wrapper-container'>
                <img className='card-group' src='/images/card_group.png' alt='card-group'/>
                <div className='wrapper-content'>
                    <div className='wrapper-form'>
                        <div className='text-center'>
                            <img src='/images/logo.png' alt='logo'/>
                        </div>
                        <Outlet/>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ContainerBodyOnlyBackground;