import { yupResolver } from '@hookform/resolvers/yup';
import { observer } from 'mobx-react';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { Link, Navigate, useNavigate } from 'react-router-dom';
import { MSG, SCREEN_MODE, SYSTEM_PATH } from '../../core/configs/constants';
import { useStore } from '../../core/utils/hook';
import yup from '../../core/utils/yupValidate';
import classNames from 'classnames';

const LoginScreen = observer((props) => {

    // other
    const navigate = useNavigate();

    // props
    const { mode } = props;

    // store
    const { authStore: { token, login, userList } } = useStore();

    /// state
    const [ showPassword, setShowPassword ] = useState(false);

    const validateLoginSchema = yup.object().shape({
        email: yup
            .string()
            .checkEmailLoginBefore(userList?.map(e => e.user.email), MSG['error.email_login_before'])
            .required(MSG['error.required']),
        password: yup
            .string()
            .required(MSG['error.required'])
    })

    const { register, handleSubmit, formState: { errors, isSubmitting } } = useForm({resolver: yupResolver(validateLoginSchema), mode: 'onChange'});

    // function
    const toggleShowPassword = () => {
        setShowPassword(!showPassword);
    }

    const onLogin = async (data) => {
        const res = await login(data);
        if(res) {
            navigate(SYSTEM_PATH.HOLDER);
            navigate(0);
        }
    }

    if(token && mode === SCREEN_MODE.LOGIN) {
        return <Navigate to={SYSTEM_PATH.HOLDER}/>
    }

    if(mode === SCREEN_MODE.LOGIN) {
        return (
            <div className='login-screen wrapper-content-form'>
                <form onSubmit={handleSubmit(onLogin)} className='mg-t-35'>
                    <div className='title fs-heading-large text-white font-semi-bold'>
                    ログイン
                    </div>
                    <div className='mg-t-15'>
                        <div className='form-floating input-floating'>
                            <input {...register('email')} id='email' type={'text'} placeholder='email' className='form-control'/>
                            <label htmlFor='email'>メールアドレス</label>
                        </div>
                        {
                            errors?.email &&
                                <div className='text-danger mg-t-5 fs-error'>{errors.email?.message}</div>
                        }
                    </div>
                    <div className='mg-t-15'>
                        <div className='form-floating input-floating'>
                            <input {...register('password')} id='password' placeholder='password' type={!showPassword ? 'password' : 'text'} 
                                className='form-control input-with-icon' autoComplete='off'/>
                            <label htmlFor='password'>パスワード</label>
                        </div>
                        <button type='button' className='btn-icon-input' onClick={toggleShowPassword}>
                            <i className={classNames('text-white', showPassword ? 'fas fa-eye' : 'fas fa-eye-slash')}/>
                        </button>
                        {
                            errors?.password &&
                                <div className='text-danger mg-t-5 fs-error'>{errors.password?.message}</div>
                        }
                    </div>
                    <div className='text-end mg-t-20'>
                        <Link to={SYSTEM_PATH.FORGET_PASSWORD} className='hover-underline fs-label'>パスワードを忘れた方はこちら</Link>
                    </div>
                    <button type='submit' className='btn btn-bg-cs-1 w-100 mg-t-20' disabled={isSubmitting}>ログイン</button>
                </form>
                <div className='form-bottom'>
                    <div className='text-center'>
                        <span className='fs-label mg-r-5 text-white'>アカウントをお持ちでないですか?</span> 
                        <Link to={SYSTEM_PATH.REGISTER} className='hover-underline fs-label'>アカウント登録</Link>
                    </div>
                </div>
            </div>
        )
    }
    if(mode === SCREEN_MODE.ADD_ACCOUNT) {
        return (
            <div className='add-account-screen bg-cs-container border-radius-10 pd-t-30 pd-b-100 max-width-1200 mx-auto'>
                <form onSubmit={handleSubmit(onLogin)} className='max-width-700 mx-auto pd-lr-20'>
                    <div className='title fs-heading-large text-white font-semi-bold text-center'>
                    別のアカウント追加
                    </div>
                    <div className='mg-t-30'>
                        <label className='w-100 font-medium fs-heading-normal' htmlFor='email'>メールアドレス</label>
                        <input {...register('email')} id='email' type={'text'} className='w-100 input-type-1 mg-t-5 reset-auto-fill'/>
                        {
                            errors?.email &&
                            <div className='text-danger mg-t-5 fs-error'>{errors.email?.message}</div>
                        }
                    </div>
                    <div className='mg-t-20'>
                        <label className='w-100 font-medium fs-heading-normal' htmlFor='password'>パスワード</label>
                        <input {...register('password')} id='password' type={!showPassword ? 'password' : 'text'} 
                            className='w-100 input-type-1 input-with-icon mg-t-5 reset-auto-fill' autoComplete='off'/>
                        <button type='button' className='btn-icon-input' onClick={toggleShowPassword}>
                            <i className={classNames('text-white', showPassword ? 'fas fa-eye' : 'fas fa-eye-slash')}/>
                        </button>
                        {
                            errors?.password &&
                            <div className='text-danger mg-t-5 fs-error'>{errors.password?.message}</div>
                        }
                    </div>
                    <div className='text-center'>
                        <button type='submit' className='btn btn-bg-cs-1 mg-t-40 border-radius-5 w-100 max-width-400 min-width-250' 
                            disabled={isSubmitting}>アカウント追加</button>
                    </div>
                </form>
            </div>
        )
    }
})

export default LoginScreen;