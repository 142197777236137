import { action, flow, makeObservable, observable } from 'mobx';
import { AuthApi } from '../apis';
import { getSavedInfo, getSavedInfoParse } from '../core/utils/browser';

class AuthStore {

    accountList = getSavedInfoParse('accountList') || [];
    accountInfo = getSavedInfoParse('account');
    token = getSavedInfo('token');

    constructor(rootStore) {
        makeObservable(this, {
            accountList: observable,
            accountInfo: observable,
            token: observable,
            saveAccountInfo: action.bound,
            clearAuthentication: action.bound,
            login: flow.bound,
            logout: flow.bound,
            registerMember: flow.bound,
            forgetPassword: flow.bound,
            verifyUuid: flow.bound,
            setPassword: flow.bound,
            getInfo: flow.bound,
            getAccountList: flow.bound,
            changePassword: flow.bound,
            changeEmail: flow.bound,
            confirmEmail: flow.bound,
            switchAccount: action.bound,
            logoutAll: flow.bound,
            withdraw: flow.bound,
            cancelPremium: flow.bound,
            addAccount: flow.bound,
            verifyAuth: action.bound,
            switchAccountById: action.bound,
            switchNextAccount: action.bound,
            updateUsernameAccount: flow.bound
        })
        this.rootStore = rootStore;
        this.api = new AuthApi();
    }

    saveAccountInfo(data) {
        if (!data) {
            return;
        }

        if (data.accountList) {
            this.accountList = [...this.accountList, ...data.accountList];
            localStorage.setItem('accountList', JSON.stringify(this.accountList));
        }

        if (data.token) {
            this.token = data.token;
            localStorage.setItem('token', data.token);
        }

        if (data.account) {
            this.accountInfo = data.account;
            localStorage.setItem('account', JSON.stringify(data.account));
        }
    }

    switchAccount(account) {
        if (account.token && account.token !== this.token) {
            this.token = account.token;
            localStorage.setItem('token', account.token);
        }

        if (account.account && account.account?.id !== this.accountInfo?.id) {
            this.accountInfo = account.account;
            localStorage.setItem('account', JSON.stringify({
                id: account.account?.id
            }));
        }
    }

    switchAccountById(id) {
        if(this.accountList?.length > 0) {
            const account = this.accountList.filter(acc => acc.account?.id === id)?.[0];
            if(account) {
                this.switchAccount(account);
            }
        }
    }

    switchNextAccount() {
        const accountList = getSavedInfoParse('accountList') || [];
        if(accountList?.length < 2) {
            this.clearAuthentication();
        } else {
            const filterAccount = accountList.filter(acc => acc.account?.id !== this.accountInfo?.id);
            this.accountList = filterAccount;
            localStorage.setItem('accountList', JSON.stringify(filterAccount));
            this.switchAccount(filterAccount[0]);
        }
    }

    *login(payload) {
        try {
            const res = yield this.rootStore.apiStore.call(this.api, this.api.login, payload);
            if (res?.ok) {
                if(res?.data?.length > 0) {
                    this.saveAccountInfo({
                        accountList: res.data,
                        token: res.data[0]?.token,
                        account: res.data[0]?.account
                    });
                }
                return true;
            }
            return false;
        } catch (error) {
            console.log(error);
        }
    }

    *logout(payload) {
        try {
            const res = yield this.rootStore.apiStore.call(this.api, this.api.logout, payload);
            if (res?.ok) {
                let switchAccount = false;
                if (this.accountList?.length <= 1) {
                    this.clearAuthentication();
                } else {
                    this.accountList = this.accountList.filter(account => account?.account?.id !== this.accountInfo?.id);
                    localStorage.setItem('accountList', JSON.stringify(this.accountList?.map(e => ({
                        account: {
                            id: e.account.id
                        },
                        token: e.token
                    }))));
                    this.switchAccount(this.accountList?.[0]);
                    switchAccount = true;
                }
                return { switchAccount };
            }
            return false;
        } catch (error) {
            console.log(error);
        }
    }

    *registerMember(payload) {
        try {
            const res = yield this.rootStore.apiStore.call(this.api, this.api.register, payload);
            if (res) {
                return res;
            }
            return false;
        } catch (error) {
            console.log(error);
        }
    }

    *logoutAll() {
        try {
            const res = yield this.rootStore.apiStore.call(this.api, this.api.logoutAll, { tokens: this.accountList?.map(e => e.token) });
            if (res?.ok) {
                this.clearAuthentication();
                return true;
            }
            return false;
        } catch (error) {
            console.log(error);
        }
    }

    *forgetPassword(payload) {
        try {
            const res = yield this.rootStore.apiStore.call(this.api, this.api.forgetPassword, payload);
            if (res?.ok) {
                return res;
            }
            return false;
        } catch (error) {
            console.log(error);
        }
    }

    *verifyUuid(payload) {
        try {
            const res = yield this.rootStore.apiStore.call(this.api, this.api.verifyUuid, payload);
            if (res?.ok) {
                return true;
            }
            return false;
        } catch (error) {
            console.log(error);
        }
    }

    *setPassword(payload) {
        try {
            const res = yield this.rootStore.apiStore.call(this.api, this.api.setPassword, payload);
            if (res?.ok) {
                return true;
            }
            return false;
        } catch (error) {
            console.log(error);
        }
    }

    *getInfo(disableLoading = false) {
        try {
            const res = yield this.rootStore.apiStore.call(this.api, this.api.getInfo, null, false, null, disableLoading );
            if (res?.ok) {
                this.accountInfo = { ...this.account, ...(res?.data ?? {}) };
                return true;
            }
            return false;
        } catch (error) {
            console.log(error);
        }
    }

    *getAccountList(payload) {
        try {
            const res = yield this.rootStore.apiStore.call(this.api, this.api.getAccountList, payload);
            if (res?.ok) {
                if(res?.data?.length < 1) {
                    this.clearAuthentication();
                } else {
                    this.accountList = res?.data || [];
                    localStorage.setItem('accountList', JSON.stringify(res?.data || []));
                    this.verifyAuth();
                }
                return true;
            }
            return false;
        } catch (error) {
            console.log(error);
        }
    }

    verifyAuth() {
        if(this.accountList?.length > 0) {
            const idxCurrentAccount = this.accountList.findIndex(acc => acc.account?.id === this.accountInfo?.id);
            if(idxCurrentAccount > -1) {
                this.switchAccount(this.accountList[idxCurrentAccount]);
            } else {
                this.switchAccount(this.accountList[0]);
            }
        }
    }

    *changePassword(payload) {
        try {
            const res = yield this.rootStore.apiStore.call(this.api, this.api.changePassword, payload);
            if (res?.ok) {
                return res;
            }
            return false;
        } catch (error) {
            console.log(error);
        }
    }

    *changeEmail(payload) {
        try {
            const res = yield this.rootStore.apiStore.call(this.api, this.api.changeEmail, payload);
            if (res?.ok) {
                return res;
            }
            return false;
        } catch (error) {
            console.log(error);
        }
    }

    *confirmEmail(payload) {
        try {
            const res = yield this.rootStore.apiStore.call(this.api, this.api.confirmEmail, payload, false, null, false, false, true);
            if (res?.data) {
                return res?.data;
            }
            return false;
        } catch (error) {
            console.log(error);
        }
    }

    *withdraw() {
        try {
            const res = yield this.rootStore.apiStore.call(this.api, this.api.withDraw);
            if (res?.ok) {
                return true
            }
            return false;
        } catch (error) {
            console.log(error);
        }
    }

    *cancelPremium() {
        try {
            const res = yield this.rootStore.apiStore.call(this.api, this.api.cancelPremium);
            if (res?.ok) {
                return true
            }
            return false;
        } catch (error) {
            console.log(error);
        }
    }

    *addAccount(payload) {
        try {
            const res = yield this.rootStore.apiStore.call(this.api, this.api.addAccount, payload);
            if (res?.ok) {
                return res?.data;
            }
            return false;
        } catch (error) {
            console.log(error);
        }
    }

    *updateUsernameAccount(payload) {
        try {
            const res = yield this.rootStore.apiStore.call(this.api, this.api.updateUsernameAccount, payload);
            if (res?.ok) {
                return true;
            }
            return false;
        } catch (error) {
            console.log(error);
        }
    }

    clearAuthentication() {
        localStorage.clear();
        sessionStorage.clear();
        this.token = null;
        this.accountInfo = {};
        this.accountList = [];
    }
}

export default AuthStore;