/* eslint-disable max-len */
import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { MSG, PRICE, PURCHASE_STATUS, SORT_DATE_PUBLIC_AT, SYSTEM_PATH, TEXT } from '../../core/configs/constants';
import { useStore } from '../../core/utils/hook';
import { FormatNumber, FullPagination, ReactNotifications } from '../../components';
import { URL_SOCKET_CARD, URL_SOCKET_PURCHASE, socket } from '../../core/utils/socket';
import { calculateCardInfo } from '../../core/utils/common';
import InfiniteScroll from 'react-infinite-scroll-component';
import { io } from 'socket.io-client';
import './style.scss'
import { Banner } from '../../components/Container/Banner';
import { BeatLoader } from 'react-spinners';
import LazyLoad from 'react-lazy-load';

const KEY_SHUFFLE_CARD = 2;

const ListCard = observer(() => {

    // other
    const navigate = useNavigate();

    // store
    const { 
        modalStore: { show, hide, openAlert }, 
        cardStore: { getAllCard, listCard, setAttrObservable, paging, addCardToHold },
        authStore: { accountInfo }
    } = useStore();

    // state
    const [cacheData, setCacheData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [cacheDataLength, setCacheDataLength] = useState(0); 

    const { register, getValues, watch } = useForm({
        mode: 'onChange', defaultValues: {
            price: '0',
            sort: '2'
        }
    });
    const watchPrice = watch('price');
    const watchSort = watch('sort');

    // lifecycle
    useEffect(() => {
        const getData = async () => {
            setCacheDataLength(0);
            setIsLoading(false);
            onFetchCardList({ page: 1, size: watchSort == 2 ? 60 : 12 });
        }
 
        getData();
        return () => {
            setAttrObservable('listCard', []);
        }
    }, [watchPrice, watchSort])

    useEffect(() => {
        setCacheDataLength(cacheData.length);
    }, [cacheData.length])

    useEffect(() => {
        const socket = io(URL_SOCKET_CARD);

        socket.on('change-card-info', (arg) => {
            const { currentRate = 0, currencyRate = 0 } = arg;
            setCacheData(prevData => {
                const processData = prevData.map((card) => ({
                    ...card,
                    currentRate,
                    variationRate: calculateCardInfo(currentRate, currencyRate, card)?.variationRate
                }))
                setAttrObservable('listCard', processData);

                return processData;
            })
        });

        socket.on('update-card-info', (arg) => {
            const { id, airdrop, baseRate, cardPrice, cardTypeId, image, publicAt, status } = arg;
            setCacheData(prevData => {
                const findIndex = prevData.findIndex(item => item?.id == id);
                if (findIndex !== -1) {
                    prevData[findIndex].airdrop = airdrop;
                    prevData[findIndex].baseRate = baseRate;
                    prevData[findIndex].cardPrice = cardPrice;
                    prevData[findIndex].cardTypeId = cardTypeId;
                    prevData[findIndex].image = image;
                    prevData[findIndex].publicAt = publicAt;
                    prevData[findIndex].status = status;
                }
                
                setAttrObservable('listCard', prevData);
                return prevData;
            })
        })

        socket.on('hold-card-success', (arg) => {
            const { cardId } = arg;
            if(cardId) {
                setCacheData(prevData => {
                    const findIndex = prevData.findIndex(item => item?.id == cardId);
                    if (findIndex !== -1) {
                        prevData[findIndex].isHolded = true
                    }
                    setAttrObservable('listCard', prevData);
                    return prevData;
                })
            }
        })

        return () => {
            socket.off('change-card-info');
            socket.off('update-card-info');
            socket.off('hold-card-success');
            socket.disconnect();
        };
    }, []);

    // function
    const onFetchCardList = async (payload) => {
        const { price, sort } = getValues();
        await getAllCard({
            minPrice: PRICE[price]?.minPrice || null,
            maxPrice: PRICE[price]?.maxPrice || null,
            sortKey: SORT_DATE_PUBLIC_AT[sort]?.sortKey || 'public_at',
            sortDir: SORT_DATE_PUBLIC_AT[sort]?.sortDir || 'desc',
            isShuffle: SORT_DATE_PUBLIC_AT[sort]?.value || false,
            ...payload
        }, (data) => {
            setCacheData(data?.elements || []);
            setIsLoading(true); 
        });
    }

    const onShow = (id) => {
        show({
            id: 'modal',
            isOpen: true,
            header: '確認',
            onCancel: () => hide(),
            children: (
                <div className='mg-b-20 pd-lr-20'>
                    <span>Holderに追加しますか？</span>
                    <div className='mg-t-20 d-flex align-items-center justify-content-center flex-gap-20 flex-wrap'>
                        <button className='btn btn-bg-cs-6 width-130 btn-bg-cs-6-bd-cs-1' onClick={() => hide()}>キャンセル</button>
                        <button className='btn btn-bg-cs-1 width-130 ' onClick={() => holderCard(id)}>追加 </button>
                    </div>
                </div>
            )
        })
    }

    const holderCard = async (id) => {
        hide();
        const res = await addCardToHold({ cardId: id });
        if (res) {
            ReactNotifications('success', MSG['inform.success.create']);
            navigate(SYSTEM_PATH.HOLDER)
        }

    }

    const onPageChange = (page) => {
        onFetchCardList({ page, size: 12 });
    }

    const fetchMoreData = () => {
        const { price, sort } = getValues();
        const cardIds = cacheData.map(item => item.id).join(',');
        if (SORT_DATE_PUBLIC_AT[sort]?.value && isLoading) {
            getAllCard({ 
                minPrice: PRICE[price]?.minPrice || null,
                maxPrice: PRICE[price]?.maxPrice || null,
                sortKey: SORT_DATE_PUBLIC_AT[sort]?.sortKey || 'public_at',
                sortDir: SORT_DATE_PUBLIC_AT[sort]?.sortDir || 'desc',
                size: 60, 
                isShuffle: SORT_DATE_PUBLIC_AT[sort]?.value || false, 
                cardIds }, 
            (data) => {
                if (data.elements.length > 0) {
                    setCacheData(prevData => [...prevData, ...data.elements]);
                } else {
                    setIsLoading(false); 
                }
            }, true);
        }
    }

    // chilren components
    const ChildrenCard = ({item}) => (
        <div className='list-card-item row'>
            <div className='col-5 card-detail-image d-flex  align-items-center justify-content-center'>
                <img className='w-100 max-width-190' src={item?.image}></img>
            </div>
            <div className='card-detail-label col-7'>
                <div className='py-2'>
                    {item?.variationRate < 0 ? <div className='d-flex text-danger'>
                        <div className='card-detail-label-item-icon width-70'>
                            {/* <i className="fa-solid fa-arrow-down"></i> */}
                            <img src='/images/icon_down_rate.svg'></img>
                        </div>
                        <div className='d-block card-detail-label-item-text'>
                            <div className='mg-b-1 w-100 text-danger' >騰落率</div>
                            <span className='font-semi-bold fs-label text-danger' > <FormatNumber allowNegative className='text-danger' statusDisplay='text' value={item?.variationRate} decimalScale={2} suffix=' %' /></span>
                            {/* <span className='font-semi-bold fs-label text-color-cs-4 '> {item?.variationRate ? parseFloat(item?.variationRate.toFixed(2)) : ''}%</span> */}
                        </div>
                    </div> : item?.variationRate > 0 ?
                        <div className='d-flex text-color-cs-5'>
                            <div className='card-detail-label-item-icon width-70'>
                                <img src='/images/icon_rate_up.svg'></img>
                            </div>
                            <div className='d-block card-detail-label-item-text '>
                                <div className='mg-b-1 w-100 text-color-cs-14' >騰落率</div>
                                <span className='font-semi-bold fs-label text-color-cs-4' > <FormatNumber prefix='+' className='font-semi-bold fs-label text-color-cs-14' statusDisplay='text' value={item?.variationRate} decimalScale={2} suffix=' %' /></span>

                                {/* <span className='font-semi-bold fs-label text-color-cs-4 '>+ {item?.variationRate ? parseFloat(item?.variationRate.toFixed(2)) : ''}%</span> */}
                            </div>
                        </div> : <div className='d-flex '>
                            <div className='card-detail-label-item-icon width-70'>
                                <img src='/images/icon_equal_rate.svg'></img>
                            </div>
                            <div className='d-block card-detail-label-item-text '>
                                <div className='mg-b-1 w-100' >騰落率</div>
                                <span className='font-semi-bold fs-label '> {item?.variationRate}%</span>
                            </div>
                        </div>}
                    <div className='d-flex align-items-center mb-2 card-item '>
                        <div className='width-70'>
                            <div className='card-detail-label-item-icon p-1 d-flex 
                            align-items-center justify-content-center rounded  width-35 height-35 card-item-icon'>
                                <img src='/images/icon_calendar.svg'></img>
                            </div>
                        </div>
                        <div className=''>
                            <span className='d-block card-detail-label-item text-color-cs-13' >発行日時</span>
                            <span className='d-block font-semi-bold' >{item?.publicAt}</span>
                        </div>
                    </div>

                    <div className='d-flex align-items-center mb-2 card-item '>
                        <div className='width-70'>
                            <div className='card-detail-label-item-icon p-1
                            d-flex align-items-center justify-content-center  rounded  width-35 height-35 card-item-icon '>
                                <img src='/images/icon_purchase_price.svg'></img>
                            </div>
                        </div>

                        <div className=''>
                            <span className='d-block card-detail-label-item text-color-cs-13' >カード価格</span>
                            <span className='d-block font-semi-bold' >{<div> <FormatNumber statusDisplay='text' value={item?.cardPrice} suffix=' 円' /> </div>}</span>
                        </div>
                    </div>
                    <div className='d-flex align-items-center mb-2 card-item  '>
                        <div className='width-70'>

                            <div className='card-detail-label-item-icon p-1
                            d-flex align-items-center justify-content-center rounded  width-35 height-35 card-item-icon'>
                                <img src='/images/icon_rate_buy.svg'></img>
                            </div>
                        </div>
                        <div className=''>
                            <span className='d-block card-detail-label-item text-color-cs-13' >基準レート</span>
                            <span className='d-block font-semi-bold' >{item?.baseRate} USDT</span>
                        </div>
                        {/* <div>
                            <span className='card-detail-label-item-text d-block' >{item.rateCurrent}</span>
                            <span className='card-detail-label-item-text d-block' >{item.rateCurrent}</span>
                        </div> */}
                    </div>
                    <div className='d-flex align-items-center mb-2 card-item  '>
                        <div className='width-70'>
                            <div className='card-detail-label-item-icon p-1
                            d-flex align-items-center justify-content-center  rounded  width-35 height-35 card-item-icon'>
                                <img src='/images/icon_curent_rate.svg'></img>
                            </div>
                        </div>

                        <div className=''>
                            <span className='d-block card-detail-label-item text-color-cs-13' >現在レート</span>
                            <span className='d-block ' > <FormatNumber statusDisplay='text' value={item?.currentRate} decimalScale={6} suffix=' USDT' /></span>
                            {/* <span className='d-block font-semi-bold' >{parseFloat((+item?.currentRate).toFixed(6))}</span> */}
                        </div>
                    </div>
                    <div className='d-flex align-items-center mb-2 card-item  '>
                        <div className='width-70'>
                            <div className='card-detail-label-item-icon p-1
                            d-flex align-items-center justify-content-center rounded  width-35 height-35 card-item-icon'>
                                <img src='/images/icon_currency.svg'></img>
                            </div>
                        </div>

                        <div className=''>
                            <span className='d-block card-detail-label-item text-color-cs-13' >Airdrop</span>
                            <span className='d-block font-semi-bold' >{<div> <FormatNumber statusDisplay='text' value={item?.airdrop} suffix=' SML' />  </div>}</span>
                        </div>
                    </div>
                    {
                        item?.isHolded ? 
                            <div className='text-center font-weight-bold text-danger'>
                                既に所有者がいる
                            </div>
                            :
                            <button onClick={() => onShow(item?.id)}
                                className='d-flex align-items-center justify-content-center height-30 rounded btn-bg-cs-1 col-12 
                                font-weight-bold'>
                                <span>Holderに追加する</span>
                            </button>
                    }
                </div>


            </div>
        </div>
    )

    return (
        <div className='list-card-screen'>
            {/* <div className='d-flex  justify-content-between col-12 flex-wrap  w-full'>
                <div className='col-md-6 col-12'>
                    
                    <div className='screen-card-title font-weight-bold fs-heading-max-large'>
                        <span className='d-block font-semi-bold'>RAISE-SML</span>
                        <span className='d-block font-semi-bold'>Placeholder</span>
                        <span className='d-block font-semi-bold'>Text</span>
                    </div>
                    <div className='mg-t-10 screen-card-label'>
                        <p className="fs-default font-extra-light">Smell token Tracking Card & Collection</p>
                        <p className="fs-default font-extra-light">AirDrop AM9:00-PM23:00【JAPAN Time】</p>
                    </div>
                </div>
                <div className=' col-md-6 col-12 d-flex justify-content-end screen-card-image '>
                    <img className='card-list-image ' src='/images/listGroupCard.png'></img>
                </div>

            </div> */}
            <Banner />
            <div className='w-full mg-t-40'>

                <form className='d-flex align-items-center justify-content-between flex-gap-20 flex-wrap list-card-list-select'>
                    <div className='d-flex  align-items-center flex-gap-5 flex-wrap list-card-list-select-item'>
                        <div className='width-120 list-card-list-select-item-title'>カードリスト:</div>
                        <select {...register('price')} className='width-170 select-custom height-45'>
                            {
                                Object.keys(PRICE).map((key) => <option key={key} value={key}>{PRICE[key].label}</option>)
                            }
                        </select>
                    </div>
                    <div className='d-flex  align-items-center  flex-gap-5 flex-wrap list-card-list-select-item'>
                        <div className='width-120 list-card-list-select-item-title'>並べ替え:</div>
                        <select {...register('sort')} className='width-170 select-custom height-45'>
                            <option value={KEY_SHUFFLE_CARD}>ランダム</option>
                            {
                                Object.keys(SORT_DATE_PUBLIC_AT).filter(key => key != KEY_SHUFFLE_CARD).map((key) => <option key={key} value={key}>{SORT_DATE_PUBLIC_AT[key].label}</option>)
                            }
                        </select>
                    </div>
                </form>

                <InfiniteScroll
                    dataLength={cacheDataLength}
                    next={fetchMoreData}
                    hasMore={isLoading}
                    loader={SORT_DATE_PUBLIC_AT[getValues('sort')]?.value && <BeatLoader color='#D1BA7A' className='text-center'/>}
                    endMessage={
                        <p className='text-center'>
                            <b>{TEXT.SEEN_IT_ALL}</b>
                        </p>
                    }
                >
                    <div className='row mg-t-16 list-item'>
                        {cacheData?.length > 0 ? cacheData.map((item) => {
                            if(SORT_DATE_PUBLIC_AT[watchSort]?.value && cacheData?.length > 12) {
                                return (
                                    // eslint-disable-next-line max-len
                                    <LazyLoad key={item.id} className='d-flex align-items-center justify-content-center col-xl-4 list-item-wrapper col-md-6 col-sm-12 flex-wrap mg-b-24'>
                                        <ChildrenCard item={item}/>
                                    </LazyLoad>
                                )
                            }
                            return (
                                // eslint-disable-next-line max-len
                                <div key={item.id} className='d-flex align-items-center justify-content-center col-xl-4 list-item-wrapper col-md-6 col-sm-12 flex-wrap mg-b-24'>
                                    <ChildrenCard item={item}/>
                                </div>
                            )
                        }) :
                            <div className='d-flex align-items-center justify-content-center '>
                                <span className='fs-heading-normal font-bold text-color-cs-1'>{MSG['inform.no_data']}</span>
                            </div>
                        }
                    </div>
                </InfiniteScroll>
                {
                    !SORT_DATE_PUBLIC_AT[getValues('sort')]?.value && (
                        <div className='d-flex align-items-center justify-content-center '>
                            <FullPagination siblingCount={1}
                                totalRecords={paging.totalRecord}
                                currentPage={paging.page}
                                pageSize={paging.size}
                                onPageChange={onPageChange}
                                previousLabel="«"
                                nextLabel="»" />
                        </div>
                    )
                }

            </div>
        </div>
    )
})

export default ListCard;