import { flow, makeObservable, observable } from 'mobx';
import { CardApi } from '../apis';
import BaseStore from './BaseStore';

class CardStore extends BaseStore {

    listCard = [];
    listHolders = [];
    listCardInCart = [];

    constructor(rootStore) {
        super();
        makeObservable(this, {
            listCard: observable,
            listHolders: observable,
            listCardInCart: observable,
            getAllCard: flow.bound,
            getListCardHold: flow.bound,
            getListAvailableCard: flow.bound,
            addCardToHold: flow.bound,
            removeCardFromHolder: flow.bound,
            buyCard: flow.bound,
            setNotification: flow.bound,
            updateStatusLockCard: flow.bound,
            sortCard: flow.bound
        })
        this.rootStore = rootStore;
        this.api = new CardApi();
    }

    *getAllCard(searchParams, cb, disableLoading = false) {
        try {
            const { size, page, sortDir, sortKey } = this.paging;
            const payload = { size, page, sortDir, sortKey, ...searchParams };
            const res = yield this.rootStore.apiStore.call(this.api, this.api.getListCard, payload, false, null, disableLoading);
            if (res?.ok) {
                this.listCard = res?.data?.elements || [];
                this.setAttrObservable('paging', res?.data?.paginate, true, false);
                cb && cb(res?.data);
            }
        } catch (error) {
            console.log(error);
        }
    }

    *addCardToHold(payload) {
        const res = yield this.rootStore.apiStore.call(this.api, this.api.addCardtoHold, payload);
        if (res?.ok) {
            return true;
        }
        return false;
    }

    *getListCardHold(cb, disableLoading = false) {
        try {
            const res = yield this.rootStore.apiStore.call(this.api, this.api.getListCardHold, null, false, null, disableLoading);
            if (res?.ok) {
                if (cb) {
                    cb(res)
                } else {
                    this.listHolders = res?.data || [];
                }
            }
        } catch (error) {
            console.log(error);
        }
    }

    *getListAvailableCard(cb) {
        try {
            const res = yield this.rootStore.apiStore.call(this.api, this.api.getListAvailableCard);
            if (res?.ok) {
                if (cb) {
                    cb(res)
                } else {
                    this.listCardInCart = res?.data?.elements || [];
                }
            }
        } catch (error) {
            console.log(error);
        }
    }

    *removeCardFromHolder(id) {
        try {
            const res = yield this.rootStore.apiStore.call(this.api, this.api.removeCardfromHolder, id);
            if (res?.ok) {
                return true;
            }
            return false;
        } catch (error) {
            console.log(error)
        }
    }

    *buyCard(payload) {
        try {
            const res = yield this.rootStore.apiStore.call(this.api, this.api.buyCard, payload);
            if (res?.ok) {
                return true;
            }
            return false;
        } catch (error) {
            console.log(error)
        }
    }

    *setNotification({ id, payload }) {
        try {
            const res = yield this.rootStore.apiStore.call(this.api, this.api.setNotification, { id, payload });
            if (res?.ok) {
                return true;
            }
            return false;
        } catch (error) {
            console.log(error)
        }
    }

    *updateStatusLockCard(id, payload) {
        try {
            const res = yield this.rootStore.apiStore.call(this.api, this.api.updateStatusLockCard, { id, payload });
            if (res?.ok) {
                return true;
            }
            return false;
        } catch (error) {
            console.log(error)
        }
    }

    *sortCard(id, payload) {
        try {
            const res = yield this.rootStore.apiStore.call(this.api, this.api.sortCard, { id, payload });
            if (res?.ok) {
                return true;
            }
            return false;
        } catch (error) {
            console.log(error)
        }
    }

    clean() {
        super.clean();
        this.listCard = [];
        this.listHolders = [];
        this.listCardInCart = [];
    }
}

export default CardStore;
