import ApiService from './ApiService';

class UserApi extends ApiService {
    constructor() {
        super('profile');
    }

    getUserProfile({ id, payload }) {
        return this.get(id, payload);
    }

    updateUserProfile(payload) {
        return this.put('', payload, { 'Content-Type': 'multipart/form-data' });
    }
}

export default UserApi;
