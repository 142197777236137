import { observer } from 'mobx-react';
import classNames from 'classnames';
import { useStore } from '../../core/utils/hook';
import { useState } from 'react';
import { MSG } from '../../core/configs/constants';
import yup from '../../core/utils/yupValidate';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import ReactNotifications from '../../components/Notifications/ReactNotifications';

import './styles.scss';
import { useNavigate } from 'react-router-dom';

const ChangeEmailScreen = observer(() => {

    // other
    const navigate = useNavigate();

    // store
    const { authStore: { changeEmail } } = useStore();

    // state
    const [currentPassword, setCurrentPassword] = useState(false);

    const validateChangeEmailSchema = yup.object().shape({
        currentEmail: yup
            .string()
            .required(MSG['error.required'])
            .email(MSG['error.email_format']),

        newEmail: yup
            .string()
            .required(MSG['error.required'])
            .email(MSG['error.email_format'])
            .test('not-same-email', MSG['error.validate_new_email'], function(value) {
                return value !== this.resolve(yup.ref('currentEmail'));
            }),

        currentPassword: yup
            .string()
            .required(MSG['error.required']),
    })
    
    const { 
        register, 
        handleSubmit, 
        formState: { errors, isSubmitting },
    } = useForm({resolver: yupResolver(validateChangeEmailSchema), mode: 'onChange'});

    // function
    const toggleShowCurrentPassword = () => {
        setCurrentPassword(!currentPassword);
    }

    const onSubmit = async ( { currentEmail, newEmail, currentPassword } ) => {
        const res = await changeEmail({ currentEmail, newEmail, password: currentPassword });
        if (res) {
            navigate('/account');
            ReactNotifications('success', MSG['inform.success.update']);
        }
    }

    return (
        <div className='change-info-screen pd-20 border-radius-10 bg-cs-container min-height-820'>
            <div className='fs-heading-large font-semi-bold'>メールアドレス変更</div>
            <form onSubmit={handleSubmit(onSubmit)} className='mg-t-46'>
                <div className='row align-items-start'>
                    <label 
                        className='col-3 fs-default font-medium text-color-cs-8 pd-none'>
                            現在のメールアドレス <span className='text-color-cs-red'>*</span>
                    </label>
                    <div className='col-8'>
                        <input 
                            className='input-type-2 text-color-cs-white font-medium fs-label reset-auto-fill w-100'
                            {...register('currentEmail')}
                            type='email'
                            id='currentEmail' 
                            tabIndex={1}
                            autoComplete='off'/>
                        {
                            errors?.currentEmail &&
                                <div className='text-danger mg-t-5 fs-error'>{errors.currentEmail?.message}</div>
                        }
                    </div>
                </div>
                <div className='row mg-t-25 align-items-start'>
                    <label 
                        className='col-3 fs-default font-medium text-color-cs-8 pd-none'>
                            変更したいメールアドレス <span className='text-color-cs-red'>*</span>
                    </label>
                    <div className='col-8'>
                        <input 
                            className='input-type-2 text-color-cs-white font-medium fs-label reset-auto-fill w-100'
                            {...register('newEmail')}
                            type='email'
                            id='newEmail' 
                            tabIndex={2}
                            autoComplete='off'/>
                        {
                            errors?.newEmail &&
                                <div className='text-danger mg-t-5 fs-error'>{errors.newEmail?.message}</div>
                        }
                    </div>
                </div>
                <div className='row mg-t-25 align-items-start'>
                    <label 
                        className='col-3 fs-default font-medium text-color-cs-8 pd-none'>
                            現在のパスワード <span className='text-color-cs-red'>*</span>
                    </label>
                    <div className='col-8 text-color-cs-white font-medium fs-label'>
                        <input 
                            className='input-type-2 w-100 reset-auto-fill' 
                            type={!currentPassword ? 'password' : 'text'} 
                            {...register('currentPassword')} 
                            id='currentPassword' 
                            tabIndex={3}
                            autoComplete='off'/>
                        <button type='button' className='btn-icon-input' onClick={toggleShowCurrentPassword}>
                            <i className={classNames('text-white', currentPassword ? 'fas fa-eye' : 'fas fa-eye-slash')}></i>
                        </button>
                        {
                            errors?.currentPassword &&
                                <div className='text-danger mg-t-5 fs-error'>{errors.currentPassword?.message}</div>
                        }
                    </div>
                </div>
                <div className='row mg-t-70'>
                    <div className='col-3'></div>
                    <div className='col-8 d-flex justify-content-center flex-gap-100'>
                        <button 
                            type='button'
                            className='btn btn-cs-1 text-color-cs-white width-150 btn-common' 
                            onClick={() => {navigate('/account')}}>キャンセル</button>
                        <button type='submit' className='btn btn-bg-cs-1 width-150 btn-common' disabled={isSubmitting}>変更</button>
                    </div>
                </div>
            </form>
        </div>
    );
});

export default ChangeEmailScreen;