import { observer } from 'mobx-react';
import { useStore } from '../../../core/utils/hook';
import { SYSTEM_PATH } from '../../../core/configs/constants';

const ModalSwitchAccount = observer((props) => {

    // props
    const { id } = props;

    // store
    const { modalStore: { hideAll }, authStore: { switchAccountById } } = useStore();

    // function
    const onSwitchAccount = () => {
        switchAccountById(id);
        window.location.href = SYSTEM_PATH.HOLDER;
    }

    return (
        <div className='pd-20 confirm-switch-account'>
            <div className='text-center'>
                <i className='fa-solid fa-circle-check fs-cs-65'></i>
                <div className='mg-t-10 fs-cs-18 font-semi-bold'>アカウントが追加されました。</div>
            </div>
            <div className='mg-t-25 footer-button d-flex align-items-center flex-gap-20 flex-wrap'>
                <button type='button' className='d-block m-auto btn btn-bg-cs-1 min-width-200' onClick={onSwitchAccount}>アカウントを切り替える</button>
                <button className=' d-block mx-auto btn btn-bg-cs-6 min-width-200' onClick={hideAll}>閉じる</button>
            </div>
        </div>
    )
})

export default ModalSwitchAccount;