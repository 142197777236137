import { observer } from 'mobx-react';
import { useState, useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import NotFoundScreen from '../404/NotFoundScreen';
import { SYSTEM_PATH } from '../../core/configs/constants';
import { useStore } from '../../core/utils/hook';

const ConfirmEmailScreen = observer((props) => {

    // other
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const uuid = searchParams.get('uuid');
    const accept = searchParams.get('accept');
    
    // store
    const { authStore: {  confirmEmail } } = useStore();

    // state
    const [confirmEmailStatus, setConfirmEmailStatus] = useState(false);
    const [loading, setLoading] = useState(true);

    // lifecycle
    useEffect(() => {
        onConfirmEmail();
    }, [])

    // function
    const onConfirmEmail = async () => {
        const res = await confirmEmail({ uuid, accept });
        if (res) {
            setConfirmEmailStatus(res);
        }
        setLoading(false);
    };
    
    if (loading) return null;

    if (!uuid) return <NotFoundScreen/>;

    return (
        <div className='confirm-email-screen'>
            <div className='mg-t-80 d-flex justify-content-center align-items-center flex-column text-center'>
                {
                    confirmEmailStatus.success ?
                        <i className='fa-solid fa-circle-check text-color-cs-white fs-cs-80'></i>
                        :
                        <i className='fa-solid fa-circle-xmark text-color-cs-red fs-cs-80'></i>
                }
                <div className='mg-t-50 fw-bolder fs-cs-20 text-color-cs-white'>
                    { confirmEmailStatus?.text }
                </div>
                <button className='mg-t-50 btn w-100 btn-bg-cs-1' onClick={() => navigate(SYSTEM_PATH.LOGIN)}>ログインに戻り</button>
            </div>
        </div>
    );
});

export default ConfirmEmailScreen;
