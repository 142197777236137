import { observer } from 'mobx-react';
import { useForm } from 'react-hook-form';
import { Link, useNavigate } from 'react-router-dom';
import { MSG, SYSTEM_PATH } from '../../core/configs/constants';
import { useStore } from '../../core/utils/hook';
import yup from '../../core/utils/yupValidate';
import { yupResolver } from '@hookform/resolvers/yup';
import { useState } from 'react';

const ForgetPasswordScreen = observer(props => {

    // other
    const navigate = useNavigate();

    // store
    const { authStore: { forgetPassword } } = useStore();

    // state
    const validateSchema = yup.object().shape({
        email: yup
            .string()
            .required(MSG['error.emailForgetPassword.required'])
            .email(MSG['error.email_format'])
    })
    const { register, handleSubmit, formState: { errors, isSubmitting } } = useForm({ resolver: yupResolver(validateSchema), mode: 'onChange' });
    const [ showForgetPasswordMailSuccess, setShowForgetPasswordMailSuccess] = useState(false);

    // function
    const onForgetPassword = async (data) => {
        const res = await forgetPassword(data);
        if(res?.ok) {
            setShowForgetPasswordMailSuccess(!showForgetPasswordMailSuccess);
        }
    }

    const redirectToLogin = () => {
        navigate(SYSTEM_PATH.LOGIN);
    }

    return(
        <div className='forget-password-screen wrapper-content-form'>
            { showForgetPasswordMailSuccess ? (
                <div className='text-center text-color-cs-white'>
                    <i className='fa-solid fa-circle-check fs-cs-90 mg-t-100'></i>
                    <div className='mg-t-18 fs-cs-24 font-semi-bold'>メールを送信しました!</div>
                    <div className='mg-t-18 fs-cs-13 font-400'>
                        <div>パスワード再設定用のメールを送信しました。</div>
                        <div>メール記載のリンクをクリックし、パスワード再設定に進んでください。</div>
                    </div>
                    <button 
                        className='btn btn-bg-cs-1 w-100 mg-t-100'
                        onClick={redirectToLogin}
                    >ログイン</button>
                </div>
            ) : (
                <>
                    <form onSubmit={handleSubmit(onForgetPassword)} className='mg-t-35'>
                        <div className='title fs-heading-large text-white font-semi-bold'>
                            パスワードをお忘れの場合
                        </div>
                        <div className='mg-t-15'>
                            <div className='form-floating input-floating'>
                                <input {...register('email')} id='email' type={'email'} placeholder='email' className='form-control'/>
                                <label htmlFor='email'>
                                    メールアドレスを入力してください
                                </label>
                            </div>
                            {
                                errors?.email && errors?.email?.message.split('\n').map((line, index) => (
                                    <div className='text-danger fs-error mg-t-5' key={index}>{line}</div>
                                ))
                            }
                        </div>
                        <button type='submit' className='btn btn-bg-cs-1 w-100 mg-t-50' disabled={isSubmitting}>送信</button>
                    </form>
                    <div className='form-bottom'>
                        <div className='text-center'>
                            <Link to={SYSTEM_PATH.LOGIN} className='hover-underline fs-label'>ログインへ戻る</Link>
                        </div>
                    </div>
                </>
            )}
        </div>
    )
})

export default ForgetPasswordScreen;