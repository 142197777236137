import { useForm } from 'react-hook-form';
import { MSG } from '../../core/configs/constants';
import { useStore } from '../../core/utils/hook';
import yup from '../../core/utils/yupValidate';
import { yupResolver } from '@hookform/resolvers/yup';
import { useNavigate } from 'react-router-dom';
import ReactNotifications from '../../components/Notifications/ReactNotifications';
import { useEffect } from 'react';

const EditUsernameModal = () => {

    // other
    const navigate = useNavigate();

    // store
    const { modalStore: { hide }, authStore: { accountInfo, updateUsernameAccount }} = useStore();

    // state
    const validateEditUsernameModalSchema = yup.object().shape({
        userName: yup
            .string()
            .required(MSG['error.required'])
    })
    const {
        register, 
        handleSubmit, 
        reset,
        formState: { errors, isSubmitting }
    } = useForm({resolver: yupResolver(validateEditUsernameModalSchema), mode: 'onChange'});

    // life cycle
    useEffect(() => {
        const { userName } = accountInfo;
        reset({ userName });
    }, [accountInfo])

    // function
    const onSubmit = async ({ userName }) => {
        const res = await updateUsernameAccount({ userName })
        if(res) {
            accountInfo.userName = userName;
            ReactNotifications('success', MSG['inform.success.update']);
            hide();
        }
    }

    return (
        <div className='edit-username-modal pd-b-15 pd-lr-15'>
            <form onSubmit={handleSubmit(onSubmit)} className='pd-t-10'>
                <label className='text-color-cs-8 font-medium w-100'>
                    ユーザーネーム
                    <span className='text-color-cs-red'>*</span> 
                    (12文字以内)
                </label>
                <div className='mg-t-5'>
                    <input
                        {...register('userName')} 
                        id='userName' 
                        type={'text'}  
                        className='input-type-2 text-color-cs-white font-medium w-100 reset-auto-fill' 
                        maxLength={12} />
                    {
                        errors?.userName &&
                            <div className='text-danger mg-t-5 fs-error'>{errors.userName?.message}</div>
                    }
                </div>
                <div className='footer-button mg-t-10 flex-gap-20 d-flex 
                    align-items-center justify-content-around flex-wrap pd-t-25'>
                    <button className='btn btn-bg-cs-6-bd-cs-1 min-width-150' onClick={() => hide()}>キャンセル</button>
                    <button type='submit' className='btn btn-bg-cs-1 min-width-150' disabled={isSubmitting}>更新</button>
                </div>
            </form>
        </div>
    )
};

export default EditUsernameModal;