export const SYSTEM_PATH = {
    LOGIN: '/login',
    REGISTER: '/register',
    FORGET_PASSWORD: '/forget-password',
    SET_PASSWORD: '/set-password',
    // change password
    CHANGE_EMAIL: '/change-email',
    CHANGE_PASSWORD: '/change-password',
    // confirm email
    CONFIRM_EMAIL: '/confirm-email',
    // home
    HOME: '/',
    //holder
    HOLDER: '/holder',
    //send inquiry
    SEND_INQUIRY: '/contact-us',
    // add account
    ADD_ACCOUNT: '/add-account',
    // card
    LIST_CARD: '/list-card',
    PURCHASE_CARD: '/purchase-card',
    // purcharse history
    PURCHASE_HISTORY: '/purchase-history',
    //account
    ACCOUNT_INFO: '/account',
    EDIT_ACCOUNT: '/edit-account',
    //card buy
    LIST_CARD_BUY: '/list-card-buy',
    //subcription history
    SUBCRIPTION_HISTORY: '/subcription-history',
    // card buy history
    CARD_BUY_HISOTRY: '/card-buy-history',
    // card buy detail
    CARD_BUY_DETAIL: '/card-buy-detail/:id',
    // buy holder
    PURCHASE_HOLDER: '/purchase-holder',
    // extend holder
    EXTEND_HOLDER: '/extend-holder',
    // purchase holder detail
    PURCHASE_HOLDER_DETAIL: '/purchase-holder-detail/:id'
};

export const MSG = {
    //#region Api message
    'api.response.worng_format': '応答本文は正しい形式ではありません。',
    'api.response.no_message': 'エラーメッセージの応答はありません。',
    'api.response.server_error':
        'サーバ接続で問題が発生しました。\nお手数ですが、もう一度接続してみて下さい。',
    'api.response.no_network':
        'サーバ接続で問題が発生しました。\nお手数ですが、もう一度接続してみて下さい。',
    'api.response.authorization': '再度ログインして下さい。',
    //#endregion Api message

    //#region inform
    'inform.no_data': 'データが見つかりません。',
    'inform.success.create': '新規登録が完了しました。',
    'inform.success.update': '更新が完了しました。',
    'inform.success.update_password': 'パスワードが変更されました。次回のログイン時に新しいパスワードを使用してください。',
    'inform.success.delete': '削除が完了しました。',
    'inform.success.forget_password': 'メールを送信しました。ご確認ください。',
    'inform.success.buy_card': 'お支払い情報を含むメールがこの後に送信されます。この取引を完了するためにはお支払いを行ってください。',
    //#endregion

    //#region validate error message
    'error.required': '必須項目をご入力ください。',
    'error.dropAddress.required': '必須項目です。Drop先アドレスを追加しない場合、この行を削除してください。',
    'error.emailForgetPassword.required': '登録されているメールアドレスを入力してください。\nパスワード再設定のメールを送信します。',
    'error.validate_new_email': '現在のメールアドレスと違うメールアドレスを入力してください。',
    'error.email_login_before': 'このアカウントは既にログインしています。別のアカウントでお試しください。',
    'error.email_format': 'メールアドレスの形式は正しくありません。再度確認してください。',
    'error.password_format_warning': 'パスワードの形式は正しくありません。再度確認してください。',
    'error.password_format':
        'パスワードの形式は正しくありません。再度確認してください。\nパスワードの条件は以下となります。\n・８桁以上であること \n・少なくとも１桁の大文字を含むこと \n・少なくとも１桁の小文字を含むこと \n・少なくとも１桁の数字を含むこと \n・少なくとも１桁の記号を含むこと',
    'error.furigana_format': 'フリガナでご入力ください。',
    'error.confirm_password_not_match': '新しいパスワード確認が新しいパスワードと一致しないのでもう一度お試しください。',
    'error.length_12': '最大12文字までしか入力できません。',
    'error.length_24': '最大24文字までしか入力できません。',
    'error.length_150': '最大150文字までしか入力できません。',
    'error.length_1000': '最大1000文字までしか入力できません。',
    'error.phone_number_length': '電話番号は数字11桁以内で入力してください。',
    'error.address_length': '最大54文字までしか入力できません。',
    'error.phone_number_format': '電話番号は数字だけで入力してください。',
    'error.avatar_tooltip': 'サポートされている画像ファイル形式は JPG、JPEG、PNG のみです',
    'error.avatar_format': 'サポートしている画像ファイルの形式はJPGとPNGのみになります。もう一度お試しください。',
    //#endregion
    'disable_card': 'このクレジットカードを無効化しますが、よろしいですか。'
};

export const DEFAULT_PAGE_SIZE = 10;
export const PAGE_SIZE_OPTIONS = [10, 50, 100, 500, 1000];
// There are alot of table in system. Example: Document, Form difinition, User, Tenant.
export const TABLE_CODE = {
    DOCUMENT: 0
}

export const SCREEN_MODE = {
    ADD: 0,
    EDIT: 1,
    DETAIL: 2,
    LOGIN: 3,
    ADD_ACCOUNT: 4,
    HOLDER_SCREEN: 5,
    PURCHASE_CARD_SCREEN: 6
}

export const WIDTH_COLUMN_SELECT_TABLE = '50px';

export const FORMAT_DATE = 'YYYY/MM/DD';
export const FORMAT_DATE_TIME = 'YYYY/MM/DD HH:mm:ss';

export const AVATAR_DEFAULT_URL = '/images/userAvatar.png';
export const IMAGE_DEFAULT_URL = '/images/image_default.png';

export const GENDER = {
    MALE: {
        key: 0,
        label: '男性'
    },
    FEMALE: {
        key: 1,
        label: '女性'
    }
}

export const USER_STATUS = {
    INACTIVE: {
        key: 0,
        label: '無効',
        color: '#D9001B'
    },
    ACTIVE: {
        key: 1,
        label: '有効',
        color: '#03B615'
    },
    WAITING_CONFIRM: {
        key: 2,
        label: 'アカウント確認待ち',
        color: '#6300BF'
    }
}

export const ACCOUNT_STATUS = {
    SUBSCRIBED: {
        key: 1,
        label: 'サブスク契約中',
        color: '#03B615'
    },
    SUBSCRIPTION_SUSPENDED: {
        key: 2,
        label: 'サブスク停止中',
        color: '#F59A23'
    },
    FORCED_TERMIMATION: {
        key: 3,
        label: '強制解約',
        color: '#D9001B'
    },
    WITHDRAW: {
        key: 4,
        label: '退会',
        color: '#D9001B'
    }
}

export const MEMBERSHIP_TYPE = {
    MONTH: 1,
    YEAR: 2
}


export const MODAL = {
    TITLE_WARNING_MODAL: '確認'
}

export const TEXT = {
    CANCEL: 'キャンセル',
    CREATE: '保存',
    UPDATE: '保存',
    DELETE: '削除',
    SEARCH: '検索',
    SEEN_IT_ALL: 'すべてのカードが読み込まれました。'
}

export const HEADER_NAV = {
    HOLDER: {
        label: 'Holder',
        path: '/holder'
    },
    CARD: {
        label: 'カード選択',
        path: SYSTEM_PATH.LIST_CARD
    },
    BUY_CARD: {
        label: 'カード購入',
        path: SYSTEM_PATH.PURCHASE_CARD
    },
    ABOUT_US: {
        label: '会社概要',
        path: '/about-us'
    },
    CONTACT_US: {
        label: 'お問い合わせ',
        path: '/contact-us'
    }
}

export const FOOTER_NAV = {
    TERMS: {
        label: '利⽤規約',
        path: '/terms'
    },
    ACT_OF_SPECIFIED_COMMERCIAL_TRANSACTION: {
        label: '特定商取引法に基づく表記',
        path: '/act'
    },
    PRIVACY_POLICY: {
        label: 'プライバシーポリシー',
        path: '/privacy-policy'
    }
}

export const TYPE_GENERAL_SETTING = {
    PRIVACY_POLICY: 'privacy_policy',
    ACT_OF_SPECIFIED_COMMERCIAL_TRANSACTION: 'act_of_specified_commercial_transaction',
    TERMS: 'terms',
    ABOUT_US: 'about_us',
    OTHER_SETTING: 'other_setting'
};

export const TOOLTIP_FORMAT_PASSWORD = [
    'パスワードの条件は以下となります。',
    '・８桁以上であること',
    '・少なくとも１桁の大文字を含むこと',
    '・少なくとも１桁の小文字を含むこと',
    '・少なくとも１桁の数字を含むこと',
    '・少なくとも１桁の記号を含むこと'
];

export const PRICE = {
    0: {
        label: '全て ',
        minPrice: null,
        maxPrice: null
    },
    1: {
        label: '0  ~  999 円 ',
        minPrice: 0,
        maxPrice: 999
    },
    2: {
        label: '1,000  ~  2,999円',
        minPrice: 1000,
        maxPrice: 2999
    },
    3: {
        label: '3,000  ~  4,999円',
        minPrice: 3000,
        maxPrice: 4999
    },
    4: {
        label: '5,000  ~  9,999円',
        minPrice: 5000,
        maxPrice: 9999
    },
    5: {
        label: '10,000円以上',
        minPrice: 10000,
        maxPrice: null
    }
}

export const SORT_DATE_PUBLIC_AT = {
    0: {
        label: '新しい順',
        sortKey: 'public_at',
        sortDir: 'desc'
    },
    1: {
        label: '古い順',
        sortKey: 'public_at',
        sortDir: 'asc'
    },
    2: {
        label: 'ランダム',
        value: true
    }
}

export const HOLDER_STATUS = {
    LOCK: 0,
    UNLOCK: 1
}

export const HOLDER_TYPE = {
    FREE: 0,
    FEE: 1
}

export const TRANSACTION_STATUS = {
    UNPAID: 0,
    PAID: 1,
    FAILED: 2,
    CANCELED: 3
}

export const MEMBERSHIP_TYPE_TITTLE = {
    MONTHLY: '月額会費',
    ANNUAL: '年間会費'
}

export const NOTIFY_FLAG = {
    ON: 1,
    OFF: 0
}
export const NOTIFY_LEVEL = [
    { label: '10%', value: 10 },
    { label: '20%', value: 20 },
    { label: '30%', value: 30 },
    { label: '40%', value: 40 },
    { label: '50%', value: 50 }
]

// export const AIRdrop_STATUS = {
//     WAITING_PAYMENT: '送金待ち',
//     COMPLETED_PAYMENT: '送金完了',
//     FAILED: '失敗'
// };

export const AIRdrop_STATUS = {

    WAITING_PAYMENT: {
        value: 3,
        label: '送金待ち',
        color: '#D9001B'
    },
    COMPLETED_PAYMENT: {
        value: 4,
        label: '送金完了',
        color: '#03B615'
    },
    WAITING_PAYMENT_2: {
        value: null,
        label: '',
        color: '#03B615'
    }

};

// export const PURCHASE_STATUS = {
//     WAIT_FOR_PAYMENT:
//     {
//         label: '支払い待ち',
//         value: 3,
//         color: '#ffff'
//     },
//     SUCCESS:
//     {
//         label: '成功',
//         value: 4,
//         color: '#03B615'
//     },

// };


export const PURCHASE_STATUS = {
    WAIT_FOR_PAYMENT: {
        value: 0,
        label: '支払い待ち',
        color: 'orange'
    },
    SUCCESS: {
        value: 1,
        label: '成功',
        color: '#03B615'
    },
    FAILED: {
        value: 2,
        label: '失敗',
        color: '#D9001B'
    },
    CANCELED: {
        value: 3,
        label: 'キャンセル済',
        color: '#d5d5d5'
    }
};

export const PAYMENT_TYPE = {
    CARD: 3,
    HOLDER: 2,
    MEMBERSHIP: 1
}

export const HOLDER_PURCHASE_TYPE = {
    ADD_HOLDER: 0,
    EXTEND_HOLDER: 1
}

export const TEXT_MONTHLY = [
    '1か月',
    '2か月',
    '3か月',
    '4か月',
    '5か月',
    '6か月',
    '7か月',
    '8か月',
    '9か月',
    '10か月',
    '11か月',
    '12か月'
]