import { useLocation, useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react';
import { useStore } from '../../core/utils/hook';
import { SYSTEM_PATH } from '../../core/configs/constants';
import { useState } from 'react';
import classNames from 'classnames';
import './style.scss';

const dataSideBar = [
    {
        id: 1,
        icon: 'images/icon_user.svg',
        name: 'アカウント情報',
        url: SYSTEM_PATH.ACCOUNT_INFO
    },
    {
        id: 2,
        icon: 'images/icon_card_buy.svg',
        name: '購入済みカード一覧',
        url: SYSTEM_PATH.LIST_CARD_BUY
    },
    {
        id: 3,
        icon: 'images/icon_purchase_history.svg',
        name: '決済情報',
        chilren: true,
        subMenu: [
            {
                id: 14,
                icon: 'images/icon_purchase_history.svg',
                name: 'カード購入取引履歴',
                url: SYSTEM_PATH.CARD_BUY_HISOTRY
            },
            {
                id: 12,
                icon: 'images/icon_card_buy.svg',
                name: 'Holder追加取引履歴',
                url: SYSTEM_PATH.PURCHASE_HISTORY
            },
            {
                id: 13,
                icon: 'images/icon_purchase_history.svg',
                name: '月額支払い',
                url: SYSTEM_PATH.SUBCRIPTION_HISTORY
            }
        ]

    },

    {
        id: 4,
        icon: 'images/icon_log_out.svg',
        name: '退会',
    }
]


const SideBar = observer((props) => {

    // other
    const location = useLocation();
    const navigate = useNavigate();

    // store
    const { modalStore: { show, hide, openAlert }, authStore: { withdraw } } = useStore();

    // state
    const [isActive, setActive] = useState();

    // function
    const onShow = () => {
        show({
            id: 'modal',
            isOpen: true,
            header: '確認する',
            onCancel: () => hide(),
            children: (
                <div className='pd-tb-10 pd-lr-20'>
                    <div className='pd-b-10'>本当に退会してもよろしいですか?</div>
                    <div>終了すると、システムに戻ることはできず、関連する情報はすべて復元できなくなります。</div>
                    <div className='mg-t-20 mg-b-10 d-flex align-items-center justify-content-center flex-gap-20 flex-wrap'>
                        <button className='btn btn-bg-cs-6 width-130 btn-bg-cs-6-bd-cs-1' onClick={() => hide()}>キャンセル</button>
                        <button className='btn btn-bg-cs-1 width-130' onClick={handleWithDraw}>離れる</button>
                    </div>
                </div>
            )
        })
    }

    const handleWithDraw = async () => {
        const res = await withdraw()
        if (res) {
            navigate(SYSTEM_PATH.LOGIN)
        }
    }

    // function
    const hanldeActiveLink = (item, e) => {
        setActive(item?.id);
        if (item?.name === '退会') {
            onShow()
        }
        if (isActive === item?.id) {
            e.stopPropagation()
            setActive(null)
            return
        }
        item?.url ? navigate(item?.url) : e.stopPropagation();

    }



    // store
    const { authStore: { userInfo } } = useStore();

    return (
        <div className='side-bar border-radius-10 bg-cs-container'>
            <div className='list-item-side-bar w-100 h-100 border-radius-10 bg-cs-container '>
                <div className='fs-heading-large pd-t-20 pd-l-20 font-semi-bold'>マイページ</div>
                <div className='mg-t-40 list-item-side-bar-items '>
                    {dataSideBar.length > 0 && dataSideBar.map((item, index) => (
                        <div className='side-bar-item' key={item?.id}>
                            <div onClick={(e) => hanldeActiveLink(item, e)} role='button'
                                // eslint-disable-next-line max-len
                                className={classNames('list-item-side-bar-item', index < dataSideBar.length - 1 && 'border-bottom-opacity', (location.pathname.includes(item?.url)) && 'active')}>
                                <div key={item.id} className='item-side-bar pd-tb-20 '>
                                    <div to={item.url} className='d-flex  align-items-center justify-content-between pd-lr-20 '>
                                        <div className='d-flex align-items-center flex-gap-40'>
                                            <img src={item?.icon}></img>
                                            <div className='active-text'>{item?.name}</div>
                                        </div>
                                        {item?.chilren ? item?.id === isActive ? <i className="fa-solid fa-chevron-up"></i>
                                            : <i className="fa-solid fa-chevron-down"></i> : <></>}
                                    </div>
                                </div>
                            </div>
                            {item?.subMenu?.length > 0 && item?.id === isActive &&
                                <div className='fade-in-custom '>
                                    {item?.subMenu?.map((subItem, subIndex) => (
                                        // eslint-disable-next-line max-len
                                        <div className={classNames('cursor-pointer list-item-side-bar-item', subIndex < item.subMenu.length - 1 && 'border-bottom-opacity', (location.pathname.includes(subItem?.url) || subItem?.id === isActive) && 'active')}>
                                            <div onClick={() => navigate(subItem.url)} key={subItem.id} className='item-side-bar pd-tb-20 pd-l-20'>
                                                <div to={subItem.url} className='d-flex align-items-center justify-content-between pd-lr-20'>
                                                    <div className='d-flex align-items-center flex-gap-40'>
                                                        <div className='active-text'>{subItem?.name}</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            }
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
})

export default SideBar;