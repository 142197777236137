import { useEffect } from 'react';
import { useStore } from '../../core/utils/hook';
import './styles.scss';
import { Link, useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react';
import SettingDropAddressModal from './SettingDropAddressModal';
import { useForm } from 'react-hook-form';
import { loadFilePreview, loadURLPreview } from '../../core/utils/browser';
import { AVATAR_DEFAULT_URL, MEMBERSHIP_TYPE, MEMBERSHIP_TYPE_TITTLE, MSG, SYSTEM_PATH } from '../../core/configs/constants';
import EditUsernameModal from './EditUsernameModal';

const AccountInfoScreen = observer((props) => {
    //other
    const navigate = useNavigate();
    //store
    const { authStore: { accountInfo, getInfo }, modalStore: {hide, show}   } = useStore();
    //state
    const { register, watch, reset } = useForm();
    const watchImage = watch('avatar');
    //life cycle
    useEffect(() => {
        const getAccInfo = async () => {
            await getInfo();
        }
        getAccInfo();
        
    }, [])
    useEffect( () => {
        if (accountInfo?.userName) {
            const { userName, user: { avatar }} = accountInfo;
            reset( { userName, avatar } );
        }
    }, [accountInfo])
    useEffect(() => {
        if (watchImage instanceof FileList) {
            if (watchImage.length > 0) {
                loadFilePreview(watchImage[0], 'preview-image');
            } else {
                loadURLPreview(AVATAR_DEFAULT_URL, 'preview-image');
            }
        } else if (typeof watchImage === 'string' || watchImage instanceof String) {
            loadURLPreview(watchImage || AVATAR_DEFAULT_URL, 'preview-image');
        } else {
            loadURLPreview(AVATAR_DEFAULT_URL, 'preview-image');
        }
    }, [watchImage])
    //modal
    const openModalSettingDropAddress = () => {
        show({
            id: 'modal-alert',
            header: '更新',
            isOpen: true,
            onCancel: () => {
                hide();
            },
            children: (
                <SettingDropAddressModal/>
            ),
            type: 'small'
        })
    }

    const openModalEditUsername = () => {
        show({
            id: 'modal-alert',
            header: 'ユーザーネーム更新',
            isOpen: true,
            onCancel: () => {
                hide();
            },
            children: (
                <EditUsernameModal/>
            ),
            type: 'small'
        });
    }
    //function    

    return <div className="account-info-screen pd-20 border-radius-10 bg-cs-container min-height-820">
        <div className='font-semi-bold fs-heading-large'>アカウント情報</div>
        <div className="account-info-content row">
            <div className="col-12 col-lg-3 avatar text-center mg-t-35">
                <div className='position-relative'>
                    <img id='preview-image' className='img-avatar width-155 height-155' />
                </div>
                <div className='mg-t-20'>
                    <input 
                        id='avatar' 
                        type={'file'} 
                        {...register('avatar')} 
                        accept='image/png, image/jpeg' 
                        className='w-100 reset-auto-fill' 
                        hidden />
                </div>
            </div>
            <div className="account-info-list-items col-12 col-lg-9">
                <div className='font-semi-bold fs-heading-normal'>アカウント情報</div>
                <div className="account-item">
                    <p className="font-semi-bold">ユーザーネーム</p>
                    <p className="fs-label">{accountInfo?.userName}</p>
                </div>
                <div className="account-item">
                    <p className="font-semi-bold">会員料金プラン</p>
                    <p className="fs-label">{
                        accountInfo?.membershipType === 1 ? MEMBERSHIP_TYPE_TITTLE.MONTHLY : (
                            accountInfo?.membershipType === 2 ? MEMBERSHIP_TYPE_TITTLE.ANNUAL : ''
                        )
                    }</p>
                </div>
                <div className="mg-t-30 text-sm-end account-item-last pd-tb-15">
                    <button className='btn btn-bg-cs-1 btn-edit-account min-width-200 height-40'
                        onClick={openModalEditUsername}>
                        <img src='/images/icon_edit.svg' />
                        <span className='font-semi-bold mg-l-5'>ユーザーネーム更新</span>
                    </button>
                </div>
                <div className='font-semi-bold fs-heading-normal mg-t-20'>プロフィール</div>
                <div className="account-item">
                    <p className="font-semi-bold">ユーザーID</p>
                    <p className="fs-label">{accountInfo?.user?.code}</p>
                </div>
                <div className='account-item'>
                    <p className="font-semi-bold">名前-本名</p>
                    <p className="fs-label">{accountInfo?.user?.fullName}</p>
                    
                </div>
                <div className="account-item">
                    <p className="font-semi-bold">名前-フリガナ</p>
                    <p className="fs-label">{accountInfo?.user?.fullNameFuri}</p>
                    
                </div>
                <div className="account-item">
                    <p className="font-semi-bold">生年月日</p>
                    <p className="fs-label">{accountInfo?.user?.birthday}</p>
                    
                </div>
                <div className="account-item">
                    <p className="font-semi-bold">メールアドレス</p>
                    <p className="flex-fill fs-label">{accountInfo?.user?.email}</p>
                    <div className="flex-fill d-flex justify-content-md-end justify-content-start align-items-center">
                        <img src='/images/icon_edit-outline.svg' />
                        <Link className='fs-label mg-l-5' to={SYSTEM_PATH.CHANGE_EMAIL}>メールアドレス変更</Link>
                    </div>
                </div>
                <div className="account-item">
                    <p className="font-semi-bold">電話番号</p>
                    <p className="fs-label">{accountInfo?.user?.phoneNumber}</p>
                    
                </div>
                <div className="account-item">
                    <p className="font-semi-bold">住所</p>
                    <p className="fs-label">{accountInfo?.user?.address}</p>
                    
                </div>
                <div className="account-item">
                    <p className="font-semi-bold">パスワード</p>
                    <p className="">
                        <img src='/images/icon_edit-outline.svg' />
                        <Link className='fs-label mg-l-5' to={SYSTEM_PATH.CHANGE_PASSWORD}>パスワード再設定</Link>
                    </p>
                    
                </div>
                <div className="account-item">
                    <p className="font-semi-bold">Drop先アドレス</p>
                    <p className="flex-fill fs-label">
                        {accountInfo?.user?.dropAddress && accountInfo?.user?.dropAddress.length && accountInfo?.user?.dropAddress[0] ?
                            accountInfo?.user?.dropAddress[0] + ' (デフォルト)' : ''}</p>
                    <div className="flex-fill d-flex align-items-center justify-content-md-end justify-content-start">
                        <img src='/images/icon_edit-outline.svg' />
                        <div onClick={() => openModalSettingDropAddress()} className='fs-label mg-l-5 edit' to={SYSTEM_PATH.CHANGE_EMAIL}>
                            <span className='text-color-cs-4'>更新</span></div>
                    </div>
                </div>
                <div className="mg-t-40 text-sm-end ">
                    <button className='btn btn-bg-cs-1 btn-edit-account min-width-200 height-40'
                        onClick={() => navigate(SYSTEM_PATH.EDIT_ACCOUNT)}>
                        <img src='/images/icon_edit.svg' />
                        <span className='font-semi-bold mg-l-5'>プロフィール編集</span>
                    </button>
                </div>
            </div>
        </div>
    </div>
})

export default AccountInfoScreen;