/* eslint-disable max-len */
import { observer } from 'mobx-react';
import { Banner } from '../../components/Container/Banner';
import { useEffect, useState } from 'react';
import { useStore } from '../../core/utils/hook';
import { ACCOUNT_STATUS, SCREEN_MODE } from '../../core/configs/constants';
import Card from './Card';
import { useForm } from 'react-hook-form';
import { BuyCardsModal } from './Modal';
import { URL_SOCKET_CARD } from '../../core/utils/socket';
import { calculateCardInfo, roundDecimalNumber } from '../../core/utils/common';
import { io } from 'socket.io-client';

import classNames from 'classnames';
import './style.scss';

const PurchaseCardsScreen = observer((props) => {
    //other
    //store
    const { authStore: { accountInfo, getInfo, accountList },
        cardStore: { listCardInCart, getListAvailableCard, setAttrObservable, clean },
        modalStore: { hide, show } } = useStore();
    //state
    const [cacheData, setCacheData] = useState([]);
    const [allSelectedCard, setSelectedCard] = useState(0);
    const { register, handleSubmit, getValues, reset, setValue, watch } = useForm({
        defaultValues: {
            cardIds: []
        }
    });
    const watchCardIds = watch('cardIds');
    //life cycle
    useEffect(() => {
        const socketCard = io(URL_SOCKET_CARD);

        socketCard.on('change-card-info', (arg) => {
            const { currentRate = 0, currencyRate = 0 } = arg;
            setCacheData(prevData => {
                const processData = prevData.map((card) => {
                    return {
                        ...card,
                        currentRate,
                        variationRate: calculateCardInfo(currentRate, currencyRate, card)?.variationRate,
                        currentPrice: calculateCardInfo(currentRate, currencyRate, card)?.currentPrice
                    }

                });
                setAttrObservable('listCardInCart', processData);

                return processData;
            })
        });

        socketCard.on('update-card-info', (arg) => {
            const { id, airdrop, baseRate, cardPrice, cardTypeId, image, publicAt, status, account } = arg;
            setCacheData(prevData => {
                const findIndex = prevData.findIndex(item => item?.id == id);
                const isAccountOfUser = accountList.some(item => item?.account?.id == account?.id);
                if (findIndex !== -1) {
                    prevData[findIndex].airdrop = airdrop;
                    prevData[findIndex].baseRate = baseRate;
                    prevData[findIndex].cardPrice = cardPrice;
                    prevData[findIndex].cardTypeId = cardTypeId;
                    prevData[findIndex].image = image;
                    prevData[findIndex].publicAt = publicAt;
                    prevData[findIndex].status = status;
                }
                if (isAccountOfUser) {
                    prevData[findIndex].account.userName = account.userName;
                }
                setAttrObservable('listCardInCart', prevData);

                return prevData;
            })
        })

        return () => {
            socketCard.off('change-card-info');
            socketCard.off('update-card-info');
            socketCard.disconnect();
        };
    }, []);
    
    useEffect(() => {
        if (accountInfo?.status === ACCOUNT_STATUS.SUBSCRIBED.key) {
            onGetAllCardInCart();
        }
        //reset({ cardIds: [] });

        return () => {
            setAttrObservable('listCardInCart', [], false, true);
        }
    }, [accountInfo])

    useEffect(() => {
        let check = watchCardIds.length === listCardInCart?.length;
        if (check) {
            setSelectedCard(listCardInCart.length);
        } else {
            setSelectedCard(0);
        }
    }, [watchCardIds])
    //modal
    const openConfirmPurchaseCard = (data) => {
        const { cardIds } = data;
        const total = listCardInCart.reduce((prev, cur) => {
            if (cur.id && cardIds.includes(cur.id.toString())) {
                prev.totalAirdrop += cur.airdrop;
                prev.totalPrice += cur.cardPrice;
            }
            return prev;
        }, { totalAirdrop: 0, totalPrice: 0 })
        total.totalAirdrop = roundDecimalNumber(total.totalAirdrop, 2);
        total.totalPrice = roundDecimalNumber(total.totalPrice, 2);
        show({
            id: 'modal-purchase-cards',
            header: '購入情報確認',
            isOpen: true,
            onCancel: () => hide(),
            children: (<BuyCardsModal cardIds={cardIds} total={total} onGetAllCardInCart={onGetAllCardInCart} />)
        })
    }
    //function

    const onGetAllCardInCart = (payload) => {
        clean();
        reset({ cardIds: [] });

        getListAvailableCard((res) => {
            setAttrObservable('listCardInCart', res?.data?.elements || [], false, true);
            setCacheData(res?.data?.elements || []);
            setSelectedCard(0);

        }, payload);
    }
    const onChangeSelectAll = () => {
        if (allSelectedCard == 0 && listCardInCart && listCardInCart.length) {
            setSelectedCard(listCardInCart.length);
            let allCardIds = listCardInCart.map((card) => String(card?.id));
            setValue('cardIds', allCardIds);
        } else {
            setSelectedCard(0)
            setValue('cardIds', []);
        }

    }

    return <div className='holder-screen'>
        <Banner />
        {accountInfo?.status === ACCOUNT_STATUS.SUBSCRIBED.key ? listCardInCart && listCardInCart?.length ?
            <>
                <div className='mg-t-25 d-flex justify-content-between'>
                    <p className='fs-label font-regular'>購入したいカードを選択してください。</p>
                    <div role='button' onClick={() => onChangeSelectAll()} style={{ color: '#4082D0' }} className='fs-heading-small font-regular'>{allSelectedCard == 0 ? '全選択' : '全解除'}</div>
                </div>
                <form onSubmit={handleSubmit(openConfirmPurchaseCard)}>
                    <div className='holder-list row mg-t-5'>
                        {listCardInCart.map((card, index) => {
                            return <div key={`holder-${card?.id}`} className='col-12 col-lg-6 holder-item min-height-340 mg-b-30'>
                                <div className={classNames('holder-item-content d-flex justify-content-center align-items-center', watchCardIds.includes(card.id.toString()) && 'hightlight')}>
                                    <div className='card-select'>
                                        <label className="custom-checkbox">
                                            <input type="checkbox" className='styled-checkbox' value={card.id} {...register('cardIds')} />
                                            <span className="checkmark">
                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="#D1BA7A">
                                                    <path d="M0 0h24v24H0z" fill="none" />
                                                    <path d="M9.9 18.3c-0.3 0-0.5-0.1-0.7-0.3l-4.2-4.2c-0.4-0.4-0.4-1 0-1.4s1-0.4 1.4 0l3.5 3.5 7.1-7.1c0.4-0.4 1-0.4 1.4 0s0.4 1 0 1.4l-7.8 7.8c-0.2 0.2-0.4 0.3-0.7 0.3z" />
                                                </svg>
                                            </span>
                                        </label>
                                    </div>
                                    <Card cardInfo={card} mode={SCREEN_MODE.PURCHASE_CARD_SCREEN} />
                                </div>
                            </div>
                        })}
                    </div>
                    <div className='mg-t-30 holder-btn-group d-flex justify-content-center align-items-center'>
                        <button className='holder-btn holder-btn-payment min-width-250 fs-heading-small' style={{ visibility: (watchCardIds && watchCardIds.length) ? 'visible' : 'hidden' }}>カード購入</button>
                    </div>
                </form></> : <div className='mg-t-25 text-center'>
                <p className='fs-label text-color-cs-red'>保有カードがありません。先にカードをHolderに追加してください。</p>
            </div> : <div className='mg-t-25 text-center'>
            <p className='fs-label text-color-cs-red'>カード購入するためには有料会員に参加してください。</p>
        </div>}

    </div>
})

export default PurchaseCardsScreen;