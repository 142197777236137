import { observer } from 'mobx-react';
import classNames from 'classnames';
import { useStore } from '../../core/utils/hook';
import { useState } from 'react';
import { MSG, TOOLTIP_FORMAT_PASSWORD } from '../../core/configs/constants';
import yup from '../../core/utils/yupValidate';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import ReactNotifications from '../../components/Notifications/ReactNotifications';
import { useNavigate } from 'react-router';
import { passwordRegex } from '../../core/utils/common';

import './styles.scss'

const ChangePasswordScreen = observer(props => {

    // other
    const navigate = useNavigate();

    // store
    const { authStore: { changePassword } } = useStore();

    // state
    const [currentPassword, setCurrentPassword] = useState(false);
    const [newPassword, setNewPassword] = useState(false);
    const [newPasswordConfirm, setNewPasswordConfirm] = useState(false);

    const validateChangePasswordSchema = yup.object().shape({
        currentPassword: yup
            .string()
            .required(MSG['error.required']),

        newPassword: yup
            .string()
            .required(MSG['error.required'])
            .matches(passwordRegex, MSG['error.password_format_warning']),

        newPasswordConfirm: yup
            .string()
            .required(MSG['error.required'])
            .oneOf([yup.ref('newPassword'), null], MSG['error.confirm_password_not_match']),
    })

    const {
        register,
        handleSubmit,
        formState: { errors, isSubmitting },
    } = useForm({ resolver: yupResolver(validateChangePasswordSchema), mode: 'onChange' });

    // function
    const toggleShowCurrentPassword = () => {
        setCurrentPassword(!currentPassword);
    };

    const toggleShowNewPassword = () => {
        setNewPassword(!newPassword);
    };

    const toggleShowNewPasswordConfirm = () => {
        setNewPasswordConfirm(!newPasswordConfirm);
    };

    const onClickCancelButton = () => {
        navigate('/account');
    };

    const onSubmit = async ({ currentPassword, newPassword }) => {
        const res = await changePassword({ currentPassword, newPassword });
        if (res) {
            navigate('/account');
            ReactNotifications('success', MSG['inform.success.update_password']);
        }
    };

    return (
        <div className='change-info-screen pd-20 border-radius-10 bg-cs-container min-height-820'>
            <div className='fs-heading-large font-semi-bold'>パスワード再設定</div>
            <form onSubmit={handleSubmit(onSubmit)} className='mg-t-46'>
                <div className='row align-items-start'>
                    <label className='col-3 fs-default font-medium text-color-cs-8'>現在のパスワード <span className='text-color-cs-red'>*</span></label>
                    <div className='col-8 text-color-cs-white font-medium fs-label'>
                        <input
                            className='input-type-2 w-100 reset-auto-fill'
                            type={!currentPassword ? 'password' : 'text'}
                            {...register('currentPassword')}
                            id='currentPassword'
                            tabIndex={1}
                            autoComplete='off' />
                        <button type='button' className='btn-icon-input' onClick={toggleShowCurrentPassword}>
                            <i className={classNames('text-white', currentPassword ? 'fas fa-eye' : 'fas fa-eye-slash')}></i>
                        </button>
                        {
                            errors?.currentPassword &&
                            <div className='text-danger mg-t-5 fs-error'>{errors.currentPassword?.message}</div>
                        }
                    </div>
                </div>
                <div className='row mg-t-25 align-items-start'>
                    <label className='col-3 fs-default font-medium text-color-cs-8'>
                        新しいパスワード
                        <span className='text-color-cs-red'>* </span>
                        <div className='header-nav-dropdown dropdown'>
                            <i className='fa-solid fa-exclamation fa-rotate-180 info-icon header-menu dropdown'
                                type='button' data-bs-toggle='dropdown' aria-expanded='false'></i>
                            <ul className='dropdown-menu dropdown-cs-menu dropdown-cs-menu-left pd-10'>
                                {TOOLTIP_FORMAT_PASSWORD.map((item, index) => {
                                    return (
                                        <li key={index} className='dropdown-item font-semi-bold'>
                                            {item}
                                        </li>
                                    );
                                })}
                            </ul>
                        </div>
                    </label>
                    <div className='col-8 text-color-cs-white font-medium fs-label'>
                        <input
                            className='input-type-2 w-100 reset-auto-fill'
                            type={!newPassword ? 'password' : 'text'}
                            {...register('newPassword')}
                            id='newPassword'
                            tabIndex={2}
                            autoComplete='off' />
                        <button type='button' className='btn-icon-input' onClick={toggleShowNewPassword}>
                            <i className={classNames('text-white', newPassword ? 'fas fa-eye' : 'fas fa-eye-slash')}></i>
                        </button>
                        {
                            errors?.newPassword &&
                            <div className='text-danger mg-t-5 fs-error'>{errors.newPassword?.message}</div>
                        }
                    </div>
                </div>
                <div className='row mg-t-25 align-items-start'>
                    <label className='col-3 fs-default font-medium text-color-cs-8'>新しいパスワードを再入力 <span className='text-color-cs-red'>*</span></label>
                    <div className='col-8 text-color-cs-white font-medium fs-label'>
                        <input
                            className='input-type-2 w-100 reset-auto-fill'
                            type={!newPasswordConfirm ? 'password' : 'text'}
                            {...register('newPasswordConfirm')}
                            id='newPasswordConfirm'
                            tabIndex={3}
                            autoComplete='off' />
                        <button type='button' className='btn-icon-input' onClick={toggleShowNewPasswordConfirm}>
                            <i className={classNames('text-white', newPasswordConfirm ? 'fas fa-eye' : 'fas fa-eye-slash')}></i>
                        </button>
                        {
                            errors?.newPasswordConfirm &&
                            <div className='text-danger mg-t-5 fs-error'>{errors.newPasswordConfirm?.message}</div>
                        }
                    </div>
                </div>
                <div className='row mg-t-70'>
                    <div className='col-3'></div>
                    <div className='col-8 d-flex justify-content-center flex-gap-100'>
                        <button type='button' 
                            className='btn btn-cs-1 text-color-cs-white width-150 btn-common' onClick={onClickCancelButton}>キャンセル</button>
                        <button type='submit' className='btn btn-bg-cs-1 width-150 btn-common' disabled={isSubmitting}>再設定</button>
                    </div>
                </div>
            </form>
        </div>
    )
})

export default ChangePasswordScreen;