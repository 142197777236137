import ApiService from './ApiService';

class CardApi extends ApiService {
    constructor() {
        super('card');
    }

    getListCard(payload) {
        return this.post('', payload);
    }

    getListCardHold() {
        return this.get('hold');
    }

    getListAvailableCard(payload) {
        return this.get('cart', payload);
    }

    addCardtoHold(payload) {
        return this.post('hold', payload);
    }

    removeCardfromHolder(id) {
        return this.delete(`hold/${id}`);
    }

    buyCard(payload) {
        return this.post('buy', payload);
    }

    setNotification({ id, payload }) {
        return this.put(`hold/${id}/notify`, payload);
    }

    updateStatusLockCard({ id, payload }) {
        return this.put(`hold/${id}/status-lock`, payload);
    }

    sortCard({ id, payload }) {
        return this.put(`hold/${id}/sort`, payload);
    }
}

export default CardApi;
