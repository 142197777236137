/* eslint-disable max-len */
import { useForm } from 'react-hook-form';
import { useStore } from '../../../core/utils/hook';
import { useEffect } from 'react';
import { FormatNumber } from '../../../components';
import { yupResolver } from '@hookform/resolvers/yup';
import yup from '../../../core/utils/yupValidate';
import { MSG } from '../../../core/configs/constants';
import SendMailMessage from './SendMailMessage';

const BuyCardsModal = (props) => {
    //other
    const {total, cardIds,  onGetAllCardInCart} = props;
    //store
    const {authStore: { accountInfo, getInfo }, cardStore: {buyCard, setAttrObservable}, modalStore: {show, hide}} = useStore()
    //state
    const validateSchema = yup.object().shape({
        selectedDropAddress: yup.string().trim().required(MSG['error.required'])
    })
    const {
        register, 
        handleSubmit, 
        getValues, 
        setValue,
        reset,
        watch,
        formState: { errors, isSubmitting }
    } = useForm({resolver: yupResolver(validateSchema), mode: 'onChange'});
    
    //life cycle
    // useEffect(() => {
    //     const getAccountInfo = async () => {
    //         await getInfo();
    //     }
    //     getAccountInfo();
    // }, [])

    useEffect(() => {
        const { dropAddress = [] } = accountInfo.user;
        reset({selectedDropAddress: dropAddress.length ? dropAddress[0] : ''});
    }, [accountInfo])
    //modal
    const openSendMailMessage = () => {
        show({
            id: 'modal-message',
            isOpen: true,
            notButtonX: true,
            children: (<SendMailMessage 
                text={MSG['inform.success.buy_card']}
                cb={() => {
                    onGetAllCardInCart();
                    hide();
                }} />
            )
        })
    }
    //function
    const onSubmitPayment = async (data) => {
        let res = await buyCard({ cardIds, dropAddress: data.selectedDropAddress });
        if (res) {
            hide();
            openSendMailMessage();
        }
    }


    return <div className="payment pd-20 border-top">
        <form onSubmit={handleSubmit(onSubmitPayment)}>
            <div className="d-flex">
                <div className="width-170 fs-label font-medium text-color-cs-11" >選択したカード数</div>
                <div className="mg-l-0 text-color-cs-2 mg-b-0 fs-label" >{cardIds && cardIds.length}</div>
            </div>
            <div className='mg-t-25 d-flex'>
                <div className="width-170 fs-label font-medium text-color-cs-11" >Airdrop総量</div>
                <div className="mg-l-0 text-color-cs-2 mg-b-0 fs-label" >{total?.totalAirdrop || total?.totalAirdrop == 0 ? <div> <FormatNumber statusDisplay='text' value={total?.totalAirdrop} suffix=' SML' /></div> : ''}</div>
                
            </div>
            <div className="mg-t-25 d-flex">
                <label htmlFor="drop-address-id" className="width-170 fs-label font-medium text-color-cs-11" >受け取りID</label>
                <div className='flex-fill'>
                    <select {...register('selectedDropAddress')} className="bg-black w-100 pd-5 height-40 text-color-cs-2"
                        id="drop-address-id" >
                        {accountInfo?.user?.dropAddress && accountInfo.user.dropAddress.length &&
                            accountInfo.user.dropAddress.map((dropAdd) => <option key={dropAdd} value={dropAdd}>{dropAdd}</option>)}
                    </select>
                    {
                        errors?.selectedDropAddress &&
                                <div className='text-danger mg-t-5 fs-error'>{errors.selectedDropAddress?.message}</div>
                    }
                </div>
                
            </div>
            <div className='mg-t-25 d-flex'>
                <div className="width-170 fs-label font-medium text-color-cs-11" htmlFor="totalPrice">総合計</div>
                <div className="mg-l-0 text-color-cs-red fs-heading-normal mg-b-0" id="totalPrice">{total?.totalPrice || total?.totalPrice == 0 ? <div><FormatNumber statusDisplay='text' value={total?.totalPrice} /> 円</div> : ''}</div>
            </div>
            
            <div className='mg-t-35 modal-footer-group d-flex align-items-center justify-content-center flex-wrap'>
                <button type='button' onClick={() => hide()} className='btn btn-bg-cs-6-bd-cs-1 min-width-130 '>キャンセル</button>
                <button type="submit" className='btn btn-bg-cs-1 min-width-130 '>決済</button>
            </div>
        </form>
    </div>
}
export default BuyCardsModal;