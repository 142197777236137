import ApiService from './ApiService';

class AuthApi extends ApiService {

    login(payload) {
        return this.post('login', payload);
    }

    logout(payload) {
        return this.post('logout', payload);
    }

    register(payload) {
        return this.post('register-member', payload);
    }

    logoutAll(payload) {
        return this.post('logout-all', payload);
    }

    forgetPassword(payload) {
        return this.post('forget-password', payload);
    }

    verifyUuid(payload) {
        return this.post('verify-uuid', payload);
    }

    setPassword(payload) {
        return this.post('set-password', payload);
    }

    getInfo() {
        return this.get('profile');
    }

    changePassword(payload) {
        return this.post('change-password', payload);
    }

    changeEmail(payload) {
        return this.post('change-email', payload);
    }

    confirmEmail(payload) {
        return this.get('confirm-email', payload);
    }

    withDraw() {
        return this.post('withdraw');
    }

    cancelPremium() {
        return this.post('cancel-premium')
    }

    getAccountList(payload) {
        return this.get('account', payload);
    }

    addAccount(payload) {
        return this.post('account', payload);
    }

    updateUsernameAccount(payload) {
        return this.put('account', payload);
    }
}

export default AuthApi;