import { useNavigate } from 'react-router-dom';
import '../style.scss';

const SuccessMessage = (props) => {
    //other
    const navigate = useNavigate();


    return (<div className='pd-tb-30 pd-lr-20'>
        <div className='text-center'>
            <i className='fa-solid fa-circle-check fs-cs-45'></i>
            <div className='mg-t-10 fs-cs-16 font-semi-bold'>決済が完了しました。</div>
        </div>
        <div className='mg-t-25 modal-footer-group d-flex align-items-center justify-content-center flex-wrap'>
            <button className='btn btn-bg-cs-6-bd-cs-1 min-width-170' onClick={() => navigate('/')}>Holder一覧を表示</button>
            <button className='btn btn-bg-cs-1 min-width-170' onClick={() => navigate('/purchase-history')}>決済情報を表示</button>
        </div>
    </div>)
}

export default SuccessMessage;