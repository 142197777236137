import { observer } from 'mobx-react';
import { useNavigate, useParams } from 'react-router-dom';
import { useStore } from '../../core/utils/hook';
import { useEffect } from 'react';
import { io } from 'socket.io-client';
import { URL_SOCKET_PURCHASE } from '../../core/utils/socket';
import moment from 'moment';
import { PURCHASE_STATUS, SYSTEM_PATH } from '../../core/configs/constants';
import { FormatNumber } from '../../components';

const PurchaseHolderDetail = observer(() => {

    // other
    const navigate = useNavigate();
    const { id } = useParams();

    // store
    const { 
        transactionStore: { getDetailTransaction, detailTransaction, setAttrObservable },
        authStore: { accountInfo }
    } = useStore();

    // lifecycle
    useEffect(() => {
        getData();

        return () => {
            setAttrObservable('detailTransaction', {});
        }
    }, [])

    useEffect(() => {
        const socketPurchase = io(URL_SOCKET_PURCHASE);

        socketPurchase.on('connect', () => {
            socketPurchase.emit('user-connected', {accountId: accountInfo?.id})
        })

        socketPurchase.on('accept-purchase-status', () => {
            getData(true);
        });

        return () => {
            socketPurchase.off('accept-purchase-status');
            socketPurchase.disconnect();
        };
    }, []);

    // function
    const getData = async (disableLoading) => {
        if (id) {
            await getDetailTransaction(id, disableLoading);
        }
    }

    const navigateToHolderHistory = () => {
        navigate(SYSTEM_PATH.PURCHASE_HISTORY);
    }

    const purchaseStatus = (transaction) => {
        return Object.keys(PURCHASE_STATUS).filter(e => PURCHASE_STATUS[e].value === transaction)?.[0] ?? null;
    }

    return (
        <div className='purchase-holder-detail-screen pd-20 border-radius-10 bg-cs-container min-height-820'>
            <div className='purchase-holder-detail-info'>
                <div className='fs-heading-large heading font-semi-bold'>取引詳細</div>
                <div className='d-flex align-items-center flex-wrap mg-t-20 card-buy-detail'>
                    <div className='d-flex col-lg-4 col-md-6 col-12 align-items-center  mg-b-20 card-buy-detail-item '>
                        <div className='col-6 label-width  text-color-cs-9 card-buy-detail-item-label'>取引No</div>
                        <div className='w-full'>{detailTransaction?.code}</div>
                    </div>
                    <div className='d-flex col-lg-4 col-md-6 col-12 align-items-center mg-b-20 card-buy-detail-item '>
                        <div className='col-6 label-width text-color-cs-9 card-buy-detail-item-label'>作成日</div>
                        <div className='w-full'>{detailTransaction?.createdAt ?
                            moment(detailTransaction?.createdAt).format('YYYY/MM/DD HH:mm:ss') : ''}</div>
                    </div>
                    <div className='d-flex col-lg-4 col-md-6 col-12 align-items-center mg-b-20 card-buy-detail-item '>
                        <div className='col-6 label-width text-color-cs-9 card-buy-detail-item-label'>支払日</div>
                        <div className='w-full'>{detailTransaction?.transactionDate ?
                            moment(detailTransaction?.transactionDate).format('YYYY/MM/DD HH:mm:ss') : ''}</div>
                    </div>
                    <div className='d-flex col-lg-4 col-md-6 col-12 align-items-center mg-b-20 card-buy-detail-item '>
                        <div className='col-6 label-width  text-color-cs-9 card-buy-detail-item-label'>Holder総枠数</div>
                        <div className='w-full'>{detailTransaction?.numberHolder || ''}</div>
                    </div>
                    <div className='d-flex col-lg-4 col-md-6 col-12 align-items-center mg-b-20 card-buy-detail-item '>
                        <div className='col-6 label-width text-color-cs-9 card-buy-detail-item-label'>支払総額</div>
                        {detailTransaction?.totalPayment > 0 ? <div className='text-color-cs-14 w-full'>
                            <FormatNumber 
                                className='text-color-cs-14' statusDisplay='text' value={detailTransaction?.totalPayment} suffix=' 円' /></div> : ''}
                    </div>
                    <div className='d-flex col-lg-4 col-md-6 col-12 align-items-center mg-b-20 card-buy-detail-item '>
                        <div className='col-6 label-width text-color-cs-9 card-buy-detail-item-label'>支払いステータス</div>
                        <div className='w-full'>
                            <div 
                                className='d-flex justify-content-center align-items-center width-120 height-20 border-radius-10' 
                                style={{ 'backgroundColor': PURCHASE_STATUS[purchaseStatus(detailTransaction?.status)]?.color }} >
                                <div> 
                                    {
                                        purchaseStatus(detailTransaction?.status) && 
                                            PURCHASE_STATUS[purchaseStatus(detailTransaction?.status)]?.label
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className='mg-t-16 list-item'>
                <div className='mg-t-20 mg-b-20 fs-heading-large heading font-semi-bold'> 取引一覧</div>
                {
                    detailTransaction?.holdersOfAccounts?.length > 0 &&
                    detailTransaction?.holdersOfAccounts.map((transaction, index) => (
                        <PurchaseHolderItem key={index} data={transaction} holderFee={detailTransaction.holderFee} />
                    ))
                }
            </div>

            <div className='mg-t-30 mg-b-10'>
                <button 
                    type='button' 
                    onClick={navigateToHolderHistory}
                    className='btn btn-bg-cs-6-bd-cs-1 min-width-150 d-block mx-auto' >戻る</button>
            </div>

        </div>
    )
})

const PurchaseHolderItem = ({ data, holderFee }) => {
    return (
        <div className='purchase-holder-item pd-b-20'>
            <div className='mg-tb-20 d-flex flex-wrap'>
                <div className='d-flex col-lg-4 col-md-6 col-12 align-items-center mg-b-20 card-buy-detail-item '>
                    <div className='col-6 label-width  text-color-cs-9 card-buy-detail-item-label'>アカウント</div>
                    <div className='w-full'>{data?.account?.userName || ''}</div>
                </div>
                <div className='d-flex col-lg-4 col-md-6 col-12 align-items-center mg-b-20 card-buy-detail-item '>
                    <div className='col-6 label-width text-color-cs-9 card-buy-detail-item-label'>Holder総枠数</div>
                    <div className='w-full'>{data?.holders?.length || 0}</div>
                </div>
                <div className='d-flex col-lg-4 col-md-6 col-12 align-items-center card-buy-detail-item '>
                    <div className='col-6 label-width text-color-cs-9 card-buy-detail-item-label'></div>
                    <div className='w-full'></div>
                </div>
            </div>
            <div className='d-flex flex-wrap gap-2'>
                {
                    data?.holders?.length > 0 &&
                    data?.holders?.map((holder, index) => (
                        <PurchaseItem key={index} data={holder} index={index} holderFee={holderFee} />
                    ))
                }
            </div>
        </div>
    )
}

const PurchaseItem = ({ data, index, holderFee }) => {
    return (
        <div className='purchase-item bg-cs-lighter-container d-flex align-items-center gap-4 pd-20 border-radius-10' style={{width: '32%'}}>
            <div className='width-70 height-95'>
                <img src='/images/PurchaseHolderItemImg.png' alt="gold card" />
            </div>
            <div className='d-flex flex-column gap-2 w-75'>
                <div>{`Holder ${index + 1}`}</div>
                <div className='row'>
                    <div className='col-6'>利用期間</div>
                    <div className='col-6'>{`${data?.months}か月`}</div>
                </div>
                <div className='row'>
                    <div className='col-6'>支払総額</div>
                    <FormatNumber 
                        className='col-6' 
                        statusDisplay='text' 
                        value={data.months*holderFee} 
                        suffix=' 円' />
                </div>
            </div>
        </div>
    )
}

export default PurchaseHolderDetail;