/* eslint-disable max-len */
import { yupResolver } from '@hookform/resolvers/yup';
import { observer } from 'mobx-react';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { Link, Navigate, useNavigate, useParams } from 'react-router-dom';
import { AIRdrop_STATUS, MSG, SYSTEM_PATH } from '../../core/configs/constants';
import { useStore } from '../../core/utils/hook';
import yup from '../../core/utils/yupValidate';
import classNames from 'classnames';
import './style.scss'
import { useEffect } from 'react';
import { toJS } from 'mobx';
import moment from 'moment';
import { FormatNumber } from '../../components';
import { calculateCardInfo } from '../../core/utils/common';
import { io } from 'socket.io-client';
import { URL_SOCKET_PURCHASE } from '../../core/utils/socket';
// import ListImageCard from '/list_card.svg'



const CardBuyDetail = observer(() => {

    // other
    const navigate = useNavigate();
    const { id } = useParams();

    // store
    const { 
        modalStore: { show, hide, openAlert },
        transactionStore: { getDetailTransaction, detailTransaction, setAttrObservable },
        authStore: { accountInfo }} = useStore();


    // lifecycle
    useEffect(() => {
        getData();

        return () => {
            setAttrObservable('detailTransaction', {});
        }
    }, [])

    useEffect(() => {
        const socketPurchase = io(URL_SOCKET_PURCHASE);

        socketPurchase.on('connect', () => {
            socketPurchase.emit('user-connected', {accountId: accountInfo?.id})
        })

        socketPurchase.on('update-airdrop-status', () => {
            getData(true);
        });

        return () => {
            socketPurchase.off('update-airdrop-status');
            socketPurchase.disconnect();
        };
    }, []);

    // function
    const getData = async (disableLoading) => {

        if (id) {
            await getDetailTransaction(id, disableLoading);
        }
    }

    const onShow = () => {
        show({
            id: 'modal',
            isOpen: true,
            header: '確認',
            onCancel: () => hide(),
            children: (
                <div className='pd-tb-10 pd-lr-20'>
                    <span>Holderに追加しますか？</span>
                    <div className='mg-t-20 d-flex align-items-center justify-content-center flex-gap-20 flex-wrap'>
                        <button className='btn btn-bg-cs-6 width-130' onClick={() => hide()}>キャンセル</button>
                        <button className='btn btn-bg-cs-1 width-130'>解除</button>
                    </div>
                </div>
            )
        })
    }

    /// state


    const airDropStatus = Object.keys(AIRdrop_STATUS).filter(e => AIRdrop_STATUS[e].value === detailTransaction?.airdropStatus)?.[0] ?? null;


    // function




    return (
        <div className='list-card-buy-screen list-purchase-history-screen pd-20 border-radius-10 bg-cs-container min-height-820'>
            <div>
                <div className='mg-b-20 fs-heading-large heading font-semi-bold'>取引詳細</div>

                <div className='d-flex align-items-center  flex-wrap mg-t-20 card-buy-detail'>
                    <div className='d-flex col-lg-4 col-md-6 col-12 align-items-center  mg-b-20 card-buy-detail-item '>
                        <div className='col-6 label-width  text-color-cs-9 card-buy-detail-item-label'>取引No</div>
                        <div className='w-full'>{detailTransaction?.code}</div>
                    </div>
                    <div className='d-flex col-lg-4 col-md-6 col-12 align-items-center mg-b-20 card-buy-detail-item '>
                        <div className='col-6 label-width text-color-cs-9 card-buy-detail-item-label'>作成日</div>
                        <div className='w-full'>{detailTransaction?.createdAt ?
                            moment(detailTransaction?.createdAt).format('YYYY/MM/DD HH:mm:ss') : ''}</div>
                    </div>
                    <div className='d-flex col-lg-4 col-md-6 col-12 align-items-center  mg-b-20 card-buy-detail-item '>
                        <div className='col-6 label-width  text-color-cs-9 card-buy-detail-item-label'>選択したカード数</div>
                        <div className='w-full'>{detailTransaction?.cards?.length || 0}</div>
                    </div>
                    <div className='d-flex col-lg-4 col-md-6 col-12 align-items-center  mg-b-20 card-buy-detail-item '>
                        <div className='col-6 label-width text-color-cs-9 card-buy-detail-item-label'>総合計</div>
                        {detailTransaction?.totalPayment > 0 ? <div className='text-color-cs-14 w-full'>
                            <FormatNumber className='text-color-cs-14' statusDisplay='text' value={detailTransaction?.totalPayment} suffix=' 円' /></div> : ''}
                    </div>
                    <div className='d-flex col-lg-4 col-md-6 col-12 align-items-center  mg-b-20 card-buy-detail-item '>
                        <div className='col-6 label-width text-color-cs-9 card-buy-detail-item-label'>支払日</div>
                        <div className='w-full'>{detailTransaction?.transactionDate ?
                            moment(detailTransaction?.transactionDate).format('YYYY/MM/DD HH:mm:ss') : ''}</div>
                    </div>
                    <div className='d-flex col-lg-4 col-md-6 col-12 align-items-center  mg-b-20 card-buy-detail-item '>
                        <div className='col-6 label-width text-color-cs-9 card-buy-detail-item-label'>Airdrop総量</div>
                        {detailTransaction?.airdrop > 0 ? <div className=' w-full'>
                            <FormatNumber className='' statusDisplay='text' value={detailTransaction?.airdrop} suffix=' SML' /> </div> : ''}
                    </div>
                    <div className='d-flex col-lg-4 col-md-6 col-12 align-items-center   mg-b-20 card-buy-detail-item '>
                        <div className='col-6 label-width text-color-cs-9 card-buy-detail-item-label'>Drop先アドレス</div>
                        <div className='w-full'>{detailTransaction?.dropAddress}</div>
                    </div>
                    <div className='d-flex col-lg-4 col-md-6 col-12 align-items-center   mg-b-20 card-buy-detail-item  '>
                        <div className='col-6 label-width text-color-cs-9 card-buy-detail-item-label'>Airdropステータス</div>

                        <div className='w-full' style={{ 'color': AIRdrop_STATUS[airDropStatus]?.color }} >
                            {airDropStatus && AIRdrop_STATUS[airDropStatus]?.label}
                        </div>
                        {/* <div className='w-full'>{detailTransaction?.airdropStatus === 3 ?
                            AIRdrop_STATUS.WAITING_PAYMENT : detailTransaction.airdropStatus === null ?
                                AIRdrop_STATUS.FAILED : AIRdrop_STATUS.COMPLETED_PAYMENT}</div> */}
                    </div>
                    {/* <div className='d-flex align-items-center   history-purchase-card-label-item mg-b-10  
                                     history-purchase-card-label-item-collapse'>
                            <div className='width-200 label-width text-color-cs-9'>Drop先アドレス</div>
                            <div className='w-full'>SMLPC000000004</div>
                        </div>
                        <div className='d-flex align-items-center   history-purchase-card-label-item mg-b-10  
                                     history-purchase-card-label-item-collapse'>
                            <div className='width-200 label-width text-color-cs-9'>AIRdropステータス</div>
                            <div className='w-full'>SMLPC000000004</div>
                        </div> */}


                </div>
            </div>
            <div className='mg-t-16 list-item'>
                <div className='mg-t-20 mg-b-20 fs-heading-large heading font-semi-bold'> 取引一覧</div>
                {detailTransaction?.cards?.length > 0 ? detailTransaction?.cards?.map((item) => {
                    const variationRate = calculateCardInfo(item?.currentRate, item?.currencyRate, item)?.variationRate
                    return (

                        // eslint-disable-next-line max-len
                        <div key={item.id} className='d-flex align-items-center justify-content-center col-12 flex-wrap mg-b-24'>
                            <div className='list-card-buy-item list-card-item-purchase d-flex flex-gap-20 align-items-center justify-content-center '>
                                <div className='width-180 card-detail-image '>
                                    <img className='w-100 ' src={item?.image}></img>
                                </div>
                                <div className='d-flex max-width-custom' style={{ 'maxWidth': 'calc(100% - 200px)' }}>
                                    <div className='d-flex align-items-center justify-content-start  '>
                                        <div className='d-flex align-items-center justify-content-start flex-wrap list-card-item-purchase-option'>
                                            <div className='d-flex align-items-center col-4 min-width-175 mg-b-30 min-width-custom card-item '>

                                                <div>
                                                    <span className='d-block text-color-cs-13' >管理番号</span>
                                                    <span className='d-block font-semi-bold' >{item?.code}</span>
                                                </div>
                                            </div>
                                            <div className='d-flex align-items-center col-4 min-width-175 mg-b-30 min-width-custom card-item  '>


                                                <div>
                                                    <span className='d-block text-color-cs-13' >基準レート</span>
                                                    <span className='d-block font-semi-bold' >{item?.baseRate} USDT</span>
                                                </div>
                                            </div>
                                            <div className='d-flex align-items-center col-4 min-width-175 mg-b-30 min-width-custom card-item '>


                                                <div>
                                                    <span className='d-block text-color-cs-13' >カード価格</span>
                                                    {item?.cardPrice > 0 ? <div className='d-block font-semi-bold'>
                                                        <FormatNumber statusDisplay='text'
                                                            value={item?.cardPrice} suffix=' 円' /> </div> : ''}

                                                </div>
                                            </div>
                                            <div className='d-flex align-items-center col-4 min-width-175 mg-b-30 min-width-custom card-item  '>


                                                <div>
                                                    <span className='d-block text-color-cs-13' >Airdrop</span>
                                                    {item?.airdrop || item?.airdrop === 0 ?
                                                        <div className='d-block font-semi-bold'>
                                                            <FormatNumber statusDisplay='text' value={item?.airdrop} suffix=' SML' />
                                                        </div> : ''}
                                                </div>
                                            </div>
                                            <div className='d-flex align-items-center col-4 min-width-175 mg-b-30 min-width-custom card-item  '>

                                                <div>
                                                    <span className='d-block text-color-cs-13' >ユーザー購入時レート</span>
                                                    <span className='d-block ' > <FormatNumber statusDisplay='text' value={item?.currentRate} decimalScale={6} suffix=' USDT' /></span>

                                                    {/* <span className='d-block ' >{parseFloat(item?.currentRate.toFixed(6))}</span> */}
                                                </div>
                                            </div>
                                            <div className='d-flex align-items-center col-4 min-width-175 mg-b-30 min-width-custom card-item '>

                                                <div>
                                                    <span className='d-block text-color-cs-13' >ユーザー購入時価値 </span>
                                                    {variationRate > 0 ? <span className='d-block text-setting' >
                                                        <FormatNumber className='d-block text-setting' prefix='+' statusDisplay='text' value={variationRate} decimalScale={2} suffix=' %' />
                                                    </span> : variationRate < 0 ?
                                                        <span className='d-block text-danger' > <FormatNumber className='d-block text-danger' allowNegative statusDisplay='text' value={variationRate} decimalScale={2} suffix=' %' /></span> :
                                                        <span className='d-block ' >{variationRate} %</span>}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    )
                }) : <div className='d-flex align-items-center justify-content-center mg-t-10 '>
                    <span className='fs-heading-normal font-bold text-color-cs-1'>{MSG['inform.no_data']}</span>
                </div>}
            </div>
            <div className=' mg-t-30 mg-b-10'>
                <button type='button' onClick={() => navigate(SYSTEM_PATH.CARD_BUY_HISOTRY)}
                    className='btn btn-bg-cs-6-bd-cs-1 min-width-150 d-block mx-auto' >戻る</button>

            </div>

        </div>

    )
})

export default CardBuyDetail;