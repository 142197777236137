import { Link } from 'react-router-dom';
import { FOOTER_NAV } from '../../core/configs/constants';
import $ from 'jquery';
import { useEffect, useState } from 'react';

const Footer = props => {
    // state
    const [showBackToTop, setShowBackToTop] = useState(false);

    // life cycle
    useEffect(() => {
        const handleScroll = () => {
            if ($(window).scrollTop() > 0) {
                setShowBackToTop(true);
            } else {
                setShowBackToTop(false);
            }
        };

        $(window).on('scroll', handleScroll);

        return () => {
            $(window).off('scroll', handleScroll);
        };
    }, []);

    // function
    const handleClickBackToTop = () => {
        $('html, body').animate({ scrollTop: 0 }, 250, 'swing', () => { });
    }
    
    return (
        <div className='footer-container'>
            {
                showBackToTop && (
                    <button className='btn-back-to-top btn-bg-cs-1 shadow' onClick={handleClickBackToTop}>
                        <i className='fa-solid fa-chevron-up'></i>
                    </button>
                )
            }
            <div className='fs-heading-normal'>Copyright 2023 - Advance Ability Inc.</div>
            <div className='footer-nav'>
                {
                    Object.keys(FOOTER_NAV).map((key, idx) => (
                        <Link key={idx} to={FOOTER_NAV[key].path} 
                            className='text-white fs-heading-normal font-bold'>
                            {FOOTER_NAV[key].label}
                        </Link>
                    ))
                }
            </div>
        </div>
    )
}

export default Footer;