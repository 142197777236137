/* eslint-disable max-len */
import { yupResolver } from '@hookform/resolvers/yup';
import { observer } from 'mobx-react';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { Link, Navigate, useNavigate } from 'react-router-dom';
import { MSG, PAYMENT_TYPE, PURCHASE_STATUS, SYSTEM_PATH } from '../../core/configs/constants';
import { useStore } from '../../core/utils/hook';
import yup from '../../core/utils/yupValidate';
import classNames from 'classnames';
import { FormatNumber, FullPagination, ReactNotifications } from '../../components';
import './style.scss'
import { useEffect } from 'react';
import moment from 'moment';
import { io } from 'socket.io-client';
import { URL_SOCKET_PURCHASE } from '../../core/utils/socket';



const CardBuyHistory = observer(() => {

    // other
    const navigate = useNavigate();

    // store
    const { 
        modalStore: { show, hide, openAlert }, 
        transactionStore: { listTransaction, getAllTransaction, setAttrObservable, paging, cancelTransaction },
        authStore: { accountInfo } } = useStore();

    //state
    const { register, getValues, watch, handleSubmit } = useForm({
        mode: 'onChange', defaultValues: {
            // price: '0',
            // sort: '0'
        }
    });


    /// state
    const [cacheData, setCacheData] = useState([]);
    const purchaseStatus = (transaction) => {
        return Object.keys(PURCHASE_STATUS).filter(e => PURCHASE_STATUS[e].value === transaction)?.[0] ?? null;
    }

    //lifecycle
    useEffect(() => {
        onFetchCardBuyHistoryList({ page: 1, size: 10, type: PAYMENT_TYPE.CARD });
        return () => {
            setAttrObservable('listTransaction', []);
        }
    }, [])

    useEffect(() => {
        const socketPurchase = io(URL_SOCKET_PURCHASE);

        socketPurchase.on('connect', () => {
            socketPurchase.emit('user-connected', {accountId: accountInfo?.id})
        })

        socketPurchase.on('accept-purchase-status', () => {
            onSearch(true);
        });

        return () => {
            socketPurchase.off('accept-purchase-status');
            socketPurchase.disconnect();
        };
    }, []);

    // function
    const onShow = (id) => {
        show({
            id: 'modal',
            isOpen: true,
            header: '確認',
            onCancel: () => hide(),
            children: (
                <div className='mg-b-20 pd-lr-20'>
                    <span>本取引をキャンセルしてもよろしいでしょうか</span>
                    <div className='mg-t-20 d-flex align-items-center justify-content-center flex-gap-20 flex-wrap'>
                        <button className='btn btn-bg-cs-6 width-130 btn-bg-cs-6-bd-cs-1' onClick={() => hide()}>いいえ</button>
                        <button className='btn btn-bg-cs-1 width-130' onClick={() => holderCard(id)}>はい</button>
                    </div>
                </div>
            )
        })
    }

    const holderCard = async (id) => {
        hide();
        const res = await cancelTransaction(id);
        if (res) {
            ReactNotifications('success', MSG['inform.success.create']);
            onFetchCardBuyHistoryList({ page: 1, size: 10, type: PAYMENT_TYPE.CARD });
        }

    }

    const onSearch = (disableLoading) => {
        onFetchCardBuyHistoryList({ page: 1, size: 10, type: PAYMENT_TYPE.CARD }, () => {}, disableLoading);
    }


    const onFetchCardBuyHistoryList = async (payload, disableLoading) => {
        const { transactionCode, transactionStatus } = getValues();
        await getAllTransaction({
            transactionCode: transactionCode || null,
            transactionStatus: transactionStatus || null,
            type: PAYMENT_TYPE.CARD,
            ...payload
        }, () => {}, disableLoading);
    }

    // function

    const onPageChange = (page) => {
        onFetchCardBuyHistoryList({ page });
    }


    return (
        <div className='list-card-screen  list-purchase-history-screen pd-20 border-radius-10 bg-cs-container  
        list-purchase-history-responsive min-height-820 card-buy-history payment-screen'>
            <div className='mg-t-20 fs-heading-large font-semi-bold '>カード購入取引履歴</div>
            <div className='w-full mg-t-32'>
                {/* <form onSubmit={handleSubmit(onSearch)} >
                    <div className='d-flex align-items-center justify-content-between flex-gap-20 flex-wrap list-card-list-select'>

                        <div className='d-flex align-items-center  flex-wrap list-card-list-select-item'>
                            <label className='width-100'>取引No</label>
                            <input {...register('transactionCode')} className='width-400 select-custom input-custom'>
                            </input>
                        </div>
                        <div className='d-flex align-items-center  flex-wrap list-card-list-select-item'>
                            <label className='width-100' >支払い状態</label>
                            <select {...register('transactionStatus')} className='width-400 height-45 select-custom'>
                                <option value={''}>すべて</option>
                                {
                                    // eslint-disable-next-line max-len
                                    Object.keys(PURCHASE_STATUS).map(e => <option key={PURCHASE_STATUS[e].value} value={PURCHASE_STATUS[e].value} >{PURCHASE_STATUS[e].label}</option>)
                                }
                            </select>
                        </div>
                    </div>
                    <button type='submit' className='btn btn-bg-cs-1 min-width-150 d-block mx-auto mg-tb-20' >検索</button>
                </form> */}
                <form onSubmit={handleSubmit(onSearch)} >
                    <div className='d-flex align-items-center justify-content-between flex-gap-80 flex-wrap list-card-list-select'>

                        <div className='d-flex align-items-center flex-gap-10 list-card-list-select-item  col-6-select  flex-wrap '>
                            <label className='col-2' >取引No</label >
                            <input {...register('transactionCode')} className='col-10-custom select-custom input-custom'>
                            </input>
                        </div>
                        <div className='d-flex align-items-center flex-gap-10 list-card-list-select-item  col-6-select  flex-wrap '>
                            <label className='col-2'>支払い状態</label >
                            <select {...register('transactionStatus')} className='col-10-custom select-custom height-45'>
                                <option value={''}>すべて</option>
                                {
                                    // eslint-disable-next-line max-len
                                    Object.keys(PURCHASE_STATUS).map(e => <option key={PURCHASE_STATUS[e].value} value={PURCHASE_STATUS[e].value} >{PURCHASE_STATUS[e].label}</option>)
                                }
                            </select>
                        </div>
                    </div>
                    <button type='submit' className='btn btn-bg-cs-1 min-width-150 d-block mx-auto mg-tb-40' >検索</button>
                </form>
                <div className='row mg-t-16 list-item'>
                    {listTransaction?.length > 0 ? listTransaction.map((item) => (
                        // eslint-disable-next-line max-len
                        <div key={item.id} className='d-flex align-items-center justify-content-center col-lg-6 col-sm-12 flex-wrap mg-b-24 history-purchase-card align-self-start'>
                            <div className='list-card-item row py-4'>
                                <div className='col-4 card-detail-image d-flex  align-items-center '>
                                    <img className='w-100 max-width-190' src={item?.cards?.length > 0 ? item?.cards[0]?.image : ''}></img>
                                </div>
                                <div className='col-8 history-purchase-card-label '>
                                    <div className='mg-b-10 pd-t-15 fs-heading-normal font-semi-bold'>{item?.code}</div>
                                    <div className='mg-b-10  font-semi-bold'>カード購入料</div>
                                    <div className='d-inline-grid align-items-center justify-content-center'>
                                        <div className='d-flex align-items-center history-purchase-card-label-item mg-b-10'>
                                            <div className='width-150 history-purchase-card-label-width text-color-cs-9 text-left'>選択したカード数</div>
                                            <div className='w-full'>{item?.cards?.length}</div>
                                        </div>
                                        <div className='d-flex align-items-center history-purchase-card-label-item mg-b-10'>
                                            <div className='width-150 history-purchase-card-label-width text-color-cs-9 text-left'>総合計:</div>
                                            {item?.totalPayment > 0 ? <div className='text-color-cs-14 w-full'>
                                                <FormatNumber className='text-color-cs-14' statusDisplay='text' value={item?.totalPayment} suffix=' 円' /></div> : ''}
                                        </div>
                                        <div className='d-flex align-items-center  history-purchase-card-label-item  mg-b-10'>
                                            <div className='width-150 history-purchase-card-label-width text-color-cs-9 text-left' >作成日:</div>
                                            <div className='w-full'>{item?.createdAt ? moment(item?.createdAt).format('YYYY/MM/DD HH:mm:ss') : ''}</div>
                                        </div>
                                        <div className='d-flex align-items-center  history-purchase-card-label-item mg-b-10'>
                                            <div className='width-150 history-purchase-card-label-width text-color-cs-9 text-left' >支払日:</div>
                                            <div className='w-full'>{item?.transactionDate ? moment(item?.transactionDate).format('YYYY/MM/DD HH:mm:ss') : ''}</div>
                                        </div>
                                        {item?.status === PURCHASE_STATUS.WAIT_FOR_PAYMENT.value ? <div onClick={() => onShow(item?.id)} className='width-150 height-20 d-flex align-items-center justify-content-center flex-gap-10 bg-cs-red border-radius-5 custom-pointers mg-b-20'>
                                            <img src='/images/icon_checked.svg'></img>
                                            <div role='button' className='fs-description custom-pointer'>取引キャンセル</div>
                                        </div> : <div className='invisible width-150 height-20 d-flex align-items-center justify-content-center flex-gap-10 bg-cs-red border-radius-5 custom-pointers mg-b-20'></div>}

                                    </div>
                                </div>



                            </div>
                            {/* <div className='d-block font-semi-bold'
                                                    style={{ 'color': PURCHASE_STATUS[purchaseStatus(item?.status)]?.color }} >
                                                   
                                                </div> */}
                            <div className='notification d-flex justify-content-center align-items-center width-120 height-20
                              border-radius-10' style={{ 'backgroundColor': PURCHASE_STATUS[purchaseStatus(item?.status)]?.color }} >
                                <div> {purchaseStatus(item?.status) && PURCHASE_STATUS[purchaseStatus(item?.status)]?.label}</div>
                            </div>

                            <Link to={`/card-buy-detail/${item?.id}`} className='detail-navigate d-flex justify-content-center align-items-center flex-gap-5 text-color-cs-14 '>
                                <img className='width-20' src='/images/icon_detail.svg'></img>
                                <div className='fs-default text-color-cs-14 text-center border-bottom'>詳細を見る</div>
                            </Link>



                        </div>

                    )) : <div className='d-flex align-items-center justify-content-center mg-t-10 '>
                        <span className='fs-heading-normal font-bold text-color-cs-1'>{MSG['inform.no_data']}</span>
                    </div>}

                </div>

            </div>
            <div className='d-flex align-items-center justify-content-center '>
                <FullPagination siblingCount={0}
                    totalRecords={paging.totalRecord}
                    currentPage={paging.page}
                    pageSize={paging.size}
                    onPageChange={onPageChange}
                    previousLabel="«"
                    nextLabel="»" />
            </div>
        </div>
    )
})

export default CardBuyHistory;