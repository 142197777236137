import { flow, makeObservable, observable } from 'mobx';
import { PurchaseApi } from '../apis';
import BaseStore from './BaseStore';


class PurchaseStore extends BaseStore {

    membershipFee = []
    cardPurchase = []
    expireHolder = {}
    listHoldersCanBuy = []
    listHolderCanExtend = []

    constructor(rootStore) {
        super();
        makeObservable(this, {
            membershipFee: observable,
            cardPurchase: observable,
            expireHolder: observable,
            listHoldersCanBuy: observable,
            listHolderCanExtend: observable,
            getMembershipFee: flow.bound,
            registerMembership: flow.bound,
            purchaseAddHolderFee: flow.bound,
            cancelHolder: flow.bound,
            getCardPurchase: flow.bound,
            getExpireHolder: flow.bound,
            purchaseExpireHolder: flow.bound,
            getListHoldersCanBuy: flow.bound,
            getListHoldersCanExtend: flow.bound,
            buyHolders: flow.bound,
            extendHolders: flow.bound
        })
        this.rootStore = rootStore;
        this.api = new PurchaseApi();
    }

    *getMembershipFee() {
        try {
            const res = yield this.rootStore.apiStore.call(this.api, this.api.getMembershipFee);
            if (res?.ok) {
                this.membershipFee = res?.data || [];
            }
        } catch (error) {
            console.log(error);
        }
    }
    *registerMembership(payload) {
        try {
            const res = yield this.rootStore.apiStore.call(this.api, this.api.registerMembership, payload);
            if (res?.ok) {
                return res;
            }
            return false;
        } catch (error) {
            console.log(error)
        }
    }
    *purchaseAddHolderFee(payload) {
        try {
            const res = yield this.rootStore.apiStore.call(this.api, this.api.purchaseAddHolderFee, payload);
            if (res?.ok) {
                return true;
            }
            return false;
        } catch (error) {
            console.log(error);
        }
    }

    *cancelHolder({ id, payload }) {
        try {
            const res = yield this.rootStore.apiStore.call(this.api, this.api.cancelHolder, { id, payload });
            if (res?.ok) {
                return true;
            }
            return false;
        } catch (error) {
            console.log(error)
        }
    }
    *getCardPurchase(searchParams, disableLoading = false) {
        try {
            const { size, page, sortDir, sortKey } = this.paging;
            const payload = { size, page, sortDir, sortKey, ...searchParams };
            const res = yield this.rootStore.apiStore.call(this.api, this.api.getCardPurchase, payload, false, null, disableLoading);
            if (res?.ok) {
                this.cardPurchase = res?.data?.cards?.elements || [];
                this.setAttrObservable('paging', res?.data?.cards?.paginate, true, false);
            }
            return false;
        } catch (error) {
            console.log(error)
        }
    }

    *getExpireHolder() {
        try {
            const res = yield this.rootStore.apiStore.call(this.api, this.api.getExpireHolder);
            if (res?.ok) {
                this.expireHolder = res?.data || {};
            }
        } catch (error) {
            console.log(error);
        }
    }

    *purchaseExpireHolder(payload) {
        try {
            const res = yield this.rootStore.apiStore.call(this.api, this.api.purchaseExpireHolder, payload);
            if (res?.ok) {
                return true;
            }
            return false;
        } catch (error) {
            console.log(error);
        }
    }

    *getListHoldersCanBuy() {
        try {
            const res = yield this.rootStore.apiStore.call(this.api, this.api.getListHoldersCanBuy);
            if (res?.ok) {
                this.listHoldersCanBuy = res?.data || {};
            }
        } catch (error) {
            console.log(error);
        }
    }

    *getListHoldersCanExtend() {
        try {
            const res = yield this.rootStore.apiStore.call(this.api, this.api.getListHoldersCanExtend);
            if (res?.ok) {
                this.listHolderCanExtend = res?.data || {};
            }
        } catch (error) {
            console.log(error);
        }
    }

    *buyHolders(payload) {
        try {
            const res = yield this.rootStore.apiStore.call(this.api, this.api.buyHolders, payload);
            if (res?.ok) {
                return true;
            }
            return false;
        } catch (error) {
            console.log(error);
        }
    }

    *extendHolders(payload) {
        try {
            const res = yield this.rootStore.apiStore.call(this.api, this.api.extendHolders, payload);
            if (res?.ok) {
                return true;
            }
            return false;
        } catch (error) {
            console.log(error);
        }
    }

    clean() {
        super.clean();
        this.membershipFee = []
    }
}

export default PurchaseStore;
