import { toJS } from 'mobx';
import { useEffect, useMemo, useState } from 'react';
import { TEXT_MONTHLY } from '../../core/configs/constants';
import { FormatNumber } from '../../components';

const DEFAULT_NUMBER_HOLDER_CAN_BUY = 1

const HolderPurchaseList = (props => {
    const { formMethods: { watch, setValue, reset }, accountId, data, holderFee} = props;

    // state
    const watchPurchaseHolder = watch('purchaseHolder');

    const [dataOriginal, setDataOriginal] = useState(toJS(data));
    const dataSelected = useMemo(() => {
        if(!watchPurchaseHolder) return [];
        return watchPurchaseHolder.map(e => Number(e.accountId))
    }, [JSON.stringify(watchPurchaseHolder)])

    // life cycle
    useEffect(() => {
        if (accountId) {
            const accountCurrent = data.find(item => item.id === accountId);
            if(accountCurrent) {
                const formatAccountCurrent = formatData([toJS(accountCurrent)]);
                reset({
                    purchaseHolder: formatAccountCurrent
                })
            }
        }
    }, [accountId])

    // function
    const formatData = (data) => {
        return data ? data.map(item => {
            return {
                accountId: item?.id,
                numberOfHolder: DEFAULT_NUMBER_HOLDER_CAN_BUY,
                numberOfHolderCanBuy: item?.numberCanbuyHolders,
                monthHolders: item?.numberCanbuyHolders && item.numberCanbuyHolders > 0 ? [1] : []
            }
        }) : []
    }

    const handleAddPurchaseHolder = () => {
        const dataCanAdd = dataOriginal.find(dataOriginalItem => !dataSelected.includes(dataOriginalItem.id));
        if (dataCanAdd) {
            setValue('purchaseHolder', [...watchPurchaseHolder || [], {
                accountId: dataCanAdd.id,
                numberOfHolder: DEFAULT_NUMBER_HOLDER_CAN_BUY,
                numberOfHolderCanBuy: dataCanAdd.numberCanbuyHolders,
                monthHolders: dataCanAdd?.numberCanbuyHolders && dataCanAdd.numberCanbuyHolders > 0 ? [1] : []
            }])
        }
    }

    return (
        <>
            {
                watchPurchaseHolder?.length > 0 &&
                watchPurchaseHolder.map((item, index) => (
                    <PurchaseHolderItem 
                        key={index} 
                        itemIndex={index}
                        formMethods={props.formMethods}
                        dataOriginal={dataOriginal}
                        dataSelected={dataSelected}
                        watchPurchaseHolder={watchPurchaseHolder}
                        holderFee={holderFee}
                    />
                ))
            }
            {
                dataOriginal?.length != dataSelected?.length && (
                    <div className='d-flex justify-content-center mg-t-15'>
                        <button className='btn btn-cs-1 text-color-cs-white btn-common' type='button' onClick={handleAddPurchaseHolder}>
                            <i className='fa-solid fa-circle-plus'></i>
                            <span className='mg-l-5 font-semi-bold'>別のアカウントを追加</span>
                        </button>
                    </div>
                )
            }
        </>
    )
})

const PurchaseHolderItem = (props => {

    // props
    const { formMethods: { register, watch, getValues, setValue }, 
        itemIndex, dataOriginal, dataSelected, watchPurchaseHolder, holderFee } = props;
    
    // state
    const [ listHoldersAccounts, setListHoldersAccounts ] = useState(dataOriginal);
    const watchMonthHolders = watch(`purchaseHolder.${itemIndex}.monthHolders`);
    const watchAccountId = watch(`purchaseHolder.${itemIndex}.accountId`);
    const watchNumberOfHolderCanBuy = watch(`purchaseHolder.${itemIndex}.numberOfHolderCanBuy`);
    
    // function
    const handleOnChangeAccountId = () => {
        setValue(`purchaseHolder.${itemIndex}.numberOfHolder`, DEFAULT_NUMBER_HOLDER_CAN_BUY);
        setValue(`purchaseHolder.${itemIndex}.monthHolders`, [1]);
        setValue(`purchaseHolder.${itemIndex}.numberOfHolderCanBuy`, dataOriginal.find(item => item.id == Number(watchAccountId)).numberCanbuyHolders)
    }

    const handleOnChangeNumberHolder = (e) => {
        const currentValue = e.target.value;
        if (watchMonthHolders.length > 0 && currentValue > watchMonthHolders.length) {
            const temp = Array.from({ length: Number(currentValue - watchMonthHolders.length) }, (_, index) => 1);
            setValue(`purchaseHolder.${itemIndex}.monthHolders`, [...watchMonthHolders, ...temp ])
        }
        if (currentValue < watchMonthHolders.length) {
            setValue(`purchaseHolder.${itemIndex}.monthHolders`, watchMonthHolders.slice(0, currentValue))
        }
    }

    const hanldeRemovePurchaseHolder = () => {
        setValue('purchaseHolder', [
            ...watchPurchaseHolder.slice(0, itemIndex), 
            ...watchPurchaseHolder.slice(itemIndex + 1)
        ])
    }

    const disableOptions = useMemo(() => {
        return listHoldersAccounts.filter(e => dataSelected.includes(e.id) && e.id !== watchAccountId).map(e => e.id)
    }, [dataSelected.length, watchAccountId])

    const numberHolderCanBuy = useMemo(() => {
        return dataOriginal.find(item => item.id == Number(watchAccountId)).numberCanbuyHolders
    }, [watchAccountId])

    return (
        <div className='purchase-holder-item row pd-0 mg-0 pd-b-20 purchase-holder-line'>
            <div className='row col-11-5 w-100'>
                <div className='purchase-holder-info row align-items-center mg-t-20 gap-4 g-0'>
                    <div className='purchase-holder-select col-8 pd-0'>
                        <div className='fs-label mg-b-5'>アカウント</div>
                        <select 
                            {...register(`purchaseHolder.${itemIndex}.accountId`, {
                                onChange: handleOnChangeAccountId
                            })}
                            className='select-custom height-45 w-100'>
                            {
                                listHoldersAccounts.map((item, index) => {
                                    return (
                                        <option value={item.id} key={index} disabled={disableOptions.includes(item.id)}>{item.userName}</option>
                                    )
                                })
                            }
                        </select>
                    </div>
                    <div className='purchase-holder-select col-3-5 pd-0'>
                        <div className='fs-label mg-b-5'>Holder数</div>
                        <select 
                            {...register(`purchaseHolder.${itemIndex}.numberOfHolder`, {
                                onChange: handleOnChangeNumberHolder
                            })} 
                            className='select-custom height-45 w-100'>
                            {
                                // eslint-disable-next-line max-len
                                numberHolderCanBuy && Array.from({ length: numberHolderCanBuy }, (_, index) => index + 1).map((holderNumber, index) => (
                                    <option key={index} value={Number(holderNumber)}>{holderNumber}</option>
                                ))
                            }
                        </select>
                    </div>
                </div>
                {
                    // eslint-disable-next-line max-len
                    watchMonthHolders?.length > 0 && Array.from({ length: Number(watchMonthHolders.length) }, (_, index) => index + 1).map((holderNumber, index) => (
                        <HolderPurchaseOption 
                            holderNumber={holderNumber} 
                            key={index} 
                            itemIndex={itemIndex}
                            formMethods={props.formMethods}
                            holderFee={holderFee}
                        />
                    ))
                }
            </div>
            {
                itemIndex != 0 && (
                    <div
                        style={{cursor: 'pointer'}} 
                        className='purchase-holder-remove col-0-5 pd-0 text-center mg-t-55' 
                        onClick={hanldeRemovePurchaseHolder}>
                        <i className='fa-regular fa-trash-can fs-description-large'></i>
                    </div>
                )
            }
        </div>
    )
})

const HolderPurchaseOption = ({holderNumber, itemIndex, formMethods, holderFee}) => {

    // props
    const { register, watch } = formMethods;

    // state
    const watchMonthHolder = watch(`purchaseHolder.${itemIndex}.monthHolders.${holderNumber-1}`);

    return (
        <div className='purchase-holder-option row align-items-center mg-t-15 gap-4 g-0'>
            <div className='col-8 d-flex pd-0 align-items-center'>
                <div className='col-5 font-semi-bold fs-heading-normal text-end'>
                    <div className='mg-r-30'>Holder {holderNumber}: </div>
                </div>
                <div className='col-7 fs-label d-flex align-items-center'>
                    <span className='width-100'>利用期間</span>
                    <select {...register(`purchaseHolder.${itemIndex}.monthHolders.${holderNumber-1}`)} className='select-custom height-45 w-100'>
                        {
                            TEXT_MONTHLY.map((item, index) => (
                                <option key={index} value={index+1}>{item}</option>
                            )) 
                        }
                    </select>
                </div>
            </div>
            <div className='col-3 pd-0 align-items-center'>
                <span className='fs-label mg-r-10'>支払総額 </span>
                <FormatNumber 
                    className='fs-heading-normal text-color-cs-medium-yellow font-semi-bold' 
                    statusDisplay='text' 
                    value={watchMonthHolder*holderFee} 
                    suffix=' 円' />
            </div>
        </div>
    )
}

export default HolderPurchaseList;