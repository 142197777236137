import { BeatLoader, HashLoader, RingLoader } from 'react-spinners';

const Spinner = () => {

    return (
        <div className='spinner'>
            <RingLoader color='#D1BA7A' size={50} />
            {/* <HashLoader color='#333333'/> */}
            {/* <BeatLoader color='#333333' margin={10}/> */}
        </div>
    )
}

export default Spinner;
