import { useNavigate } from 'react-router-dom'
import { SYSTEM_PATH } from '../../../core/configs/constants';
import { observer } from 'mobx-react';
import { useStore } from '../../../core/utils/hook';

const ModalBuyHoldersSuccess = observer(() => {

    // other
    const navigate = useNavigate();

    // store
    const { modalStore: { hide } } = useStore();

    // function
    const onGoToPurchaseHistory = () => {
        hide();
        navigate(SYSTEM_PATH.PURCHASE_HISTORY);
    }

    const onGoToHolderScreen = () => {
        hide();
        navigate(SYSTEM_PATH.HOLDER);
    }

    return (
        <div className='pd-20 confirm-switch-account'>
            <div className='text-center mg-t-5'>
                <i className='fa-solid fa-circle-check fs-cs-45'></i>
                <div className='mg-t-10 fs-cs-18 font-semi-bold mg-t-20'>
                    <div>振り込み情報を含むメールが送信されました。</div>
                    <div>この取引を完了するためにはお支払いを行ってください。</div>
                </div>
            </div>
            <div className='mg-t-25 footer-button d-flex align-items-center flex-gap-20 flex-wrap'>
                <button type='button' className='d-block m-auto btn btn-bg-cs-1 min-width-200' onClick={onGoToPurchaseHistory}>取引履歴を見る</button>
                <button className=' d-block mx-auto btn btn-bg-cs-6 min-width-200' onClick={onGoToHolderScreen}>Holderに戻る</button>
            </div>
        </div>
    )
})

export default ModalBuyHoldersSuccess;