
import '../style.scss';


const SendMailMessage = ({ cb, text }) => {

    return <div className='pd-tb-30 pd-lr-20'>
        <div className='text-center'>
            <i className='fa-solid fa-circle-check fs-cs-45'></i>
            <div className='mg-t-10 fs-cs-16 font-semi-bold'>
                {text ?? '振り込み情報を含むメールが送信されました。この取引を完了するためにはお支払いを行ってください。'}
            </div>
        </div>
        <div className='mg-t-25 modal-footer-group d-flex align-items-center justify-content-center flex-wrap'>
            <button className='btn btn-bg-cs-6-bd-cs-1 min-width-170' onClick={() =>cb()}>OK</button>
        </div>
    </div>
}

export default SendMailMessage;