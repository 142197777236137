import classnames from 'classnames';
import { observer } from 'mobx-react';
import { Link, Outlet, useLocation, useNavigate } from 'react-router-dom';
import SideBar from './SideBar';
import { useProSidebar } from 'react-pro-sidebar';
import { SYSTEM_PATH } from '../../core/configs/constants';
import { useEffect, useState } from 'react';

import './style.scss';
import { toJS } from 'mobx';
import NotFoundScreen from '../../screens/404/NotFoundScreen';


const ContainerBody = observer(() => {

    // other
    const navigate = useNavigate();
    const location = useLocation();

    // store


    // state
    const [isPermission, setIsPermission] = useState(true);

    // function
    const { collapsed, collapseSidebar } = useProSidebar();

    // lifecycle


    // function

    const onToggleCollapse = () => {
        localStorage.setItem('collapsedMenu', !collapsed);
        collapseSidebar();
    }

    // function

    return (
        <div className='container-with-sidebar d-flex min-height-820 w-100'>
            <SideBar />
            <div className='container-with-sidebar-content' >
                <div className=''>
                    {
                        <Outlet />
                    }
                </div>
            </div>
        </div>
    )
})

export default ContainerBody;