/* eslint-disable indent */
/* eslint-disable max-len */
import { useStore } from '../../core/utils/hook';
import { SuccessMessage, SetNotification, PurchaseCard, HolderAllow, PurchaseHolder } from './Modal';
import { ACCOUNT_STATUS, FORMAT_DATE_TIME, HOLDER_PURCHASE_TYPE, HOLDER_STATUS, HOLDER_TYPE, MSG, PURCHASE_STATUS, SYSTEM_PATH, TRANSACTION_STATUS, USER_STATUS } from '../../core/configs/constants';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { observer } from 'mobx-react';
import { Banner } from '../../components/Container/Banner';
import Card from './Card';
import classNames from 'classnames';
import { URL_SOCKET_CARD, URL_SOCKET_PURCHASE } from '../../core/utils/socket';
import { calculateCardInfo } from '../../core/utils/common';
import { useNavigate } from 'react-router-dom';
import { io } from 'socket.io-client';
import moment from 'moment';
import './style.scss';
import { ReactNotifications } from '../../components';

const holderArr = Array.from({ length: 12 }).map((_, index) => index + 1);
const HolderDefault = ({ show = false }) => {

    return <>
        {show && <>
            <div className='holder-check'>
            </div>
            <HolderAllow />
        </>}
        <div className='holder-screen'>
            <Banner />
            <div className='holder-list row mg-t-100'>
                {holderArr.map((item) => {
                    return <div key={'holder-default' + item} className='col-12 col-lg-6 holder-item min-height-340 mg-b-30'>
                        <div className='holder-item-content d-flex justify-content-center align-items-center'>
                            {item <= 2 ? (<><div className='open-holder'></div>
                                <div className='center-btn d-flex flex-column justify-content-center align-items-center'>
                                    <img src='/images/subway_add.svg' />
                                    <p className='font-bold fs-heading-normal'>カードを探す</p>
                                </div></>) : (<><div className='overlay'></div>
                                    <div className='center-btn d-flex flex-column justify-content-center align-items-center'>
                                        <img src='/images/icon_clarity_lock-line.svg'
                                            className={classNames('text-color-cs-2 mg-b-15', item !== 3 && 'opacity-item')} />
                                    </div></>)}
                        </div>

                    </div>
                })}
            </div>
        </div>
    </>
}

const HolderScreen = observer((props) => {
    //other
    const navigate = useNavigate();

    //store
    const { authStore: { accountInfo, getInfo },
        cardStore: { listHolders, getListCardHold, removeCardFromHolder, buyCard, setAttrObservable, updateStatusLockCard, sortCard },
        purchaseStore: { cancelHolder },
        modalStore: { hide, show } } = useStore();

    //state
    const [cacheData, setCacheData] = useState([]);
    const [countCardLockStatus, setCountCardLockStatus] = useState({
        lock: 0,
        unlock: 0
    });
    const [sortCardStatus, setSortCardStatus] = useState({
        cardId: undefined,
        isLock: undefined
    });

    //life cycle
    useEffect(() => {
        const socketCard = io(URL_SOCKET_CARD);
        const socketPurchase = io(URL_SOCKET_PURCHASE);

        socketPurchase.on('connect', () => {
            socketPurchase.emit('user-connected', {accountId: accountInfo?.id})
        })

        socketCard.on('change-card-info', (arg) => {
            const { currentRate = 0, currencyRate = 0 } = arg;
            setCacheData(prevData => {
                const processData = prevData.map((holder) => {
                    return holder?.id ? {
                        ...holder,
                        card: holder.card?.id && !holder.transaction ? {
                            ...holder.card,
                            currentRate,
                            variationRate: calculateCardInfo(currentRate, currencyRate, holder.card)?.variationRate,
                            currentPrice: calculateCardInfo(currentRate, currencyRate, holder.card)?.currentPrice
                        } : holder.card

                    } : holder
                });
                setAttrObservable('listHolders', processData);

                return processData;
            })
        });

        socketCard.on('update-card-info', (arg) => {
            const { id, airdrop, baseRate, cardPrice, cardTypeId, image, publicAt, status } = arg;
            setCacheData(prevData => {
                const findIndex = prevData.findIndex(item => item?.card?.id == id);
                if (findIndex !== -1) {
                    prevData[findIndex].card.airdrop = airdrop;
                    prevData[findIndex].card.baseRate = baseRate;
                    prevData[findIndex].card.cardPrice = cardPrice;
                    prevData[findIndex].card.cardTypeId = cardTypeId;
                    prevData[findIndex].card.image = image;
                    prevData[findIndex].card.publicAt = publicAt;
                    prevData[findIndex].card.status = status;
                }
                setAttrObservable('listHolders', prevData);

                return prevData;
            })
        })


        socketPurchase.on('accept-purchase-status', () => {
            // getInfo(true);
            onGetAllCardHolder(true);
        });

        return () => {
            socketCard.off('change-card-info');
            socketCard.off('update-card-info');
            socketPurchase.off('accept-purchase-status');
            socketCard.disconnect();
            socketPurchase.disconnect();
        };
    }, []);

    useEffect(() => {
        if (accountInfo?.status === ACCOUNT_STATUS.SUBSCRIBED.key) {
            onGetAllCardHolder();
        }

        return () => {
            setAttrObservable('listHolders', [], false, true);
        }
    }, [accountInfo])

    // modal
    const openSuccessAlert = () => {
        show({
            id: 'modal-success-alert',
            header: 'メッセージ',
            isOpen: true,
            onCancel: () => hide(),
            children: <SuccessMessage hide={hide} />
        })
    }

    const openConfirmRemoveCard = (id) => {
        show({
            id: 'modal-remove-holder',
            header: '確認',
            isOpen: true,
            onCancel: () => hide(),
            children: (<div className='pd-tb-20 pd-lr-16'>
                <p className='fs-label text-center font-semi-bold'>カードを放棄しますか？</p>
                <div className='mg-t-30 modal-footer-group d-flex align-items-center justify-content-center flex-wrap'>
                    <button className='btn btn-bg-cs-6-bd-cs-1 min-width-130' onClick={() => hide()}>キャンセル</button>
                    <button className='btn btn-bg-cs-1 min-width-130' onClick={() => submitRemoveCardFromHolder(id)}>放棄</button>
                </div>
            </div>)
        })
    }

    const openConfirmRemoveHolder = (id) => {
        show({
            id: 'modal-remove-holder',
            header: '確認',
            isOpen: true,
            onCancel: () => hide(),
            children: (<div className='pd-16'>
                <p className='fs-label font-semi-bold'>Holderを解約すると、カードを追加できなくなりますが、よろしいですか</p>
                <div className='mg-t-30 modal-footer-group d-flex align-items-center justify-content-center flex-wrap'>
                    <button className='btn btn-bg-cs-6-bd-cs-1 width-130 line-height-1' onClick={() => hide()}>キャンセル</button>
                    <button className='btn btn-bg-cs-1 width-130 line-height-1' onClick={() => submitCancelHolder(id)}>Holder解約</button>
                </div>
            </div>)
        })
    }

    const openSetNotification = ({ id, notifyValue, notifyFlag }) => {
        show({
            id: 'modal-notification',
            header: '通知設定',
            isOpen: true,
            onCancel: () => hide(),
            children: <SetNotification id={id} notifyFlag={notifyFlag} notifyValue={notifyValue} onGetAllCardHolder={onGetAllCardHolder} />
        })
    }

    const openPurchaseCard = ({ cardPrice, id }) => {
        show({
            id: 'modal-payment',
            header: '決済',
            isOpen: true,
            onCancel: () => hide(),
            children: <PurchaseCard cardPrice={cardPrice} cardId={id} onGetAllCardHolder={onGetAllCardHolder} />
        })
    }

    const onClickExtendHolder = (holderId) => {
        navigate(SYSTEM_PATH.EXTEND_HOLDER, {state: { accountId: accountInfo?.id, holderId } })
    }

    const onNavigatePurchaseHolderScreen = () => {
        navigate(SYSTEM_PATH.PURCHASE_HOLDER, {state: { accountId: accountInfo?.id } })
    }

    const onConfirmChangeStatusLockCard = (cardId, isLock) => {
        if(cardId) {
            const message = isLock ? 
            'ロックされているカードはホルダーから削除することができません。このカードをロックしてよろしいですか？' 
            : 'このカードをアンロックしてよろしいですか？';
            show({
                id: 'modal-change-lock-card-staus',
                header: '確認',
                isOpen: true,
                onCancel: () => hide(),
                children: (<div className='pd-16'>
                    <p className='fs-label font-semi-bold'>{message}</p>
                    <div className='mg-t-30 modal-footer-group d-flex align-items-center justify-content-center flex-wrap'>
                        <button className='btn btn-bg-cs-6-bd-cs-1 width-130 line-height-1' onClick={() => hide()}>キャンセル</button>
                        <button className='btn btn-bg-cs-1 width-130 line-height-1' onClick={() => onChangeStatusLockCard(cardId, isLock)}>OK</button>
                    </div>
                </div>)
            })
        }
    }

    //function
    const checkCanDoPurchase = useMemo(() => isAbleToDoPurchase(listHolders), [listHolders])
    const onGetAllCardHolder = (disableLoading) => {
        getListCardHold((res) => {
            setAttrObservable('listHolders', res?.data || [], false, true);
            setCacheData(res?.data || []);
            setCountCardLockStatus({
                lock: res?.data?.filter(holder => holder?.card?.id && holder.card.isLock).length || 0,
                unlock: res?.data?.filter(holder => holder?.card?.id && !holder.card.isLock).length || 0
            })
        }, disableLoading ? true : false);
    }

    const submitRemoveCardFromHolder = async (id) => {
        const res = await removeCardFromHolder(id);
        if (res) {
            onGetAllCardHolder();
            hide();
        }
    }

    const submitCancelHolder = async (id) => {
        const res = await cancelHolder({ id });
        if (res) {
            onGetAllCardHolder();
            hide();
        }
    }

    const onChangeStatusLockCard = async (cardId, isLock) => {
        hide();
        const res = await updateStatusLockCard(cardId, { isLock });
        if(res) {
            ReactNotifications('success', MSG['inform.success.update']);
            onGetAllCardHolder();
        }
    }

    const onExitSortCard = () => {
        setSortCardStatus({
            cardId: undefined,
            isLock: undefined
        })
    }

    const onSortCard = async (moveLocation) => {
        if(sortCardStatus?.cardId !== undefined) {
            const res = await sortCard(sortCardStatus.cardId, { moveLocation: moveLocation + 1 });
            if(res) {
                ReactNotifications('success', MSG['inform.success.update']);
                onExitSortCard();
                onGetAllCardHolder();
            }
        }
    }

    return <>
        {accountInfo?.status && accountInfo?.status === ACCOUNT_STATUS.SUBSCRIBED.key ? <div className='holder-screen'>
            <Banner />
            <div className='holder-list row mg-t-100'>
                {listHolders && listHolders.length && listHolders.map((holder, index) => {
                    const expiryStartDate = holder?.expiryStartDate ? moment(holder.expiryStartDate) : null;
                    const expiryDate = holder?.expiryDate ? moment(holder.expiryDate) : null;

                    return <div key={`holder-${index}`} className='col-12 col-lg-6 holder-item min-height-340 mg-b-30'>
                        <div className={classNames('holder-item-content d-flex justify-content-center align-items-center', sortCardStatus?.cardId !== undefined && sortCardStatus.cardId === holder?.card?.id && 'doing-sort-card-active')} >
                            {
                                sortCardStatus?.cardId !== undefined && sortCardStatus.cardId !== holder?.card?.id &&
                                <div className={classNames('doing-sort-card')}>
                                    {
                                        sortCardStatus.cardId !== holder?.card?.id && sortCardStatus.isLock === holder?.card?.isLock && 
                                        <button 
                                            className='select-location-sort-card font-semi-bold d-flex align-items-center flex-gap-5' 
                                            onClick={() => onSortCard && onSortCard(index)}
                                        >
                                            <img src='/images/sort_card.svg'/>
                                            <span>こちらのHolderにカードを移行する</span>
                                        </button>
                                    }
                                </div>
                            }
                            {
                                (holder?.type === HOLDER_TYPE.FEE && holder?.status === HOLDER_STATUS.LOCK && holder?.expiryDate !== null) && 
                                <div className='warning-purchase d-flex justify-content-center align-items-center'>
                                    <div className='warning-content pd-15'>
                                        <i className="fa-solid fs-heading-large fa-triangle-exclamation d-block"></i>
                                        <p className='mg-t-10 fs-default font-semi-bold'>月額のHolder追加料のお支払いが確認できていません。引き続きご利用いただけるよう{<span className='text-color-cs-red'>{expiryStartDate?.second() || expiryStartDate?.millisecond() ? 
                                                                    expiryDate.add(1, 'minute').startOf('minute').format('YYYY/MM/DD HH:mm') 
                                                                    : expiryDate.startOf('minute').format('YYYY/MM/DD HH:mm') }</span>} までにお支払いくさだい。お支払期限が過ぎた場合、カードが削除され、当該のHolderも解約されます。（無料Holderだけ残ります）</p>
                                        <div className='mg-t-30 holder-btn-group row'>
                                            {holder.transaction && holder.transaction.status == TRANSACTION_STATUS.UNPAID ? <div className='text-danger text-center font-semi-bold'>支払い待ち (取引ID: {holder.transaction?.code || ''})</div> :
                                                <>
                                                    <div className={classNames('col-12 mg-b-10 ', !(checkCanDoPurchase && checkCanDoPurchase.canDoTransaction) && 'col-md-6')}>
                                                        <button className='holder-btn d-flex justify-content-center align-items-center holder-btn-release col-12'
                                                            onClick={() => openConfirmRemoveHolder(holder?.id)}>
                                                            <img src='/images/icon_x.svg' />
                                                            <span className='text-color-cs-1 btn-title font-semi-bold mg-l-10 fs-default'>Holder解除</span>
                                                        </button>
                                                    </div>
                                                    {!(checkCanDoPurchase && checkCanDoPurchase.canDoTransaction) && <div className='col-12 mg-b-10 col-md-6'>
                                                        <button className='holder-btn d-flex justify-content-center align-items-center holder-btn-payment col-12'
                                                            onClick={() => onClickExtendHolder(holder?.id)}>
                                                            <span className='text-color-cs-2 btn-title font-semi-bold mg-l-10 fs-default'>Holder追加料を支払う</span>
                                                        </button>
                                                    </div>}

                                                </>}
                                        </div>
                                    </div>
                                </div>
                            }
                            {
                            holder?.status === HOLDER_STATUS.UNLOCK ? 
                                holder.card && holder.card?.id && 
                                (holder.card?.transaction == null || holder.card.transaction && holder.card.transaction.status !== TRANSACTION_STATUS.PAID) ? 
                                    <Card 
                                        cardInfo={holder.card} 
                                        holderId={holder.id} 
                                        expiryStartDate={holder.expiryStartDate} 
                                        holderType={holder?.type} 
                                        accountId={accountInfo?.id} 
                                        isPayMonthly={holder?.isPayMonthly} 
                                        checkCanDoPurchase={checkCanDoPurchase}
                                        holderTransaction={holder.transaction}
                                        openSuccessAlert={openSuccessAlert} 
                                        openConfirmRemoveHolder={openConfirmRemoveHolder}
                                        openConfirmRemoveCard={openConfirmRemoveCard}
                                        openSetNotification={openSetNotification} 
                                        openPurchaseCard={openPurchaseCard} 
                                        onConfirmChangeStatusLockCard={onConfirmChangeStatusLockCard}
                                        countCardLockStatus={countCardLockStatus}
                                        sortCardStatus={sortCardStatus}
                                        setSortCardStatus={setSortCardStatus}
                                        onSortCard={onSortCard}
                                        holderIndex={index}
                                        onExitSortCard={onExitSortCard}
                                    /> 
                                    : (
                                        <>
                                            <div className='open-holder'></div>
                                            <div className='holder-card-group-button'>
                                                {
                                                    holder?.type === HOLDER_TYPE.FEE && holder?.isPayMonthly && expiryStartDate && checkCanDoPurchase && checkCanDoPurchase.canPurchase && (
                                                        <div 
                                                            title='Holderのサブスクを延長'
                                                            className='holder-card-btn extend-holder-button d-flex justify-content-center align-items-center' 
                                                            role='button' 
                                                            onClick={() => navigate(SYSTEM_PATH.EXTEND_HOLDER, {state: { accountId: accountInfo?.id, holderId: holder?.id } })}>
                                                            <img src='/images/extend_holder.svg' />
                                                        </div>
                                                    )
                                                }
                                            </div>
                                            {
                                                holder?.transaction && holder.transaction.status == TRANSACTION_STATUS.UNPAID && (
                                                    <div className='warning-holder-purchase font-bold d-flex align-items-center justify-content-center'>
                                                        <div className='d-flex align-items-center justify-content-center'>
                                                            <i className='fa-solid fa-triangle-exclamation mg-r-5'></i>
                                                            <span>このHolderには支払い待ちの取引が存在します。</span>
                                                        </div>
                                                        <span>{` (取引ID: ${holder.transaction.code})`}</span>
                                                    </div>
                                                )
                                            }
                                            <div className='center-btn d-flex flex-column justify-content-center align-items-center' role='button' onClick={() => navigate(SYSTEM_PATH.LIST_CARD)}>
                                                <img src='/images/subway_add.svg' />
                                            </div>
                                            <p className='add-holder-conent font-bold fs-heading-normal'>カードを探す</p>
                                            {
                                                holder?.type === HOLDER_TYPE.FEE && 
                                                <>
                                                    {
                                                        holder?.isPayMonthly && expiryStartDate &&
                                                        <div className='bg-cs-bolder-yellow expiry-date-holder'>
                                                            <span>Holderの有効期限 :</span>
                                                            <span className='font-bold mg-l-10'>{
                                                                expiryStartDate.second() || expiryStartDate.millisecond() ? 
                                                                expiryStartDate.add(1, 'minute').startOf('minute').format('YYYY/MM/DD HH:mm') 
                                                                : expiryStartDate.startOf('minute').format('YYYY/MM/DD HH:mm') 
                                                            }</span>
                                                        </div>
                                                    }
                                                </>
                                            }
                                        </>
                                    ) : (
                                        <>
                                            <div className='overlay'></div>
                                            <div>
                                                <div className='center-btn d-flex flex-column justify-content-center align-items-center'>
                                                    <img src='/images/icon_clarity_lock-line.svg'
                                                        className={classNames('text-color-cs-white mg-b-15', (holder?.canBuy == false || (holder?.transaction && holder.transaction.status === TRANSACTION_STATUS.UNPAID) || (checkCanDoPurchase && checkCanDoPurchase.canAddHolder)) && 'opacity-item')} />
                                                </div>
                                                {(holder.canBuy && !(checkCanDoPurchase && checkCanDoPurchase.canAddHolder)) ? (<p role='button' onClick={() => onNavigatePurchaseHolderScreen()} className='lock-content font-bold fs-heading-normal'>Holderを追加する</p>) : (holder?.transaction && holder.transaction.status === TRANSACTION_STATUS.UNPAID) ? (<div className='lock-content paid font-bold fs-label'>
                                                    <p className='mg-0 fs-label text-center font-semi-bold'>支払い待ち</p>
                                                    <p className='mg-0 fs-label text-center font-semi-bold'>(取引ID: {holder.transaction?.code || ''} )</p></div>) : <></>
                                                }
                                            </div>
                                        </>
                                    )
                                }

                        </div>
                    </div>
                })}

            </div>
        </div> : <><HolderDefault show={accountInfo?.status && accountInfo.status !== ACCOUNT_STATUS.SUBSCRIBED.key} /></>}
    </>
})

const isAbleToDoPurchase = (holderList = []) => {
    if (holderList.length) {
        const holderExpire = holderList.some((holder) => {
            return holder.type == HOLDER_TYPE.FEE && holder.expiryDate != null;
        });
        const hasAddHolderTransaction = holderList.some((holder) => {
            return holder.type == HOLDER_TYPE.FEE && holder.expiryDate == null && holder.transaction && holder.transaction.status == TRANSACTION_STATUS.UNPAID;
        })
        const hasHolderTransaction = holderList.some(holder => holder.transaction && holder.transaction.status == TRANSACTION_STATUS.UNPAID);

        return {
            canDoTransaction: holderExpire && hasAddHolderTransaction,
            canAddHolder: holderExpire,
            canPurchase: !hasHolderTransaction
        }
    }
    return {
        canDoTransaction: false,
        canAddHolder: false,
        canPurchase: false
    };

}

export default HolderScreen;