/* eslint-disable max-len */
import { observer } from 'mobx-react';
import '../style.scss';
import SendMailMessage from './SendMailMessage';
import { useStore } from '../../../core/utils/hook';
import { HOLDER_PURCHASE_TYPE, TYPE_GENERAL_SETTING } from '../../../core/configs/constants';
import { useEffect, useMemo } from 'react';
import { FormatNumber } from '../../../components';

const PurchaseHolder = observer((props) => {
    //other
    const { mode = 0, onGetAllCardHolder } = props;
    //store
    const { generalSettingStore: { holderFee, getGeneralSetting, clean },
        purchaseStore: { purchaseAddHolderFee, getExpireHolder, expireHolder, purchaseExpireHolder},
        modalStore: { show, hide } } = useStore()
    //state
    
    //lifecycle
    useEffect(() => {
        
        onGetHolderFee(mode);

        return () => {
            clean();
        }
    }, [mode])
    //modal
    const openSendMailMessage = () => {
        show({
            id: 'modal-message',
            isOpen: true,
            notButtonX: true,
            children: (<SendMailMessage cb={() => {
                onGetAllCardHolder();
                hide();
            }} />)
        })
    }
    //function
    
    const onGetHolderFee = (mode) => {
        getGeneralSetting(TYPE_GENERAL_SETTING.OTHER_SETTING)
        mode == HOLDER_PURCHASE_TYPE.EXTEND_HOLDER && getExpireHolder();
    }

    const submitBuyHolder = async (mode) => {
        let res; 
        if (mode == HOLDER_PURCHASE_TYPE.ADD_HOLDER) {
            res = await purchaseAddHolderFee();
        } else {
            res = await purchaseExpireHolder();
        }
        
        if (res) {
            hide();
            openSendMailMessage();
        }
    }

    return <div className='add-holder w-100 pd-20'>
        <div className='d-flex'>
            <p className='font-medium fs-label width-170 text-color-cs-11'>Holder追加料</p>
            <p className='font-semi-bold fs-label'>{holderFee ?<div> <FormatNumber statusDisplay='text' value={holderFee} /> 円/月</div> : ''}</p>
        </div>
        <div className='d-flex'>
            <p className='font-medium fs-label width-170 text-color-cs-11'>Holder総枠数</p>
            <p className='font-semi-bold fs-label'>{mode == HOLDER_PURCHASE_TYPE.ADD_HOLDER ? 1 : (expireHolder && expireHolder.totalExpireHolders)}</p>
        </div>
        <div className='d-flex'>
            <p className='font-medium fs-label width-170 text-color-cs-11'>支払総額</p>
            <div className='font-semi-bold fs-label text-color-cs-red'>{mode == HOLDER_PURCHASE_TYPE.EXTEND_HOLDER && expireHolder && (expireHolder.holderFee || expireHolder.holderFee == 0) && expireHolder.totalExpireHolders ? <div> <FormatNumber statusDisplay='text' value={expireHolder.holderFee * expireHolder.totalExpireHolders} /> 円</div> : mode == HOLDER_PURCHASE_TYPE.ADD_HOLDER && (holderFee || holderFee == 0)  ? <div> <FormatNumber statusDisplay='text' value={holderFee} /> 円</div>: <span>0 円</span> }</div>
        </div>
        <div className='option-description pd-5 mg-t-10'>
            <p className='font-regular text-color-cs-12 fs-description mg-0'>Holderの使用を維持するには、1ヶ月後の更新にて決済を行う必要がございます。</p>
            <p className='font-regular text-color-cs-12 fs-description'>有効化が切れたあと10日間はカードが保持されますが、それ以降はリリースされますのでご注意ください。</p>
        </div>
        <div className='mg-t-35 modal-footer-group d-flex align-items-center justify-content-center flex-wrap'>
            <button type='button' className='btn btn-bg-cs-6-bd-cs-1 font-bold min-width-150' onClick={() => hide()}>キャンセル</button>
            <button type='button' className='btn btn-bg-cs-1 font-bold min-width-150' onClick={() => submitBuyHolder(mode)}>Holder追加</button>
        </div>
    </div>
})


export default PurchaseHolder;