import { flow, makeObservable, observable } from 'mobx';
import { TransactionApi } from '../apis';
import BaseStore from './BaseStore';

class TransactionStore extends BaseStore {

    listTransaction = [];
    detailTransaction = {}




    constructor(rootStore) {
        super();
        makeObservable(this, {
            listTransaction: observable,
            detailTransaction: observable,
            getAllTransaction: flow.bound,
            getDetailTransaction: flow.bound,
            cancelTransaction: flow.bound
        })
        this.rootStore = rootStore;
        this.api = new TransactionApi();
    }

    *getAllTransaction(searchParams, cb, disableLoading = false) {
        try {
            const { size, page, sortDir, sortKey } = this.paging;
            const payload = { size, page, sortDir, sortKey, ...searchParams };
            const res = yield this.rootStore.apiStore.call(this.api, this.api.getAllTransaction, payload, false, null, disableLoading);
            if (res?.ok) {
                this.listTransaction = res?.data?.elements || [];
                this.setAttrObservable('paging', res?.data?.paginate, true, false);
                cb && cb(res?.data);
            }
        } catch (error) {
            console.log(error);
        }
    }

    *getDetailTransaction(id, disableLoading = false) {
        try {
            const res = yield this.rootStore.apiStore.call(this.api, this.api.getDetailTransaction, { id }, false, null, disableLoading);
            if (res?.ok) {
                this.detailTransaction = res?.data ?? {};
            }
        } catch (error) {
            console.log(error);
        }
    }

    *cancelTransaction(id) {
        try {
            const res = yield this.rootStore.apiStore.call(this.api, this.api.cancelTransaction, { id });
            if (res?.ok) {
                return true;
            }
            return false;
        } catch (error) {
            console.log(error);
        }
    }


    clean() {
        super.clean();
        this.detailTransaction = {}
        this.listTransaction = [];
    }
}

export default TransactionStore;
