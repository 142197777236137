/* eslint-disable no-undef */
const ENV_MODE = 'production';
const URL_CONFIG = {
    production: {
        api_url: process.env.REACT_APP_API_URL,
        port: 443,
        ssl: true,
        prefix_url: '',
        version: '1.0.0',
        socket_url: process.env.REACT_APP_SOCKET_URL
    },
    staging: {
        api_url: process.env.REACT_APP_API_URL,
        port: 80,
        ssl: true,
        prefix_url: '',
        version: '1.0.0',
        socket_url: process.env.REACT_APP_SOCKET_URL
    },
    dev: {
        api_url: process.env.REACT_APP_API_URL,
        port: 80,
        ssl: true,
        prefix_url: '',
        version: '1.0.0',
        socket_url: process.env.REACT_APP_SOCKET_URL
    },
    local: {
        api_url: 'localhost:3000/api/v1/enduser',
        port: 80,
        ssl: false,
        prefix_url: '',
        version: '1.0.0',
        socket_url: 'http://localhost:3000/socket.io'
    }
};
export default URL_CONFIG[ENV_MODE];