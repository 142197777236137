/* eslint-disable max-len */
import { observer } from 'mobx-react';
import {  useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useStore } from '../../../core/utils/hook';
import { ACCOUNT_STATUS, MEMBERSHIP_TYPE } from '../../../core/configs/constants';
import { FormatNumber } from '../../../components';
import '../style.scss';


const PurchaseMember = observer((props) => {

    //other
    const { navigateToPaidMember, onChangeNavigate, onSubmitRegisterMembership } = props;

    //store
    const { authStore: { accountInfo }, purchaseStore: { membershipFee, getMembershipFee, setAttrObservable } } = useStore();

    //state
    const { register, handleSubmit, reset, watch } = useForm({
        mode: 'onChange' });

    // lifecycle
    useEffect(() => {
        const getAllMemberShipFee = async () => {
            await getMembershipFee();
        }
        if (accountInfo && accountInfo.status === ACCOUNT_STATUS.SUBSCRIPTION_SUSPENDED.key) {
            getAllMemberShipFee();
        }
        
        return () => {
            setAttrObservable('membershipFee', [], false, true);
        }
    }, [accountInfo])
    useEffect(() => { 
        reset({ paymentOption: MEMBERSHIP_TYPE.MONTH.toString() });

    }, [membershipFee])

    //function

    return <div className='modal-default modal-select-payment'>
        <div className='modal-select-header pd-t-16 pd-lr-20 d-flex align-items-center justify-content-between'>
            <h4 className='font-semi-bold'>決済</h4>
            <span role='button' onClick={() => onChangeNavigate(!navigateToPaidMember)}><i className="fa-solid fa-x fs-heading-normal"></i></span>
        </div>
        <div className='modal-select-content pd-20'>
            <p className='fs-label font-semi-bold'>ご要望のご利用プランをお選びください。</p>
            <form onSubmit={handleSubmit(onSubmitRegisterMembership)}>
                {
                    membershipFee && membershipFee.length && membershipFee.map((fee, index) => {
                        
                        return <div key={fee.key} className="form-check mg-b-15">
                            <label className="form-check-label font-medium fs-label" htmlFor={`option-${index + 1}`}>
                                <input {...register('paymentOption')} value={fee.key} type='radio' className="form-check-input" id={`option-${index + 1}`}/>
                                {fee.key === MEMBERSHIP_TYPE.MONTH ? <div>月額利用料 (<FormatNumber statusDisplay="text" value={fee.price} suffix=' 円/月'/>)</div> : <div>年間利用料 (<FormatNumber statusDisplay="text" value={fee.price} suffix=' 円/年'/>)</div>}
                            </label>
                            <div className='option-description pd-5 mg-t-10'>
                                <div className='font-regular fs-description text-color-cs-12'>
                                    {fee.key === MEMBERSHIP_TYPE.MONTH ? <>RAISE-SML-サービスをご利用いただくために、毎月1回月額利用料をお支払いいただく料金プランです。</> :
                                        <>RAISE-SML-の会員資格を維持するために、年1回年間利用料をお支払いいただく料金プランです。</>}</div>
                            </div>
                        </div>
                    })
                }
                <div className='mg-t-30 modal-footer-group d-flex align-items-center justify-content-center flex-wrap'>
                    <button type='button' className='btn btn-bg-cs-6-bd-cs-1 min-width-150' onClick={() => onChangeNavigate(!navigateToPaidMember)}>キャンセル</button>
                    <button type='submit' className='btn btn-bg-cs-1 min-width-150'>決済</button>
                </div>
            </form>
        </div>
    </div>
})

export default PurchaseMember;