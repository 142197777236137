import { action, flow, makeObservable, observable } from 'mobx';
import BaseStore from './BaseStore';
import { InquiryApi } from '../apis';

class InquiryStore extends BaseStore {

    constructor(rootStore) {
        super();
        makeObservable(this, {
            sendInquiry: flow.bound,
        })
        this.rootStore = rootStore;
        this.api = new InquiryApi()
    }

    *sendInquiry(payload) {
        try {
            const res = yield this.rootStore.apiStore.call(this.api, this.api.sendInquiry, payload);
            if(res?.ok) {
                return res;
            }
            return false;
        } catch (error) {
            console.log(error);
        }
    }
}

export default InquiryStore;